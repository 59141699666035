import { SerializedStyles, css, keyframes } from '@emotion/react';

interface DotStyleProps {
  delay: number;
  css?: SerializedStyles;
}

const spinnerAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const dotStyle = ({ delay }: DotStyleProps) => css`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: #acef34;
  margin: 0 0.5rem;
  animation: ${spinnerAnimation} 1s ease-in-out infinite;
  animation-delay: ${delay}s;
`;

const spinnerStyle = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  opacity: 0.6;
  background-color: black;
  z-index: 999;
`;

export const styles = {
  spinnerStyle,
  dotStyle,
};
