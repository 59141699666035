import { useFacility } from '@modules/facility';
import { getFacilityPossibleRoleOptions } from '@modules/users';
import { useScrollIntoView } from '@shared/hooks';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CustomPlaceholder,
  CustomSelectOption,
  CustomSelectValue,
} from './components';
import { RoleOption } from './models';
import { selectProps, styles } from './styles';

interface Props {
  selectedOption: MultiValue<RoleOption> | SingleValue<RoleOption> | null;
  onSetSelectedOption: React.Dispatch<
    React.SetStateAction<SingleValue<RoleOption> | null>
  >;
}

export const RoleSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const currentFacility = useFacility();
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();

  const roleOptions = getFacilityPossibleRoleOptions(currentFacility, {
    admin: formatMessage({ id: 'role-select.label.admin' }),
    employee: formatMessage({ id: 'role-select.label.employee' }),
    privatePlayer: formatMessage({ id: 'role-select.label.privatePlayer' }),
    trainer: formatMessage({ id: 'role-select.label.trainer' }),
  });

  const handleChange = (newValue: SingleValue<RoleOption>) => {
    onSetSelectedOption(newValue);
  };

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="role-select.title" />
        <span css={styles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        placeholder={<CustomPlaceholder />}
        options={roleOptions}
        onFocus={handleFocus}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
};
