import { facilityAtoms } from '@modules/facility';
import { safeLog } from '@shared/utils';
import { useRecoilCallback } from 'recoil';
import { FirebaseAuthService, areClaimsValid, authAtoms } from '..';

export const useFirebaseAuthListener = () =>
  useRecoilCallback(
    ({ set }) =>
      () => {
        const authService = new FirebaseAuthService();

        try {
          set(authAtoms.loading, true);
          authService.subscribeToAuth(async (user) => {
            safeLog('User', { user });
            if (!user) {
              set(authAtoms.uid, null);
              set(authAtoms.claims, null);
              set(authAtoms.defaultClaim, null);
              set(authAtoms.emailVerified, null);
              set(facilityAtoms.facility, null);
              set(facilityAtoms.facilities, null);
              set(facilityAtoms.currentFacilityId, null);
              set(authAtoms.loading, false);
              return;
            }

            user.getIdToken(true);
            const token = await user.getIdTokenResult();
            safeLog('Token', { token });
            safeLog('Email is verified ->', user.emailVerified);

            const filteredClaims = areClaimsValid(token?.claims)
              ? token?.claims?.roles?.filter(
                  (claim) =>
                    claim.role === 'playoff-admin' ||
                    claim.role === 'admin' ||
                    claim.role === 'employee',
                )
              : null;
            const defaultClaims = !filteredClaims?.length
              ? token?.claims?.roles
              : filteredClaims;
            safeLog('defaultClaims', { defaultClaims });

            set(authAtoms.uid, user.uid);
            set(authAtoms.email, user.email);
            set(authAtoms.emailVerified, user.emailVerified);
            set(authAtoms.claims, filteredClaims);

            set(authAtoms.defaultClaim, defaultClaims?.[0] || null);
            set(authAtoms.loading, false);
          });
        } catch (error) {
          safeLog('Error in useFirebaseAuthListener', error);
          /**
           * Errors for the listener are and should be handled inside the methods that call specific actions from the Firebase SDK.
           * onError and onComplete are deprecated in new version od the SDK.
           */
        }
      },
    [],
  );
