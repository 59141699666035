import { SerializedStyles } from '@emotion/react';
import { useAuthForm } from '@modules/auth';
import { useSubmitOnEnter } from '@shared/hooks';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { forgotPasswordFormStyles as styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

export const ForgotPasswordForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { formik, loading } = useAuthForm('reset');
  const ref = useSubmitOnEnter<HTMLButtonElement>();

  return (
    <motion.form
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
      css={styles.formStyles}
    >
      <FormInput
        type="email"
        label={formatMessage({ id: 'forgot-password.email.label' })}
        id="email"
        name="email"
        Icon={Mail}
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched}
        isRequired
        placeholder={formatMessage({ id: 'forgot-password.email.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Button
        disabled={loading}
        ref={ref}
        type="submit"
        customStyles={styles.formButton}
      >
        <FormattedMessage id="forgot-password.button" />
      </Button>
      <p css={styles.switchText}>
        <FormattedMessage id="forgot-password.login.button" />
        <Link href="/login">
          <FormattedMessage id="forgot-password.login.link" />
        </Link>
      </p>
    </motion.form>
  );
};
