import { useEscPress } from '@shared/hooks';
import { animations } from 'const';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { Membership } from '../membership';
import { Info } from './info/Info';
import { MemberForm } from './member-form';
import { styles } from './singleGroupModal.styles';

interface Props {
  group: Group;
  handleCloseModal: () => void;
}

export const SingleGroupModal: FC<Props> = ({ group, handleCloseModal }) => {
  const ref = useEscPress<HTMLDivElement>(handleCloseModal);
  const [currentStep, setCurrentStep] = useState('info');

  return (
    <AnimatePresence>
      <motion.div {...animations.opacity({})} ref={ref} css={styles.base}>
        <div css={styles.modal}>
          <>
            {currentStep === 'info' && (
              <Info
                group={group}
                handleCloseModal={handleCloseModal}
                setCurrentStep={setCurrentStep}
              />
            )}
            {currentStep === 'memberForm' && (
              <MemberForm setCurrentStep={setCurrentStep} groupId={group.id} />
            )}
            {currentStep === 'membership' && (
              <Membership setCurrentStep={setCurrentStep} groupId={group.id} />
            )}
          </>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
