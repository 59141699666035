import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import { useFirebaseAuth } from './useFirebaseAuth';

export const useSetLoginData = () => {
  const setLoginDataCallable = useFunction<SetLoginData, SetLoginDataResponse>(
    'setLoginData',
  );
  const { formatMessage } = useIntl();
  const { handleLogin } = useFirebaseAuth();

  async function setLoginData(
    password: string,
    uid: string,
    displayName: string,
  ) {
    if (!setLoginDataCallable) return;
    try {
      const { data } = await setLoginDataCallable({
        password,
        uid,
        displayName,
      });
      if (!data.success) {
        throw new Error(formatMessage({ id: 'set-login-data.toasts.error' }));
      }

      await handleLogin(data.payload.email, password);
    } catch (e) {
      toast.error(formatMessage({ id: 'set-login-data.toasts.error' }));
    }
  }

  return {
    setLoginData,
  };
};
