import {
  getReservationGraphData,
  getReservationGraphOptions,
} from '@modules/analytics';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { useRouter } from 'next/router';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

interface Props {
  view: 'monthly' | 'weekly';
}

export const ReservationsGraph: React.FC<Props> = ({ view }) => {
  const { locale } = useRouter();

  const options = getReservationGraphOptions();
  const data = getReservationGraphData(view, locale);

  return <Line options={options} data={data} />;
};
