import { SerializedStyles } from '@emotion/react';
import {
  convertPrice,
  useBookedHoursList,
  useBookingHelpers,
} from '@modules/booking-new';
import { useCurrentLocale } from '@modules/translations';
import { Button } from '@ui/Button';
import { format } from 'date-fns-tz';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { styles } from './bookedListItems.styles';

const TrashIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/trash-can.svg'),
  {
    loading: () => null,
  },
);

export const BookedListItems: FC = () => {
  const { isPaintball, sortedList, onClickHandler } = useBookedHoursList();
  const { formatHour, convertToDate } = useBookingHelpers();
  const { getLocale } = useCurrentLocale();

  return (
    <ul css={styles.list}>
      {sortedList?.map((booking, i) => (
        <li key={i} css={styles.base}>
          {booking.name && <span>{booking.name}</span>}
          <span css={styles.date}>
            {format(booking.from, 'E, d. MMM', {
              locale: getLocale(),
            })}
          </span>
          <div css={styles.hoursWrapper}>
            <span css={styles.hours}>
              {formatHour(convertToDate(booking.from))} -{' '}
              {formatHour(convertToDate(booking.to))}
            </span>
            <div css={styles.priceWrapper}>
              {!isPaintball && (
                <span css={styles.price}>{convertPrice(booking.price)}€</span>
              )}
              <Button
                customStyles={styles.trashButton}
                onClick={() => onClickHandler(booking)}
              >
                <TrashIcon viewBox="0 0 16 16" />
              </Button>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
