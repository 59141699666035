import { BookingHistoryCard, CancelBookingModal } from '@modules/booking-new';
import { FILTER_RESULTS } from '@modules/booking-overview';
import { useModal } from '@shared/hooks';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

interface Props {
  isLoading?: boolean;
  bookings: Booking[];
  selectedOption: BookingOverviewType;
}

export const BookingOverviewList: React.FC<Props> = ({
  isLoading,
  bookings,
  selectedOption,
}) => {
  const { locale } = useRouter();
  const { closeModal, isModalOpen, openModal } = useModal();
  const [booking, setBooking] = useState<Booking | undefined>(undefined);

  return (
    <div css={styles.resultsContainer}>
      <div css={styles.historyContainer}>
        <>
          <>
            {!bookings?.length && !isLoading ? (
              <div css={styles.noReservationsContainer}>
                <span>
                  <FormattedMessage id="booking-overview-list.no-results" />
                </span>
              </div>
            ) : (
              <>
                {bookings?.map((booking: Booking, i: number) => (
                  <BookingHistoryCard
                    key={i}
                    status={selectedOption}
                    index={i}
                    data={booking}
                    onSetBooking={setBooking}
                    openModal={openModal}
                    isRepeat={selectedOption === FILTER_RESULTS[locale][3]}
                  />
                ))}
              </>
            )}
          </>
        </>
      </div>
      {isModalOpen && booking && (
        <CancelBookingModal
          closeModal={closeModal}
          data={booking}
          isRepeat={selectedOption === FILTER_RESULTS[locale][3]}
        />
      )}
    </div>
  );
};
