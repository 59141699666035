export interface RoleClaim {
  role: Role;
}

export function isRoleClaim(
  claim: RoleClaim[] | string | unknown | null,
): claim is RoleClaim[] {
  return claim !== null && typeof claim === 'object';
}

export function isRole(claim: Role | string | unknown | null): claim is Role {
  return claim !== null && typeof claim === 'string';
}

export function areClaimsValid(obj: any): obj is Claims {
  return obj && obj.roles && Array.isArray(obj.roles);
}
