import { css } from '@emotion/react';
import { scroll } from 'styles/scroll.styles';

const selectWrapper = css`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  margin-bottom: 12px;
`;

const select = css`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-text6);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const recentResultsContainer = css`
  width: 224px;
  right: 0px;
  background: #2a282f;
  border: 1px solid rgba(248, 248, 248, 0.08);
  box-shadow: 0px -4px 32px rgba(20, 19, 21, 0.44),
    0px 14px 32px rgba(20, 19, 21, 0.44);
  border-radius: 6px;
  padding: 16px 16px 8px;
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
  top: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const daysContainer = css`
  width: 100%;
  ${scroll.base}
`;

const selectClosing = css`
  animation: closeAnimation 0.2s ease-in;
`;

const recentResultsTitle = css`
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #9d9daf;
  margin-bottom: 12px;
`;

const selectedOption = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #ffffff;
`;

const showLabel = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #c7c7d1;
`;

const resultsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const resultsTitle = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #a7a7a7;
  margin-bottom: 12px;
  margin-top: 12px;
`;

const historyContainer = css`
  max-height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const singleResultContainer = css`
  max-height: calc(100% - 600px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const revenueGraphSelectStyles = {
  historyContainer,
  selectWrapper,
  select,
  recentResultsContainer,
  recentResultsTitle,
  selectedOption,
  showLabel,
  daysContainer,
  resultsContainer,
  resultsTitle,
  selectClosing,
  singleResultContainer,
};
