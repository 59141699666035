import { css } from '@emotion/react';

const facilityInfo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 4px 16px;
  flex-shrink: 0;
  gap: 12px;

  border-radius: 28px;
  border: 1px solid var(--color-border-cardBorder);

  &:hover {
    border: 1px solid var(--color-border-6);
  }
`;

const facilityName = css`
  font-family: var(--font-family-primarySemiBold);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-text-6);
`;

const dropdownContainer = css`
  background: #2a282f;
  background: var(--color-background-3, #24222a);
  border: 1px solid var(--color-border-cardBorder);
  box-shadow: 0px 14px 32px 0px rgba(20, 19, 21, 0.44);
  border-radius: 8px;
  list-style: none;

  position: absolute;
  overflow-y: scroll;
  top: 56px;
  z-index: 999;
  right: 0px;

  min-width: 320px;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--text-text-5, #c7c7d1);
  padding: 20px 0;
  gap: 16px;
  overflow-x: hidden;
`;

const logout = css`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 16px;
  gap: 12px;
  color: #f37c6c;

  &:hover,
  &:focus {
    color: white;

    &:after {
      opacity: 1;
    }

    & path {
      fill: white;
    }
  }
`;

const listItem = css`
  display: flex;
  align-items: center;
  align-self: stretch;
  font-size: 14px;
  line-height: 16px;
  padding: 0 16px;

  svg,
  img {
    flex-shrink: 0;
  }

  &:hover,
  &:focus {
    color: white;

    &:after {
      opacity: 1;
    }

    & path {
      fill: white;
    }
  }
`;

const icon = css`
  margin-left: auto;
`;

const listItemName = css`
  margin: 0 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const active = css`
  color: var(--color-general-primary);

  &:after {
    opacity: 1;
  }
  & path {
    margin-right: 12px;
    fill: var(--color-general-primary);
  }

  &:hover,
  &:focus {
    color: white;

    &:after {
      opacity: 1;
    }

    & path {
      fill: white;
    }
  }
`;

const separator = css`
  height: 1px;
  width: 100%;
  padding: 0 16px;
  background: var(--color-border-cardBorder);
`;

export const styles = {
  facilityInfo,
  facilityName,
  dropdownContainer,
  logout,
  listItem,
  icon,
  listItemName,
  active,
  separator,
};
