import { css } from '@emotion/react';

const base = (start: string, end: string) => css`
  position: relative;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  color: var(--color-text-6);
  background-color: var(--color-background-4);
  border: 1px solid var(--color-border-5);
  border-radius: 8px;
  cursor: pointer;
  grid-row: time-${start} / time-${end};
  margin: 4px 0;
  overflow: hidden;
  transition: border 0.2s ease-out, color 0.2s ease-out,
    background-color 0.2s ease-out;

  :hover {
    border-color: var(--color-border-7);
  }

  :hover .icon {
    opacity: 1;
  }

  :focus {
    border-color: var(--color-border-4);
  }

  span {
    transition: color 0.2s ease-out;
  }

  :disabled {
    border-color: transparent;
    background-color: var(--color-background-2);
    color: var(--color-text-3);
    cursor: default;

    span {
      color: var(--color-text-3);
    }
  }
`;

const baseReserved = (start: string, end: string) => css`
  ${base(start, end)}
  border: 1px solid transparent;
  background-color: var(--color-background-3);
  color: var(--color-text-5);
  cursor: pointer;

  :hover .icon {
    opacity: 1;
    border-color: var(--color-border-5);
  }

  :hover {
    background-color: var(--color-border-cardBorder);
    border-color: transparent;

    span {
      color: var(--color-text-6);
    }
  }

  :focus {
    border-color: transparent;
  }
`;

const baseActive = (start: string, end: string) => css`
  ${base(start, end)}
  background-color: var(--color-background-5);
  color: var(--color-general-primary);
  cursor: pointer;
  border-color: var(--color-general-primary);

  & > * {
    color: var(--color-general-primary);
  }

  :focus {
    border-color: var(--color-general-primary);
  }

  :hover {
    border-color: var(--color-general-primary);
  }
`;

const baseDisabled = (start: string, end: string) => css`
  ${base(start, end)}
  background-color: var(--color-background-2);
  cursor: default;
  border: 1px solid transparent;

  :hover {
    border-color: transparent;
  }
`;

const baseDisabledDestructive = (start: string, end: string) => css`
  ${baseReserved(start, end)}
  opacity: 0.3;
`;

const baseDestructive = (start: string, end: string) => css`
  ${base(start, end)}

  :hover , :focus {
    color: var(--color-error-secondary);
    border-color: var(--color-error-secondary);

    span {
      color: var(--color-error-secondary);
    }

    :disabled {
      border-color: transparent;
      background-color: var(--color-background-2);
      color: var(--color-text-3);
      cursor: default;

      span {
        color: var(--color-text-3);
      }
    }
  }

  :hover svg,
  :focus svg {
    border-color: var(--color-error-secondary);
  }
`;

const baseDestructiveActive = (start: string, end: string) => css`
  ${base(start, end)}
  background-color: var(--color-background-4);
  color: red;
  cursor: pointer;
  border-color: red;

  & > * {
    color: red;
  }

  :hover {
    color: var(--color-error-secondary);
    border-color: var(--color-error-secondary);

    span {
      color: var(--color-error-secondary);
    }
  }

  :hover svg,
  :focus svg {
    border-color: var(--color-error-secondary);
  }

  :focus {
    color: red;
    border-color: red;
  }
`;

const time = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family-primarySemiBold);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.24px;
  transition: all 0.2s ease-out;

  span:first-of-type {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const name = css`
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-family-primary);
  color: var(--color-text-4);
  transition: all 0.2s ease-out;
`;

const nameWithSpace = css`
  ${name}
  padding-right: 15px;
`;

const icon = css`
  width: 24px;
  height: 24px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const destructiveIcon = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

const repeat = css`
  position: absolute;
  bottom: 10px;
  right: 8px;
  margin-left: auto;
  background-color: transparent;
`;

const progress = css`
  padding: 4px 12px;
  color: var(--color-general-primary);
  background-color: var(--color-background-4);
  border-radius: 12px;
  margin-left: auto;
  min-width: max-content;
`;

const checkedNegative = css`
  width: 24px;
  height: 24px;

  path {
    fill: red;
  }

  rect {
    stroke: red;
  }
`;

const trash = css`
  margin-left: auto;
  width: 24px;
  height: 24px;
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: border 0.2s ease-out;
`;

const pyramid = css`
  font-size: 10px;
  line-height: 16px;
  padding: 4px 8px;
  color: #f49f21;
  border-radius: 12px;
  margin-top: auto;
  background-color: #3f3229;
  margin-bottom: auto;
`;

const pyramidDestructive = css`
  ${pyramid};
`;

const bottomLess = css`
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;

  :focus {
    border-bottom: 0;
  }
`;

const topLess = css`
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;

  ::after {
    position: absolute;
    top: 0;
    left: 8px;
    width: calc(100% - 16px);
    height: 1px;
    content: '';
    background-color: var(--color-border-3);
  }

  :focus {
    border-top: 0;
  }
`;

const dot = css`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-general-primary);
`;

const infoButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  border: 1px solid var(--color-border-cardBorder);
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-out;

  svg {
    min-width: max-content;
    path {
      fill: var(--color-border-5);
      transition: all 0.2s ease-out;
    }
  }
`;

const plusIcon = css`
  ${infoButton}
  opacity: 0;
  border: 1px solid var(--color-border-5);

  svg {
    width: 12px;
    height: 12px;

    path {
      fill: var(--color-white);
    }
  }
`;

export const styles = {
  base,
  baseReserved,
  baseActive,
  baseDestructive,
  baseDestructiveActive,
  baseDisabledDestructive,
  baseDisabled,
  time,
  name,
  nameWithSpace,
  progress,
  pyramid,
  pyramidDestructive,
  icon,
  destructiveIcon,
  checkedNegative,
  trash,
  bottomLess,
  topLess,
  repeat,
  dot,
  infoButton,
  plusIcon,
};
