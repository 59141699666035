import { PropsWithChildren, useEffect } from 'react';
import { useFirebaseAuthListener } from '../hooks';

interface Props extends PropsWithChildren {
  children: React.ReactNode;
}

export const FirebaseAuthProvider: React.FC<Props> = ({ children }) => {
  const authListener = useFirebaseAuthListener();
  useEffect(authListener, [authListener]);

  return <>{children}</>;
};
