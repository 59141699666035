import { useOpponentSelectOptions } from '@modules/pyramid';
import { useScrollIntoView } from '@shared/hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CustomPlaceholder,
  CustomSelectOption,
  CustomSelectValue,
} from './components';
import { OpponentOption } from './models';
import { selectProps, styles } from './styles';

interface Props {
  selectedOption:
    | MultiValue<OpponentOption>
    | SingleValue<OpponentOption>
    | null;
  onSetSelectedOption: React.Dispatch<
    React.SetStateAction<SingleValue<OpponentOption> | null>
  >;
}

export const OpponentSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const { opponentOptions } = useOpponentSelectOptions();
  const handleChange = (newValue: SingleValue<OpponentOption>) => {
    onSetSelectedOption(newValue);
  };
  const { handleFocus } = useScrollIntoView();

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="opponent-select.label" />
        <span css={styles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        placeholder={<CustomPlaceholder />}
        options={opponentOptions}
        onFocus={handleFocus}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        value={selectedOption}
        onChange={handleChange}
        menuShouldScrollIntoView={false}
      />
    </div>
  );
};
