import { toast } from 'sonner';
import {
  useCurrentToDeleteBookedHours,
  useResetCurrentToDeleteBookedHours,
  useSetIsLoading,
} from '../state';
import { useBookingsDocument } from './useBookingsDocument';

export function useDeleteBooking() {
  const bookedHours = useCurrentToDeleteBookedHours();
  const setIsLoading = useSetIsLoading();
  const resetBookedHours = useResetCurrentToDeleteBookedHours();
  const { deleteBookingByStatus } = useBookingsDocument();

  const deleteBooking = async () => {
    if (!bookedHours) return;

    try {
      setIsLoading(true);
      await Promise.all(bookedHours.map(({ id }) => deleteBookingByStatus(id)));
      toast.success('Rezervacija uspješno obrisana');
    } catch {
      toast.error('Greška prilikom brisanja rezervacije');
    } finally {
      setIsLoading(false);
      resetBookedHours();
    }
  };

  const deleteSingleBooking = async (id?: string) => {
    if (!id) return;
    try {
      setIsLoading(true);
      await deleteBookingByStatus(id);
      toast.success('Rezervacija uspješno obrisana');
    } catch {
      toast.error('Greška prilikom brisanja rezervacije');
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteBooking, deleteSingleBooking };
}
