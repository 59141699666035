import { SerializedStyles } from '@emotion/react';
import { LanguageSwitcherModal } from '@modules/translations';
import { useModal } from '@shared/hooks';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React from 'react';
import { AdminDashboardNavDropdown } from '../admin-dashboard-nav-dropdown';
import { AdminDashboardNavigation } from '../admin-dashboard-navigation';
import { adminDashboardStyles as styles } from './adminDashboardStyles';

const GlobeIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/globe.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  children: React.ReactNode;
  title: string;
  description: string;
  containerStyles?: SerializedStyles;
  mainStyles?: SerializedStyles;
}

export const AdminDashboard: React.FC<Props> = ({
  children,
  title,
  containerStyles,
  mainStyles,
}) => {
  const { locale } = useRouter();
  const { closeModal, isModalOpen, openModal } = useModal();

  return (
    <div css={containerStyles}>
      <aside css={styles.sidebar}>
        <AdminDashboardNavigation />
      </aside>
      <motion.section
        initial={{ x: 300, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 300, opacity: 0 }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 30,
        }}
        css={styles.wrapper}
      >
        <div css={styles.header}>
          <h1 css={styles.title}>{title}</h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'flex' }}
          >
            <div css={styles.languageBtn(isModalOpen)} onClick={openModal}>
              <GlobeIcon viewBox="0 1 15 15" />
              <span>{locale}</span>
            </div>
            <AdminDashboardNavDropdown />
          </motion.div>{' '}
        </div>
        <div css={[styles.main, mainStyles]}>{children}</div>
        {isModalOpen && (
          <LanguageSwitcherModal
            handleCloseModal={closeModal}
            clickOutside={closeModal}
          />
        )}
      </motion.section>
    </div>
  );
};
