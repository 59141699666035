import { css } from '@emotion/react';

const base = css`
  position: fixed;
  background-color: #16141bcc;
  color: var(--color-text-6);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const modal = css`
  min-width: 520px;
  padding: 28px 24px;
  background-color: var(--color-background-2);
  border-radius: 8px;
  max-height: 80vh;
`;

export const styles = {
  base,
  modal,
};
