import { useScreenWidth } from './useScreenWidth';

/**
 * @returns {boolean} If the screen is mobile or not
 */

export function useMobile() {
  const width = useScreenWidth();
  const isMobileWidth = width < 1000;

  const isMobileDevice = () => {
    const userAgent = navigator?.userAgent;
    return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent,
    );
  };

  const getMobileOS = () => {
    const userAgent = navigator?.userAgent;
    if (/Android/i.test(userAgent)) {
      return 'Android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  return { isMobileWidth, isMobileDevice, getMobileOS };
}
