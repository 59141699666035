import { css } from '@emotion/react';
import { button } from '@styles/button.styles';

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const formButton = css`
  ${button.primary};
  font-weight: 700;
  padding: 12px 72px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-1);
  margin-top: 16px;
  background-color: var(--color-general-primary);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    color: white;
    opacity: 1;
    background-color: var(--color-background-light);
  }

  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    color: black;
  }
`;

const switchText = css`
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      color: white;
    }
  }
`;

const ctaButtons = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const styles = {
  formStyles,
  inputStyles,
  buttonStyles,
  formButton,
  ctaButtons,
  switchText,
};
