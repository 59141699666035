import { useDefaultClaim, useUserClaims } from '@modules/auth';
import {
  facilityFirestoreConverter,
  useCurrentFacilityId,
  useSetCurrentFacilityId,
  useSetFacilities,
} from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import { useLocalStorage } from '@shared/hooks';
import { safeLog } from '@shared/utils';
import { LAST_SELECTED_FACILITY_ID } from 'const';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';

/**This hook is used to fetch playoff facilities from user claims */
export function useFacilitiesListener() {
  const claims = useUserClaims();
  const defaultClaim = useDefaultClaim();
  const currentFacilityId = useCurrentFacilityId();

  const [lastSelectedFacilityId] = useLocalStorage<string>(
    LAST_SELECTED_FACILITY_ID,
  );

  const setFacilities = useSetFacilities();
  const setCurrentFacilityId = useSetCurrentFacilityId();

  const facilityIds = useMemo(
    () =>
      claims
        ?.filter((claim) => claim.role !== 'playoff-admin')
        ?.map((claim) => claim.facilityId),
    [claims],
  );
  const isEligible =
    defaultClaim?.role === 'admin' || defaultClaim?.role === 'employee';
  const lastFacilityCached =
    currentFacilityId?.length &&
    lastSelectedFacilityId?.length &&
    lastSelectedFacilityId === currentFacilityId;

  useEffect(() => {
    /**
     * If user is not eligible or facility ids are not set stop execution
     * If current facility id is the same as last selected facility from local storage id stop execution
     */
    if (!isEligible || !facilityIds?.length || lastFacilityCached) {
      return;
    }

    const getFacilitiesData = async () => {
      try {
        const facilities = await Promise.all(
          facilityIds.map(async (id) => {
            const docRef = doc(
              FirebaseService.firestore,
              'facilities',
              id,
            ).withConverter(facilityFirestoreConverter);
            const snapshot = await getDoc(docRef);
            return snapshot.data();
          }),
        );
        setFacilities(facilities);
        /**If user does hard-refresh fetch last selected facility id from local storage */
        setCurrentFacilityId(lastSelectedFacilityId || facilities[0]?.id);
      } catch (error) {
        if (safeLog) console.error('Error fetching facilities:', error);
      }
    };

    getFacilitiesData();
  }, [defaultClaim, facilityIds]);
}
