import { safeLog } from '@shared/utils';
import {
  collection,
  CollectionReference,
  doc,
  DocumentData,
  getDocs,
  onSnapshot,
  query,
  Query,
  setDoc,
  where,
} from 'firebase/firestore';
import { useMemo } from 'react';
import { FirebaseService } from '../initFirebase';
import { getTypedCollectionDocument } from '../utils';

/** UNTESTED */
export function useFirestoreCollection<T>(path: string) {
  const reference = useMemo(() => {
    const reference: Query<DocumentData> | CollectionReference<DocumentData> =
      collection(FirebaseService.firestore, path);

    return reference;
  }, [path]);

  function buildFilteredReference(filters: FirestoreFilter<T>[]) {
    let collection = reference;

    for (const filter of filters) {
      collection = query(
        collection,
        where(String(filter.field), filter.operator, filter.value),
      );
    }

    return collection;
  }

  async function get(...filters: FirestoreFilter<T>[]) {
    const collection = buildFilteredReference(filters);
    try {
      const snapshot = await getDocs(collection);
      return snapshot.docs.map((doc) => getTypedCollectionDocument<T>(doc));
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  interface FirestoreListenParams {
    onSucess: (docs: (T | undefined)[]) => void;
    onError: (error?: string) => void;
    onFinished?: VoidFunction;
    filters?: FirestoreFilter<T>[];
  }

  function listen({
    filters,
    onError,
    onSucess,
    onFinished,
  }: FirestoreListenParams) {
    if (!filters) return;
    const collection = buildFilteredReference(filters);
    return onSnapshot(
      collection,
      (snapshot) => {
        const docs = snapshot.docs.map((doc) =>
          getTypedCollectionDocument<T>(doc),
        );
        onSucess(docs);
        onFinished?.();
        safeLog(`Listening to ${path}: `, docs);
      },
      (error) => {
        onError(error.message);
        onFinished?.();
      },
    );
  }

  async function set(id: string, data: Partial<T>) {
    const document = doc(collection(FirebaseService.firestore, path), id);
    try {
      await setDoc(document, { ...data }, { merge: true });
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  return {
    get,
    set,
    listen,
  };
}
