import { css } from '@emotion/react';

const spaceBetween = css`
  display: flex;
  justify-content: space-between;
`;

const overflowAuto = css`
  overflow: auto;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const display = { spaceBetween, overflowAuto };
