import RoleIcon from 'public/icons/role-16.svg';
import { styles } from './styles';
import { FormattedMessage } from 'react-intl';

export const CustomPlaceholder = () => (
  <div css={styles.wrapper}>
    <RoleIcon />
    <span css={styles.placeholder}>
      <FormattedMessage id="role-select.description" />
    </span>
  </div>
);
