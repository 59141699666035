import { useFirebaseAuth } from '@modules/auth';
import { useLocalStorage } from '@shared/hooks';
import { EMAIL_REGEX, RESET_PASSWORD_TIMESTAMP } from 'const';
import { differenceInMinutes } from 'date-fns';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import { ErrorFormValues } from '../models/ErrorFormValues';

const AUTH_METHODS = {
  login: 'login',
  reset: 'reset',
  signup: 'signup',
};

const PASSWORD_RESET_TIMEOUT_MINUTES = 10;

export function useAuthForm(template: keyof typeof AUTH_METHODS) {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const { handleSignup, handleLogin, handleResetPassword } = useFirebaseAuth();
  const [timestamp, setTimestamp] = useLocalStorage(RESET_PASSWORD_TIMESTAMP);

  const requiredField = formatMessage({ id: 'auth-form.required.field' });
  const invalidEmail = formatMessage({ id: 'auth-form.email.invalid' });
  const passwordLength = formatMessage({ id: 'auth-form.password.invalid' });

  const setNewTimestamp = () => setTimestamp(Date.now().toString());

  const validate = (values: ErrorFormValues) => {
    const errors: ErrorFormValues = {};
    if (!values.email) {
      errors.email = requiredField;
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = invalidEmail;
    }

    if (template === 'reset') return errors;

    if (!values?.password) errors.password = requiredField;
    if (values?.password.length < 6) errors.password = passwordLength;

    return errors;
  };

  const formik = useFormik<ErrorFormValues>({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        if (template === 'reset') {
          if (!timestamp) {
            setNewTimestamp();
            await handleResetPassword(values.email);
            return;
          }

          const timeDifference = differenceInMinutes(
            new Date(),
            new Date(Number(timestamp)),
          );

          if (timeDifference < PASSWORD_RESET_TIMEOUT_MINUTES) {
            toast.warning(
              formatMessage(
                { id: 'forgot-password.toasts.timeout' },
                { minutes: timeDifference },
              ),
            );
            return;
          }

          setNewTimestamp();
          await handleResetPassword(values.email);
        }
        if (template === 'login') {
          await handleLogin(values.email, values.password);
        }
        if (template === 'signup') {
          await handleSignup(
            values.email,
            values.password,
            `${values.firstName} ${values.lastName}`,
          );
        }
      } catch (error) {
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    },
  });

  return {
    formik,
    loading,
  };
}
