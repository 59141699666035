import { collection, doc } from 'firebase/firestore';
import { FirebaseService } from '../initFirebase';

export function useFirestoreHelpers() {
  function getDocumentReference(id?: string) {
    const ref = collection(FirebaseService.firestore, 'facilities');
    return id ? doc(ref, id) : doc(ref);
  }

  return {
    getDocumentReference,
  };
}
