import { SerializedStyles } from '@emotion/react';
import { useGroupFirebase, useGroupHelpers } from '@modules/groups/hooks';
import { useSetCurrentGroupMember } from '@modules/groups/state';
import { Button } from '@ui/Button';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './groupMemberCard.styles';

const TrashIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/trash-16-danger.svg'),
  {
    loading: () => null,
  },
);

const Checked = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/checked-icon.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  member: GroupMember;
  groupId: string;
  setCurrentStep: (value: string) => void;
}

export const GroupMemberCard: FC<Props> = ({
  member,
  groupId,
  setCurrentStep,
}) => {
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const setCurrentGroupMember = useSetCurrentGroupMember();
  const { removeNewGroupMember } = useGroupFirebase();
  const { getLastDateAttended, getMemberShipFeeColor } = useGroupHelpers();
  const dateAttendedModified = member.datesAttended.map((i) => +i);
  const lastDayAttended = getLastDateAttended(dateAttendedModified)
    ? format(getLastDateAttended(dateAttendedModified), 'd.M.')
    : '/';
  const expirationDate = Boolean(member.membershipExpiryDate)
    ? format(member.membershipExpiryDate, 'd.M.')
    : '/';
  const expirationColor = getMemberShipFeeColor(
    member.membershipPaymentInfo,
    member.membershipExpiryDate,
  );

  return (
    <li css={styles.base}>
      <div css={styles.content}>
        <p css={styles.name}>{member.name}</p>
        <div css={styles.wrapper}>
          {isDeleteActive ? (
            <>
              <Button
                customStyles={styles.buttonBack}
                onClick={() => setIsDeleteActive(false)}
              >
                <FormattedMessage id="group-member-card.cancel" />
              </Button>
              <Button
                customStyles={styles.buttonDelete}
                onClick={() =>
                  removeNewGroupMember({ groupId, memberEmail: member.email })
                }
              >
                <TrashIcon viewBox="0 0 16 16" />
                <FormattedMessage id="group-member-card.delete" />
              </Button>
            </>
          ) : (
            <>
              <div css={styles.infoWrapper}>
                <span css={styles.date}>
                  {expirationDate}
                  {expirationColor && (
                    <div css={styles[`bullet${expirationColor}`]} />
                  )}
                </span>
                <Button
                  customStyles={styles.membership}
                  onClick={() => {
                    setCurrentStep('membership');
                    setCurrentGroupMember(member);
                  }}
                >
                  <FormattedMessage id="group-member-card.membership" />
                </Button>
              </div>
              <div css={styles.infoWrapper}>
                <span css={styles.date}>{lastDayAttended}</span>
                <span css={styles.text}>
                  <FormattedMessage id="group-member-card.previous-appearance" />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <Button
        customStyles={styles.trashButton}
        onClick={() => setIsDeleteActive(true)}
      >
        {isDeleteActive ? (
          <Checked css={styles.check} viewBox="0 0 26 26" />
        ) : (
          <TrashIcon viewBox="0 0 16 16" />
        )}
      </Button>
    </li>
  );
};
