import { useDefaultClaim } from '@modules/auth';
import { useFacility } from '@modules/facility';
import { LanguageSwitcherModal } from '@modules/translations';
import { useModal } from '@shared/hooks';
import { useRouter } from 'next/router';
import Logo from 'public/logo/playoff-logo-68.svg';
import { useEffect, useMemo, useState } from 'react';
import { LandingHeader, NavigationHeader, UserHeader } from './fragments';
import { styles } from './styles';

export const Header = () => {
  const { locale, pathname, push } = useRouter();
  const { closeModal, isModalOpen, openModal } = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const defaultClaim = useDefaultClaim();
  const currentFacility = useFacility();

  const isLandingPage = useMemo(() => pathname === '/', [pathname]);
  const isPrivacyPolicyScreen = useMemo(
    () => pathname === '/privacy-policy',
    [pathname],
  );
  const isTermsOfServiceScreen = useMemo(
    () => pathname === '/terms-of-service',
    [pathname],
  );
  const isPrivatePlayer = useMemo(
    () => pathname.includes('private-player'),
    [pathname],
  );
  const isAdmin = useMemo(
    () => pathname.includes('dashboard/admin'),
    [pathname],
  );
  const isEmployee = useMemo(
    () => pathname.includes('dashboard/employee'),
    [pathname],
  );
  const isPlayOffAdmin = useMemo(
    () => pathname.includes('dashboard/playoff-admin'),
    [pathname],
  );
  const isDashboard = useMemo(
    () => isAdmin || isPlayOffAdmin || isEmployee,
    [isAdmin, isPlayOffAdmin, isEmployee],
  );
  const is404Page = useMemo(() => pathname === '/404', [pathname]);

  const isNavigationHeaderShown = useMemo(
    () =>
      isPrivacyPolicyScreen ||
      isTermsOfServiceScreen ||
      is404Page ||
      pathname === '/dashboard',
    [isLandingPage, isPrivacyPolicyScreen, isTermsOfServiceScreen, pathname],
  );

  function handleAdminClick() {
    if (!defaultClaim?.role) {
      return '/dashboard';
    }

    if (currentFacility?.type === 'group') {
      return `/dashboard/${defaultClaim?.role}/groups`;
    }

    return `/dashboard/${defaultClaim?.role}/booking-calendar`;
  }

  useEffect(() => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  }, [pathname]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [isMenuOpen]);

  return (
    <>
      <header id="header" css={[styles.base, isDashboard && styles.dashboard]}>
        <Logo onClick={() => push('/')} css={styles.logo} />
        {isLandingPage && (
          <LandingHeader
            locale={locale}
            isLanguageSwitcherModalOpen={isModalOpen}
            isAdmin={isAdmin}
            isEmployee={isEmployee}
            openModal={openModal}
            handleAdminClick={handleAdminClick}
          />
        )}
        {isPrivatePlayer && (
          <UserHeader
            locale={locale}
            isLanguageSwitcherModalOpen={isModalOpen}
            openModal={openModal}
          />
        )}
        {isNavigationHeaderShown && (
          <NavigationHeader
            locale={locale}
            isLanguageSwitcherModalOpen={isModalOpen}
            isAdmin={isAdmin}
            isEmployee={isEmployee}
            openModal={openModal}
            handleAdminClick={handleAdminClick}
          />
        )}
        {isModalOpen && (
          <LanguageSwitcherModal
            handleCloseModal={closeModal}
            clickOutside={closeModal}
          />
        )}
      </header>
    </>
  );
};
