import { Locale, format, getDay, getMonth } from 'date-fns';
import { de, enUS, es, fr, hr, it, pt } from 'date-fns/locale';

const currentDate = new Date();
const currentMonth = getMonth(currentDate);
const currentDay = getDay(currentDate);

const capitalize = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

const getWeeklyLabels = (locale: Locale) => {
  return Array.from({ length: 7 }, (_, i) => {
    const day = (currentDay + i) % 7;
    return capitalize(format(new Date(0, 0, day + 1), 'EEE', { locale }));
  });
};

const getMonthlyLabels = (locale: Locale) => {
  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(
      currentDate.getFullYear(),
      currentMonth,
      i === 0 ? 1 : i * 5,
    );
    return capitalize(format(+date, 'MMM do', { locale }));
  });
};
/**
 * @markoboras0712 internationalization of labels
 */
export const RESERVATION_GRAPH_LABELS = {
  en: {
    weekly: getWeeklyLabels(enUS),
    monthly: getMonthlyLabels(enUS),
  },
  hr: {
    weekly: getWeeklyLabels(hr),
    monthly: getMonthlyLabels(hr),
  },
  es: {
    weekly: getWeeklyLabels(es),
    monthly: getMonthlyLabels(es),
  },
  fr: {
    weekly: getWeeklyLabels(fr),
    monthly: getMonthlyLabels(fr),
  },
  de: {
    weekly: getWeeklyLabels(de),
    monthly: getMonthlyLabels(de),
  },
  pt: {
    weekly: getWeeklyLabels(pt),
    monthly: getMonthlyLabels(pt),
  },
  it: {
    weekly: getWeeklyLabels(it),
    monthly: getMonthlyLabels(it),
  },
};
