import { facilityAtoms } from '@modules/facility';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

export function usePreviousCurrentFacility(
  value: CurrentFacility,
  lookup: string,
) {
  const [prevData, setPrevData] = useRecoilState(
    facilityAtoms.prevCurrentFacility(lookup),
  );
  useEffect(() => {
    setPrevData(value);
  }, [value, lookup]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return prevData;
}
