import { css } from '@emotion/react';
import { display } from '@styles/display.styles';
import { breakpoints } from '@styles/variables.styles';

const wrapper = css`
  display: flex;
  background-color: #16141bcc;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media ${breakpoints.fromMed} {
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
`;

const header = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;
const base = css`
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #f8f8f814;

  @media ${breakpoints.toMed} {
    height: 100vh;
    width: 100vw;
  }
`;

const columnOne = css`
  /* ${display.overflowAuto}; */
  height: 100%;
  max-width: 1008px;
  padding: 32px;
  background-color: var(--color-background-2);

  @media ${breakpoints.fromMed} {
    padding: 48px;
  }

  @media ${breakpoints.toMed} {
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const title = css`
  font-weight: 600;
  color: #ffffff;

  font-size: 24px;
  line-height: 32px;

  @media ${breakpoints.fromMed} {
    font-size: 36px;
    line-height: 40px;
  }
`;
const buttonClose = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid var(--color-border-6);
  padding: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 50%;
  transition: all 0.2s ease-out;

  svg {
    width: 1em !important;
    height: 1em !important;
    flex-shrink: 0;
  }

  & path {
    fill: white;
    flex-shrink: 0;
    transition: all 0.2s ease-out;
  }

  &:hover {
    background-color: #2a282f;
    transition: all 0.2s ease-out;
  }
`;

export const styles = {
  wrapper,
  base,
  columnOne,
  title,
  buttonClose,
  header,
};
