import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSetLoginData } from './useSetLoginData';

interface ErrorFormValues {
  firstName?: string;
  lastName?: string;
  password?: string;
}

export function useSetLoginDataForm() {
  const { query } = useRouter();
  const { formatMessage } = useIntl();
  const { setLoginData } = useSetLoginData();

  const [loading, setLoading] = useState(false);

  const uid = typeof query?.id === 'string' ? query.id : '';

  const requiredField = formatMessage({ id: 'auth-form.required.field' });
  const passwordLength = formatMessage({ id: 'auth-form.password.invalid' });

  const validate = (values: ErrorFormValues) => {
    const errors: ErrorFormValues = {};

    if (values?.password.length < 6) errors.password = passwordLength;
    if (!values?.password) errors.password = requiredField;

    if (!values.firstName) errors.firstName = requiredField;
    if (!values.lastName) errors.lastName = requiredField;

    return errors;
  };

  const formik = useFormik<ErrorFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      password: '',
    },
    validate,
    onSubmit: async (values) => {
      setLoading(true);
      await setLoginData(
        values.password,
        uid,
        `${values.firstName} ${values.lastName}`,
      );

      setLoading(false);
    },
  });

  return {
    formik,
    loading,
  };
}
