import { SerializedStyles } from '@emotion/react';
import { useAdminDashboardNavDropdown } from '@modules/admin';
import { useFirebaseAuth } from '@modules/auth';
import { useFacilities, useFacility } from '@modules/facility';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

const LogoutIcon = dynamic<{ css?: SerializedStyles }>(
  () => import('public/icons/logout-icon-16.svg'),
  {
    loading: () => null,
  },
);

const ArrowIcon = dynamic<{ css?: SerializedStyles }>(
  () => import('public/icons/arrow-icon-down-16.svg'),
  {
    loading: () => null,
  },
);

const IndicatorOn = dynamic<{ css?: SerializedStyles }>(
  () => import('public/icons/admin-nav-dropdown-item-16.svg'),
  {
    loading: () => null,
  },
);

const IndicatorOff = dynamic<{ css?: SerializedStyles }>(
  () => import('public/icons/admin-nav-dropdown-item-off-16.svg'),
  {
    loading: () => null,
  },
);

export const AdminDashboardNavDropdown: React.FC = () => {
  const { handleLogout } = useFirebaseAuth();

  const currentFacility = useFacility();
  const facilities = useFacilities();

  const {
    isDropdownVisible,
    dropdownRef,
    ref,
    isActive,
    handleContainerClick,
    handleFacilityChange,
  } = useAdminDashboardNavDropdown();

  return (
    <div
      css={styles.facilityInfo}
      ref={dropdownRef}
      onClick={handleContainerClick}
    >
      <ArrowIcon />
      <span css={styles.facilityName}>{currentFacility?.name}</span>
      <Image
        src={currentFacility?.logo || '/images/admin-dash-icon.png'}
        width={24}
        height={24}
        loading="lazy"
        alt="Admin dash icon"
      />{' '}
      <AnimatePresence>
        {isDropdownVisible && (
          <motion.div
            css={styles.dropdownContainer}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
          >
            {facilities?.map((facility) => (
              <li
                key={facility?.id}
                css={styles.listItem}
                onClick={() =>
                  handleFacilityChange(facility?.id, facility?.type)
                }
              >
                <Image
                  src={facility?.logo || '/images/admin-dash-icon.png'}
                  width={32}
                  height={32}
                  loading="lazy"
                  alt="Admin dash icon"
                />
                <span
                  css={[
                    styles.listItemName,
                    isActive(facility?.name, currentFacility?.name) &&
                      styles.active,
                  ]}
                >
                  {facility?.name}
                </span>
                <div css={styles.icon}>
                  {isActive(facility?.name, currentFacility?.name) ? (
                    <IndicatorOn />
                  ) : (
                    <IndicatorOff />
                  )}
                </div>
              </li>
            ))}
            <div ref={ref} css={styles.separator} />
            <div onClick={handleLogout} css={styles.logout}>
              <LogoutIcon />
              <span>
                <FormattedMessage id="admin-nav.logout" />
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
