import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const formButton = css`
  ${button.primary};
  font-family: var(--font-family-primarySemiBold);
  min-width: 240px;
  padding: 12px 72px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-1);
  margin-top: 16px;
  background-color: var(--color-general-primary);
  width: 100%;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    color: white;
    opacity: 1;
    background-color: var(--color-background-light);
  }

  &:disabled:hover,
  &:disabled:focus,
  &:disabled:active {
    color: black;
  }

  @media ${breakpoints.fromSml} {
    width: unset;
  }
`;

const switchText = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-top: 24px;
  transition: all 0.2s ease-out;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      transition: all 0.2s ease-out;
      color: var(--color-text-white);
    }
  }

  @media ${breakpoints.fromLrg} {
    margin-top: 32px;
  }
`;

const ctaButtons = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  gap: 16px;

  @media ${breakpoints.fromSml} {
    max-width: 240px;
  }
`;

const otherAccLoginContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 20px 0;

  p {
    color: var(--color-text-3);
  }

  @media ${breakpoints.fromMed} {
    margin-bottom: 0;
  }
`;

const registerBtnContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media ${breakpoints.fromSml} {
    flex-direction: row;
  }
`;

const registerBtn = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
  background-color: transparent;
  color: var(--color-text-5);
  border: 1px solid var(--color-border-6);
  padding: 12px 32px;
  transition: all 0.2s ease-out;

  svg {
    path {
      transition: all 0.2s ease-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--color-text-6);
        transition: all 0.2s ease-out;
      }
    }
    color: var(--color-text-6);
    transition: all 0.2s ease-out;
  }
`;

export const loginFormStyles = {
  formStyles,
  inputStyles,
  buttonStyles,
  formButton,
  ctaButtons,
  switchText,
  otherAccLoginContainer,
  registerBtnContainer,
  registerBtn,
};
