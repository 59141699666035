import { useIsDayView } from '@modules/booking-new';
import { FC } from 'react';
import { BookingDaySlots } from '../booking-day-slots/BookingDaySlots';
import { BookingWeekSlots } from '../booking-week-slots/BookingWeekSlots';

export const CalendarView: FC = () => {
  const isDay = useIsDayView();

  return <>{isDay ? <BookingDaySlots /> : <BookingWeekSlots />}</>;
};
