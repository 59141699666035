import { searchAtoms } from '@modules/search';
import SearchIcon from 'public/icons/search-12-disabled.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSetRecoilState } from 'recoil';
import { styles } from './styles';

interface Props {
  searchTerm: string;
  onSetSearchTerm: (searchTerm: string) => void;
}

export const SearchInput: React.FC<Props> = ({
  searchTerm,
  onSetSearchTerm,
}) => {
  const { formatMessage } = useIntl();
  const setRecentResultsOpen = useSetRecoilState(
    searchAtoms.isRecentResultsOpen,
  );

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onSetSearchTerm(value);
  };

  return (
    <>
      <div css={styles.inputIconStyles}>
        <SearchIcon />
      </div>

      <input
        type="text"
        placeholder={formatMessage({
          id: 'search-input.placeholder',
        })}
        value={searchTerm}
        onChange={handleSearch}
        onFocus={() => {
          setRecentResultsOpen(true);
        }}
        onBlur={() => {
          setRecentResultsOpen(false);
        }}
        css={styles.inputStyles}
      />
    </>
  );
};
