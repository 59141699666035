import { selector, useRecoilValue } from 'recoil';
import { getCurrentMonthYear } from '../utils';
import { analyticsSelector } from './selectors';

/* Current year revenue */
const currentYearRevenue = selector({
  key: 'currentYearRevenue',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const currentYearBookings = analytics?.bookings?.filter((booking) => {
      return new Date(booking.date).getFullYear() === currentYear;
    });

    currentYearBookings?.forEach(({ revenue, date }) => {
      const month = new Date(date).getMonth();
      revenueByMonth[month] += revenue;
    });

    return revenueByMonth;
  },
});

const lastYearRevenue = selector({
  key: 'lastYearRevenue',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const lastYearBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return bookingDate.getFullYear() === currentYear - 1;
    });

    lastYearBookings?.forEach(({ revenue, date }) => {
      const month = new Date(date).getMonth();
      revenueByMonth[month] += revenue;
    });

    return revenueByMonth;
  },
});

const currentMonthRevenue = selector({
  key: 'currentMonthRevenue',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0];

    const currentMonthBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return (
        bookingDate.getMonth() === currentMonth &&
        bookingDate.getFullYear() === currentYear
      );
    });

    const filteredBookings = currentMonthBookings?.filter((booking) => {
      const day = new Date(booking.date).getDate();
      return [1, 5, 10, 15, 20, 25, 30].includes(day);
    });

    filteredBookings?.forEach((booking) => {
      const day = new Date(booking.date).getDate();
      const index = [1, 5, 10, 15, 20, 25, 30].indexOf(day);
      revenueByMonth[index] += booking.revenue;
    });

    return revenueByMonth;
  },
});

const pastMonthRevenue = selector({
  key: 'pastMonthRevenue',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0];

    const pastMonthBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);

      return (
        bookingDate.getMonth() === currentMonth - 1 &&
        bookingDate.getFullYear() === currentYear
      );
    });

    const filteredBookings = pastMonthBookings?.filter((_, index) => {
      return index === 0 || (index + 1) % 5 === 0;
    });

    filteredBookings?.forEach((booking) => {
      const day = new Date(booking.date).getDate();
      const index = [1, 5, 10, 15, 20, 25, 30].indexOf(day);
      revenueByMonth[index] += booking.revenue;
    });

    return revenueByMonth;
  },
});
/* Revenue graph */
export const useCurrentYearRevenue = () => useRecoilValue(currentYearRevenue);
export const useLastYearRevenue = () => useRecoilValue(lastYearRevenue);
export const useCurrentMonthRevenue = () => useRecoilValue(currentMonthRevenue);
export const usePastMonthRevenue = () => useRecoilValue(pastMonthRevenue);
