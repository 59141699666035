import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const valueContainer = css`
  display: flex;
  gap: 16px;
  color: white;

  @media ${breakpoints.toMed} {
    svg {
      display: none;
    }
  }
`;

const label = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--color-text-4);
`;

const labelStar = css`
  color: #acef34;
`;

const wrapper = css`
  margin-top: 0;
`;

export const styles = { valueContainer, label, labelStar, wrapper };

export const selectProps = {
  styles: {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#1f1d24',
      height: '44px',
      width: '100%',
      minWidth: '212px',
      cursor: 'pointer',
      marginTop: '8px',
      borderRadius: '0',
      borderColor: 'transparent',
      boxShadow: 'none',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      '&:hover': {
        border: '1px solid #61645e',
      },
    }),
    placeholder: (styles, { isFocused }) => ({
      ...styles,
      // display: isFocused ? 'none' : 'flex',
      height: '19px',
      color: '#737373',
    }),
    valueContainer: (styles) => ({
      ...styles,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '12px 16px',
      height: '100%',
    }),
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    input: (styles) => ({
      ...styles,
      height: '19px',
      padding: '0',
      margin: '0',
      color: 'white',
    }),
    menu: (styles) => ({
      ...styles,
      backgroundColor: '#1f1d24',
      border: '1px solid #F8F8F814',
      borderRadius: '12px',
      marginTop: 4,
      marginBottom: 0,
      boxShadow: 'none',
      width: '100%',
      overflow: 'auto',
    }),
    menuList: (styles) => ({
      ...styles,
      padding: 0,
      overflow: 'auto',
    }),
    option: (styles) => ({
      ...styles,
      color: 'white',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#3C3A44',
      },
      '&:active': {
        backgroundColor: '#504F5C',
      },
    }),
  },
};
