import { RevenueGraphOption } from '@modules/analytics';
import { useClickOutsideElement } from '@shared/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import ArrowIconDown from 'public/icons/arrow-icon-down-16.svg';
import ArrowIconUp from 'public/icons/arrow-icon-up-16.svg';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

interface Props {
  selectedOption: string;
  onSetSelectedOption: (option: string) => void;
}

/**@markoboras0712 internationalize this */
const revenueGraphResultsKeys = ['current-year', 'last-year'];

export const GroupRevenueGraphSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const { locale } = useRouter();
  const { formatMessage } = useIntl();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const optionsContainerRef = useClickOutsideElement<HTMLDivElement>(() =>
    setIsSelectOpen(false),
  );

  return (
    <>
      <div css={styles.selectWrapper}>
        <p css={styles.showLabel}>
          <FormattedMessage id="revenue.graph.select.label" />
        </p>

        <div onClick={() => setIsSelectOpen(!isSelectOpen)} css={styles.select}>
          <span css={styles.selectedOption}>{selectedOption}</span>
          {isSelectOpen ? <ArrowIconDown /> : <ArrowIconUp />}
        </div>
      </div>
      <AnimatePresence>
        {isSelectOpen && (
          <motion.div
            css={styles.recentResultsContainer}
            ref={optionsContainerRef}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
          >
            <span css={styles.recentResultsTitle}>
              <FormattedMessage id="revenue.graph.select.dropdown.label" />
            </span>
            {revenueGraphResultsKeys.map((key, index) => {
              const optionLabel = formatMessage({
                id: `group-revenue.graph.results.${key}`,
              });
              return (
                <RevenueGraphOption
                  key={index}
                  option={optionLabel}
                  isActive={optionLabel === selectedOption}
                  onSetSelectedOption={() => onSetSelectedOption(optionLabel)}
                  onClose={() => setIsSelectOpen(false)}
                />
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
