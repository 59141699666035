import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useDefaultClaim, useUserId, useUserIdLoading } from '../store';

export function useAuthRedirects() {
  const router = useRouter();

  const [loading, setLoading] = useState(true);

  const userId = useUserId();
  const defaultClaim = useDefaultClaim();
  const userIdLoading = useUserIdLoading();
  const isLoading = useMemo(
    () => userIdLoading || loading,
    [userIdLoading, loading],
  );

  useEffect(() => {
    if (userIdLoading) {
      return;
    }
    if (!userId) {
      router.push(router.asPath);
      setLoading(false);
      return;
    }

    if (userId && !defaultClaim) {
      router.push('/dashboard');
      return;
    }

    if (userId) {
      router.push(
        `/dashboard/${defaultClaim?.role || 'private-player'}/booking-calendar`,
      );
      return;
    }
  }, [userId, userIdLoading, defaultClaim]);

  return { loading: isLoading };
}
