import { SerializedStyles } from '@emotion/react';
import dynamic from 'next/dynamic';
import { components } from 'react-select';
import { CustomOptionProps } from '../../role-select';
import { styles } from './styles';

const { Option } = components;

const GroupIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/group.svg'),
  {
    loading: () => null,
  },
);

export const CustomSelectOption = (props: CustomOptionProps) => (
  <Option {...props}>
    <div css={styles.valueContainer}>
      <GroupIcon viewBox="0 0 16 16" />
      <span css={styles.placeholder}>{props.data.label}</span>
    </div>
  </Option>
);
