import { BookedHours } from '../models';

/**
 * Calculates the final price for a paintball package.
 *
 * @param {Package} packageData - The package data.
 * @param {number} numberOfUsers - The number of users.
 * @param {number} facilityIntervalInMinutes - The facility interval in minutes.
 * @returns {number} The final price.
 */
export function getPaintballPrice(
  packageData: Package,
  numberOfUsers: number,
  facilityIntervalInMinutes: number,
) {
  if (!packageData) return 0;

  const numberOfSlots = Math.floor(
    facilityIntervalInMinutes /
      (packageData?.interval * packageData?.minSlotsInBooking),
  );
  const price = getCalculatedSlotPrice(packageData, numberOfUsers);
  const finalPrice = Math.max(numberOfSlots * price, price);

  return finalPrice;
}

/**
 * Calculates the slot price based on the package type.
 *
 * @param {Package} packageData - The package data.
 * @param {number} numberOfUsers - The number of users.
 * @returns {number} The calculated slot price.
 * @throws {Error} If the package type is unsupported.
 */
function getCalculatedSlotPrice(
  packageData: Package,
  numberOfUsers: number,
): number {
  switch (packageData.type) {
    case 'perInterval':
      return packageData.price;
    case 'perPerson':
      return packageData.price * numberOfUsers;
    default:
      throw new Error('Unsupported package type');
  }
}

/**
 * Calculates the number of minutes between 'from' and 'to' in a BookedHours object.
 *
 * @param {BookedHours} bookedHour - The booked hours with 'from' and 'to' properties.
 * @returns {number} The number of minutes, always positive.
 */
export function getMinutesBetween(bookedHour: BookedHours): number {
  const minutes = (bookedHour.to - bookedHour.from) / (1000 * 60);
  return Math.abs(minutes); // Use Math.abs to ensure a positive result
}
