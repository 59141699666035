import { css } from '@emotion/react';

const base = css`
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4f4d56;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #2a282f;
    border-radius: 8px;
  }
`;

export const scroll = { base };
