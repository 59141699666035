import { AdminDashboard } from '@modules/admin';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { styles } from './styles';

interface Props {
  children: ReactNode;
}

export const BookingOverviewLayout: React.FC<Props> = ({ children }) => {
  const { formatMessage } = useIntl();

  return (
    <AdminDashboard
      title={formatMessage({ id: 'admin.history.title' })}
      description={formatMessage({ id: 'admin.history.description' })}
      containerStyles={styles.adminMain}
      mainStyles={styles.bookingHistoryMain}
    >
      {children}
    </AdminDashboard>
  );
};
