import { css } from '@emotion/react';

const input = css`
  overflow: hidden;
  width: 204px;
  height: 36px;
  padding: 8px 20px 8px 48px;
  font-size: 0;
  color: transparent;
  background-color: var(--color-background-3);
  border-radius: 28px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  :hover {
    background-color: var(--color-background-4);
  }
`;

const inputDay = css`
  ${input}
  width: 260px;
`;

const wrapper = css`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const button = css`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 6px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #f8f8f81a;
  transition: all 0.2s ease-in-out;

  svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }

  :hover {
    border: 1px solid var(--color-border-5);

    svg {
      path {
        fill: var(--color-border-5);
      }
    }
  }
`;

const buttonRight = css`
  ${button}
  transform: rotate(180deg);
`;

const calendarWrapper = css`
  position: relative;
`;

const calendarIcon = css`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);

  path {
    fill: var(--color-icon-4);
  }
`;

const dateRange = css`
  position: absolute;
  left: calc(50% + 8px);
  top: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  font-family: var(--font-family-primarySemiBold);
  color: var(--color-text-6);
  background-color: transparent;
  pointer-events: none;
  cursor: pointer;
  text-transform: capitalize;
`;

export const styles = {
  input,
  wrapper,
  button,
  buttonRight,
  calendarWrapper,
  calendarIcon,
  dateRange,
  inputDay,
};
