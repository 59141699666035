import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { grid } from '@styles/grid.styes';

const adminMain = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-auto-rows: auto;
  width: 100%;
`;

const email = css`
  grid-column: 1 / 7;
`;

const displayName = css`
  ${email}
  margin-bottom: 32px;
`;

const roleSelect = css`
  grid-column: 1 / 7;
`;

const cta = css`
  grid-column: 1 / 7;

  button {
    padding: 8px 32px;
  }
`;

const facilitySelect = css`
  grid-column: 7 / 13;
`;

const formStyles = css`
  /* display: flex;
  flex-direction: column; */

  /* input {
    max-width: 440px;
  } */

  ${grid.basic12col}
  padding: 0;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const formButton = css`
  ${button.primary};
  font-weight: 700;
  padding: 12px 72px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-1);
  margin-top: 32px;
  background-color: var(--color-general-primary);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }
`;

const switchText = css`
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      color: white;
    }
  }
`;

const usersManagementMain = css`
  grid-column: 1 / 11;
  margin-bottom: 240px;
`;

const groups = css`
  grid-column: 1 / 13;
`;

export const styles = {
  adminMain,
  formStyles,
  inputStyles,
  buttonStyles,
  formButton,
  switchText,
  email,
  roleSelect,
  cta,
  groups,
  facilitySelect,
  displayName,
  usersManagementMain,
};
