import { useGroups } from '@modules/groups/state';
import { useClickOutsideElement } from '@shared/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowIconDown from 'public/icons/arrow-icon-down-16.svg';
import ArrowIconUp from 'public/icons/arrow-icon-up-16.svg';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { HotspotsGraphOption } from '../hotspots-graph-option';
import { styles } from './styles';

interface Props {
  selectedOption: string;
  onSetSelectedOption: (option: string) => void;
}

export const GroupsGraphSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const groups = useGroups();

  const optionsContainerRef = useClickOutsideElement<HTMLDivElement>(() =>
    setIsSelectOpen(false),
  );

  return (
    <>
      <div css={styles.selectWrapper}>
        <p css={styles.showLabel}>
          <FormattedMessage id="groups.graph.select.label" />
        </p>

        <div onClick={() => setIsSelectOpen(!isSelectOpen)} css={styles.select}>
          <span css={styles.selectedOption}>{selectedOption}</span>
          {isSelectOpen ? <ArrowIconDown /> : <ArrowIconUp />}
        </div>
      </div>
      <AnimatePresence>
        {isSelectOpen && (
          <motion.div
            css={styles.recentResultsContainer}
            ref={optionsContainerRef}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
          >
            <span css={styles.recentResultsTitle}>
              <FormattedMessage id="groups.graph.select.dropdown.label" />
            </span>
            {groups.map((option: Group, index: number) => (
              <HotspotsGraphOption
                key={option?.id || index}
                option={option?.name || ''}
                isActive={option?.name === selectedOption}
                onSetSelectedOption={onSetSelectedOption}
                onClose={() => setIsSelectOpen(false)}
              />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
