import { css } from '@emotion/react';

const buttonWrapper = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-top: 20px;

  button {
    width: 50%;
    min-width: max-content;
  }
`;

const buttonConfirm = css`
  padding: 8px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  font-family: var(--font-family-primarySemiBold);
  color: var(--color-text-1);
  background-color: var(--color-general-primary);
  transition: all 0.2s ease-out;

  :hover {
    background-color: var(--color-general-primary-plus1);
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.4;

    :hover {
      background-color: var(--color-general-primary);
    }
  }
`;

const buttonConfirmDesctructive = css`
  ${buttonConfirm};
  background-color: var(--color-error-utility);
  color: var(--color-text-6);

  :hover {
    background-color: var(--color-error-utility);
  }

  :disabled {
    :hover {
      background-color: var(--color-error-utility);
    }
  }
`;

const cancel = css`
  border: none;
  cursor: pointer;
  color: var(--color-text-5);
  background-color: var(--color-background-5);
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;

  :hover {
    background-color: var(--color-background-6);
    border-color: var(--color-border-cardBorder);
  }

  :disabled {
    opacity: 0.4;
    cursor: pointer;
  }
`;

export const styles = {
  buttonWrapper,
  buttonConfirm,
  buttonConfirmDesctructive,
  cancel,
};
