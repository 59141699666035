import { format, isSameDay } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useActiveCourt, useBookings } from '../state';
import { useBookingHelpers } from './useBookingHelpers';

interface List {
  from: Date;
  to: Date;
  isAvailable: boolean;
  price: number | null;
}

/**This function checks the time range of the facility and the time range of the court and returns the time range of the
 * court for the current day*/

export function useGenerateHoursList(
  globalHours: WorkHour[],
  interval: number,
  currentDay: Date,
  timeRange?: TimeRange,
  workSchedules?: WorkSchedule[],
) {
  const { convertDateHoursOfOtherDate } = useBookingHelpers();
  const dayOfSlot = format(currentDay, 'iiii', { locale: enUS });
  const allBookings = useBookings();
  const activeCourt = useActiveCourt();
  const bookings = allBookings?.filter(
    ({ status, courtId, startTime }) =>
      status === 'accepted' &&
      courtId === activeCourt?.id &&
      isSameDay(new Date(startTime), currentDay),
  );

  const currentWorkSchedule = workSchedules?.find(({ ranges }) =>
    ranges.some(
      ({ from, to }) =>
        (from.month < currentDay.getMonth() + 1 ||
          (from.month === currentDay.getMonth() + 1 &&
            from.day <= currentDay.getDate())) &&
        (to.month > currentDay.getMonth() + 1 ||
          (to.month === currentDay.getMonth() + 1 &&
            to.day >= currentDay.getDate())),
    ),
  );

  // Use the working hours from the current work schedule, or the global hours if no schedule is found
  const hours = currentWorkSchedule
    ? currentWorkSchedule.workingHours?.[dayOfSlot]
    : globalHours;

  if (!timeRange || !hours) return { list: [] };

  let start = convertDateHoursOfOtherDate(
    new Date(timeRange.startTime),
    currentDay,
  );

  const end = convertDateHoursOfOtherDate(
    new Date(timeRange.endTime),
    currentDay,
  );

  if (start.getHours() >= end.getHours()) {
    end.setDate(end.getDate() + 1);
  }

  const list: List[] = [];

  // Convert the start and end times of the working hours to the hours of the current day
  const hoursWithTimes = hours.map((range) => {
    const fromDate = convertDateHoursOfOtherDate(
      new Date(range.from),
      currentDay,
    );
    const toDate = convertDateHoursOfOtherDate(new Date(range.to), currentDay);

    // If to time is 00:00, consider it as end of the day by adding 1 day
    if (toDate.getHours() === 0 && toDate.getMinutes() === 0) {
      toDate.setDate(toDate.getDate() + 1);
    }

    return {
      from: fromDate.getTime(),
      to: toDate.getTime(),
      price: range.price,
    };
  });

  // Function to check if a given date falls within any of the working hours
  function isDateInTimeRange(date: Date) {
    const time = date.getTime();

    for (const range of hoursWithTimes) {
      if (time > range.from && time <= range.to) {
        return { isAvailable: true, price: range.price };
      }
    }

    return { isAvailable: false, price: undefined };
  }

  // Generate the list of time slots

  while (start < end) {
    let nextDateTime = new Date(start);
    let isBookingAdjusted = false;

    // Find a booking that impacts the current start time
    const bookingImpact = bookings?.find(({ startTime, endTime }) => {
      const bookingStart = new Date(startTime);
      const bookingEnd = new Date(endTime);
      return (
        bookingStart.getTime() <= start.getTime() &&
        bookingEnd.getTime() > start.getTime()
      );
    });

    if (bookingImpact) {
      // Adjust start time to the end of the impacting booking
      const bookingEnd = new Date(bookingImpact.endTime);
      start = new Date(Math.max(start.getTime(), bookingEnd.getTime()));
      isBookingAdjusted = true;
    }

    if (!isBookingAdjusted) {
      // Only advance the start by the interval if no booking adjustment was needed
      start.setMinutes(start.getMinutes() + interval);
    }

    let newStart = new Date(start);

    // Ensure the loop does not incorrectly terminate by checking date and time
    if (nextDateTime >= end && nextDateTime.getDate() !== newStart.getDate()) {
      // If the date has changed (crossed midnight), adjust the end time to continue the loop
      end.setDate(end.getDate() + 1);
    }

    // Now, let's check if the new start time falls within any of the working hours
    const dateInRangeInfo = isDateInTimeRange(newStart);

    if (nextDateTime < newStart) {
      // Ensure we do not add invalid time ranges
      list.push({
        from: nextDateTime,
        to: newStart,
        isAvailable: dateInRangeInfo.isAvailable,
        price: dateInRangeInfo.price,
      });
    }
  }

  return { list };
}
