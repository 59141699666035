import { css } from '@emotion/react';

const base = css`
  position: relative;
  grid-column: 1 / span 10;
  display: flex;
  gap: 8px;
  width: 100%;
`;

const wrapper = css`
  position: relative;
  grid-column: 1 / span 9;
`;

const baseLarge = css`
  ${base}
  padding-right: 332px;
`;

const overlay = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 332px;
  background: var(--color-background-2);
  z-index: 1;
`;

export const styles = { base, wrapper, baseLarge, overlay };
