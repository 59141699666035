import { format, getMonth } from 'date-fns';
import { hr } from 'date-fns/locale';

const currentDate = new Date();
const currentMonth = getMonth(currentDate);

/**@markoboras0712 internationalize this */
export function getRevenueGraphMonthlyLabels(
  locale: string,
  isPastMonth?: boolean,
) {
  if (locale === 'en') {
    return {
      monthly: Array.from({ length: 7 }, (_, i) => {
        const date = new Date(
          currentDate.getFullYear(),
          isPastMonth ? currentMonth - 1 : currentMonth,
          i * 5 + 1,
        );
        return format(+date, 'MMM do');
      }),
      yearly: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
    };
  }

  return {
    monthly: Array.from({ length: 7 }, (_, i) => {
      const date = new Date(
        currentDate.getFullYear(),
        isPastMonth ? currentMonth - 1 : currentMonth,
        i === 0 ? 1 : i * 5,
      );
      return format(+date, 'do MMM', { locale: hr });
    }),
    yearly: [
      'Sij',
      'Velj',
      'Ožu',
      'Tra',
      'Svi',
      'Lip',
      'Srp',
      'Kol',
      'Ruj',
      'Lis',
      'Stu',
      'Pro',
    ],
  };
}
