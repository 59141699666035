import React from 'react';
import { styles } from '../styles';

interface Props {
  label: BookingOverviewType;
  isActive?: boolean;
  onSetSelectedOption: (option: BookingOverviewType) => void;
}

export const BookingOverviewFilter: React.FC<Props> = ({
  label,
  isActive,
  onSetSelectedOption,
}) => {
  return (
    <li
      css={[styles.bookingStatusPicker, isActive ? styles.active : '']}
      onClick={() => onSetSelectedOption(label)}
    >
      {label}
    </li>
  );
};
