import { SerializedStyles } from '@emotion/react';
import {
  bookingFirestoreConverter,
  convertPrice,
  useBookingsDocument,
  useCanceledBookingsListener,
  useIsLoading,
  useSetBookings,
  useSetIsLoading,
} from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { FirebaseService, getTypedCollectionDocument } from '@modules/firebase';
import {
  useClickOutsideElement,
  useCloseOnEscape,
  useSubmitOnEnter,
} from '@shared/hooks';
import { Button } from '@ui/Button';
import { animations } from 'const';
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  writeBatch,
} from 'firebase/firestore';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'sonner';
import { BookingHistoryCard } from '../booking-history-card';
import { styles } from './styles';

const User = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/user-16.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  data?: Booking;
  isRepeat?: boolean;
  closeModal: () => void;
}

export const CancelBookingModal: React.FC<Props> = ({
  data,
  isRepeat,
  closeModal,
}) => {
  const { cancelledBookings } = useCanceledBookingsListener(data);
  const ref = useCloseOnEscape<HTMLButtonElement>();
  const { formatMessage } = useIntl();
  const { updateBooking } = useBookingsDocument(data.id);
  const submitRef = useSubmitOnEnter<HTMLButtonElement>();
  const closeRef = useClickOutsideElement<HTMLDivElement>(closeModal);
  const isLoading = useIsLoading();
  const setIsLoading = useSetIsLoading();
  const setBookings = useSetBookings();
  const facility = useFacility();

  async function handleCancelBooking() {
    try {
      setIsLoading(true);
      setBookings((prev) => prev.filter((booking) => booking.id !== data.id));
      await updateBooking({
        ...data,
        status: 'cancelled',
        canceledBy: 'admin',
      });
      toast.success(
        formatMessage({ id: 'cancel.booking.modal.toast.success' }),
      );
    } catch {
      toast.error(formatMessage({ id: 'cancel.booking.modal.toast.error' }));
    } finally {
      closeModal();
      setIsLoading(false);
    }
  }

  async function handleCancelRepeatedBooking() {
    const batch = writeBatch(FirebaseService.firestore);

    const updateBooking = (booking: Booking) => {
      const bookinsRef = doc(FirebaseService.firestore, 'bookings', booking.id);
      const bookingData = bookingFirestoreConverter.toFirestore(booking);
      batch.update(bookinsRef, bookingData);
    };

    const getRepeatedBookings = async () => {
      const firebaseQuery = query(
        collection(FirebaseService.firestore, 'bookings'),
        where('facilityId', '==', facility?.id),
        where('repeatId', '==', data.repeatId),
        where('startTime', '>', +new Date()),
      ).withConverter(bookingFirestoreConverter);
      const snapshot = await getDocs(firebaseQuery);
      const bookings = snapshot.docs.flatMap((doc) =>
        getTypedCollectionDocument<Booking[]>(doc),
      );
      return bookings;
    };

    try {
      const repeatedBookings = await getRepeatedBookings();
      setIsLoading(true);
      // Update main booking
      updateBooking({
        ...data,
        isRepeatCancelled: true,
        ...(repeatedBookings.some((booking) => booking.id === data?.id) && {
          status: 'cancelled',
          canceledBy: 'admin',
        }),
      });
      /**Filter out from recoil */
      setBookings((prev) =>
        prev.filter((prevBooking) => prevBooking.id !== data.id),
      );

      for (const booking of repeatedBookings) {
        if (booking.isFirstRepeat) continue;

        updateBooking({
          ...booking,
          status: 'cancelled',
          canceledBy: 'admin',
        });
        /**Filter out from recoil */
        setBookings((prev) =>
          prev.filter((prevBooking) => prevBooking.id !== booking.id),
        );
      }
      await batch.commit();
      toast.success(
        formatMessage({ id: 'cancel.booking.modal.toast.success' }),
      );
    } catch {
      toast.error(formatMessage({ id: 'cancel.booking.modal.toast.error' }));
    } finally {
      closeModal();
      setIsLoading(false);
    }
  }

  return (
    <AnimatePresence>
      <motion.div css={styles.wrapper} {...animations.opacity({})}>
        <div css={styles.base} ref={closeRef}>
          <div css={styles.columnOne}>
            <h2 css={styles.title}>
              <FormattedMessage id="cancel.booking.modal.title" />
            </h2>

            {cancelledBookings?.length >= 2 ? (
              <>
                <h2 css={styles.description}>
                  <FormattedMessage id="cancel.booking.modal.cancelled-bookings.title" />
                </h2>
                <span css={styles.description}>
                  <FormattedMessage id="cancel.booking.modal.cancelled-bookings.description" />
                </span>
                <div>
                  <span css={styles.title}>Način plaćanja</span>
                  <span css={styles.description}>
                    {data?.paymentId ? 'Kartično' : 'Gotovina'}
                  </span>
                </div>
                {data?.selectedPackagedBookingData?.category && (
                  <div css={styles.paintballContainer}>
                    <div css={styles.packageDescriptionContainer}>
                      <p css={styles.packageTitle}>
                        {data?.selectedPackagedBookingData?.package?.name || ''}
                      </p>
                      <div css={styles.paintballHoursWrapper}>
                        {`€${convertPrice(
                          data?.selectedPackagedBookingData?.package?.price,
                        )}`}{' '}
                        /{' '}
                        {data?.selectedPackagedBookingData?.package?.type ===
                        'perInterval'
                          ? 'terminu'
                          : 'osobi'}{' '}
                        {data?.selectedPackagedBookingData?.package?.interval *
                          data?.selectedPackagedBookingData?.package
                            ?.minSlotsInBooking}{' '}
                        minuta
                      </div>
                      <span css={styles.paintballHoursWrapper}>
                        {
                          data?.selectedPackagedBookingData?.package
                            ?.description
                        }
                      </span>
                    </div>
                    <div css={styles.numberOfPlayersContainer}>
                      <User viewBox="0 0 16 16" />
                      <span>
                        {data?.selectedPackagedBookingData?.numberOfUsers}
                      </span>
                    </div>
                  </div>
                )}

                <div css={styles.canceledBookingsContainer}>
                  {cancelledBookings.map((booking, i) => (
                    <React.Fragment key={booking.id || i}>
                      <BookingHistoryCard
                        key={booking.id || ''}
                        status="upcoming"
                        data={booking}
                        isRepeat={isRepeat}
                        isCancelModal
                      />
                    </React.Fragment>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h3 css={styles.description}>
                  <FormattedMessage id="cancel.booking.modal.description" />
                </h3>
                <div>
                  <span css={styles.title}>Način plaćanja</span>
                  <span css={styles.description}>
                    {data?.paymentId ? 'Kartično' : 'Gotovina'}
                  </span>
                </div>
                {data?.selectedPackagedBookingData?.category && (
                  <div css={styles.paintballContainer}>
                    <div css={styles.packageDescriptionContainer}>
                      <p css={styles.packageTitle}>
                        {data?.selectedPackagedBookingData?.package?.name || ''}
                      </p>
                      <div css={styles.paintballHoursWrapper}>
                        {`€${convertPrice(
                          data?.selectedPackagedBookingData?.package?.price,
                        )}`}{' '}
                        /{' '}
                        {data?.selectedPackagedBookingData?.package?.type ===
                        'perInterval'
                          ? 'terminu'
                          : 'osobi'}{' '}
                        {data?.selectedPackagedBookingData?.package?.interval *
                          data?.selectedPackagedBookingData?.package
                            ?.minSlotsInBooking}{' '}
                        minuta
                      </div>
                      <span css={styles.paintballHoursWrapper}>
                        {
                          data?.selectedPackagedBookingData?.package
                            ?.description
                        }
                      </span>
                    </div>
                    <div css={styles.numberOfPlayersContainer}>
                      <User viewBox="0 0 16 16" />
                      <span>
                        {data?.selectedPackagedBookingData?.numberOfUsers}
                      </span>
                    </div>
                  </div>
                )}
                <BookingHistoryCard
                  key={data.id || ''}
                  status="upcoming"
                  data={data}
                  isRepeat={isRepeat}
                  isCancelModal
                />
              </>
            )}

            <div css={styles.buttonContainer}>
              <Button
                type="submit"
                disabled={isLoading}
                customStyles={styles.confirmButton}
                onClick={
                  isRepeat ? handleCancelRepeatedBooking : handleCancelBooking
                }
                ref={submitRef}
              >
                <FormattedMessage id="cancel.booking.modal.confirm" />
              </Button>
              <Button
                type="button"
                customStyles={styles.cancelButton}
                onClick={closeModal}
                ref={ref}
              >
                <FormattedMessage id="cancel.booking.modal.cancel" />
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
