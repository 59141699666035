import { useIsDayView } from '@modules/booking-new';
import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import { DateDayCalendar } from './DateDayCalendar';
import { DateWeekCalendar } from './DateWeekCalendar';

export const DateCalendar: FC = () => {
  const isDay = useIsDayView();

  return (
    <AnimatePresence mode="wait">
      {isDay ? <DateDayCalendar /> : <DateWeekCalendar />}
    </AnimatePresence>
  );
};
