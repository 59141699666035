import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

export const groups = atom<Group[]>({
  key: 'group.group',
  default: [],
});

export const newGroupMemberName = atom<string>({
  key: 'group.newGroupMemberName',
  default: '',
});

export const newGroupMemberSurname = atom<string>({
  key: 'group.newGroupMemberSurname',
  default: '',
});

export const newGroupMemberEmail = atom<string>({
  key: 'group.newGroupMemberEmail',
  default: '',
});

export const newGroupMemberPhone = atom<string>({
  key: 'group.newGroupMemberPhone',
  default: '',
});

export const currentGroupMember = atom<GroupMember | null>({
  key: 'group.currentGroupMember',
  default: null,
});

export const isLoading = atom<boolean>({
  key: 'group.isLoading',
  default: false,
});

export const useGroups = () => useRecoilValue(groups);
export const useNewGroupMemberName = () => useRecoilValue(newGroupMemberName);
export const useNewGroupMemberSurname = () =>
  useRecoilValue(newGroupMemberSurname);
export const useNewGroupMemberEmail = () => useRecoilValue(newGroupMemberEmail);
export const useNewGroupMemberPhone = () => useRecoilValue(newGroupMemberPhone);
export const useCurrentGroupMember = () => useRecoilValue(currentGroupMember);
export const useIsLoading = () => useRecoilValue(isLoading);

export const useSetGroups = () => useSetRecoilState(groups);
export const useSetNewGroupMemberName = () =>
  useSetRecoilState(newGroupMemberName);
export const useSetNewGroupMemberSurname = () =>
  useSetRecoilState(newGroupMemberSurname);
export const useSetNewGroupMemberEmail = () =>
  useSetRecoilState(newGroupMemberEmail);
export const useSetNewGroupMemberPhone = () =>
  useSetRecoilState(newGroupMemberPhone);
export const useSetCurrentGroupMember = () =>
  useSetRecoilState(currentGroupMember);
export const useSetIsLoading = () => useSetRecoilState(isLoading);

export const useResetCurrentGroupMember = () =>
  useResetRecoilState(currentGroupMember);
