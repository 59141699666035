import { atom } from 'recoil';

export const analytics = atom<FacilityAnalytics | null>({
  key: 'analytics',
  default: { bookings: [], hotspots: [] },
});

export const groupAnalytics = atom<GroupAnalytics | null>({
  key: 'analytics.group',
  default: null,
});

export const error = atom<string | undefined>({
  key: 'analytics.error',
  default: undefined,
});

const loadingDocument = atom<boolean>({
  key: 'analytics.loading',
  default: true,
});

export const analyticsAtoms = {
  analytics,
  groupAnalytics,
  error,
  loadingDocument,
};
