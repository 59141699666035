import { FILTER_RESULTS } from '@modules/booking-overview';
import { useRouter } from 'next/router';
import React from 'react';
import { BookingOverviewFilter } from './fragments';
import { styles } from './styles';

interface Props {
  selectedOption: BookingOverviewType;
  onSetSelectedOption: (option: BookingOverviewType) => void;
}

export const BookingOverviewFilters: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const { locale } = useRouter();

  function handleSetSelectedOption(option: BookingOverviewType) {
    onSetSelectedOption(option);
  }

  return (
    <div css={styles.selectWrapper}>
      <ul css={styles.bookingStatusList}>
        {FILTER_RESULTS[locale].map(
          (label: BookingOverviewType, index: number) => (
            <BookingOverviewFilter
              key={index}
              isActive={selectedOption === label}
              label={label}
              onSetSelectedOption={handleSetSelectedOption}
            />
          ),
        )}
      </ul>
    </div>
  );
};
