import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

//TODO: Quick fix, should be imported from groups.d.ts
interface Group {
  id: string;
  capacity: number;
  name: string;
  sportName: string;
  price: number;
  trainingTimes: Train;
  members: GroupMember[];
  facilityId: string;
  trainerId: string;
}

class Group {
  constructor(data?: Group) {
    this.id = data?.id || '';
    this.capacity = data?.capacity || 0;
    this.name = data?.name || '';
    this.sportName = data?.sportName || '';
    this.price = data?.price || 0;
    this.trainingTimes = data?.trainingTimes;
    this.members = data?.members || [];
    this.facilityId = data?.facilityId || '';
    this.trainerId = data?.trainerId || '';
  }
}

/**
 * Converts Booking to and from Firestore.
 */
export const groupsFirestoreConverter: FirestoreDataConverter<Group> = {
  /**
   * Converts a Group object to Firestore format.
   *
   * @param {Group} group The Group object to convert.
   * @returns {Object} The Firestore representation of the Group object.
   */
  toFirestore: (group: Group): object => {
    const firestoreData = {
      ...new Group(group),
    };
    return firestoreData;
  },

  /**
   * Converts a Firestore snapshot to a Booking object.
   *
   * @param {QueryDocumentSnapshot<Group>} snapshot The Firestore snapshot to convert.
   * @returns {Group} The Group object created from the snapshot data.
   */
  fromFirestore: (snapshot: QueryDocumentSnapshot<Group>): Group => {
    const firestoreData = snapshot.data();
    return new Group(firestoreData);
  },
};
