import { getGroupsGraphData, getGroupsGraphOptions } from '@modules/analytics';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
);

interface Props {
  groupsGraphOption: string;
}

export const GroupsGraph: React.FC<Props> = ({ groupsGraphOption }) => {
  return (
    <Bar
      options={getGroupsGraphOptions()}
      data={getGroupsGraphData(groupsGraphOption)}
      height={600}
      width={2000}
    />
  );
};
