/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  fromTny: 'screen and (min-width: 400px)',
  toTny: 'screen and (max-width: 399px)',
  fromSml: 'screen and (min-width: 480px)',
  toSml: 'screen and (max-width: 479px)',
  fromMed: 'screen and (min-width: 768px)',
  toMed: 'screen and (max-width: 767px)',
  fromLrg: 'screen and (min-width: 992px)',
  toLrg: 'screen and (max-width: 991px)',
  fromXLrg: 'screen and (min-width: 1200px)',
  toXlrg: 'screen and (max-width: 1199px)',
  fromHuge: 'screen and (min-width: 1400px)',
  toHuge: 'screen and (max-width: 1399px)',
  fromXHuge: 'screen and (min-width: 2000px)',
  toXHuge: 'screen and (max-width: 1999px)',

  from1024: 'screen and (min-width: 1024px)',
  to1024: 'screen and (max-width: 1023px)',
  from1100: 'screen and (min-width: 1100px)',
  to1100: 'screen and (max-width: 1099px)',
  from483: 'screen and (min-width: 483px)',
  to483: 'screen and (max-width: 482px)',
};

/**
 * Wrappers
 */

/**
 * Spacing - margins, paddings, grid gaps
 */
export const spacing = {
  tny: 'calc(var(--unit) * 2)',
  sml: 'calc(var(--unit) * 4)',
  med: 'calc(var(--unit) * 6)',
  lrg: 'calc(var(--unit) * 8)',
  xlrg: 'calc(var(--unit) * 10)',
  xxlrg: 'calc(var(--unit) * 12)',
  xxxlrg: 'calc(var(--unit) * 15)',
  huge: 'calc(var(--unit) * 16)',
  xHuge: 'calc(var(--unit) * 17)',
};

export const pageGrid = [
  {
    template: { column: 'auto', row: 'auto' },
    gap: { column: 32 },
  },
  {
    mediaMinWidth: 'toSml',
    template: { column: 'repeat(6, 1fr)', row: 'auto' },
    gap: { column: 16 },
  },
  {
    mediaMinWidth: 'fromSml',
    template: { column: 'repeat(12, 1fr)', row: 'auto' },
    gap: { column: 32 },
  },
];
/**
 * Additional grids - component
 */
export const componentGrids = {
  cardGrid: [
    {
      cols: 2,
      gap: { column: 12, row: 12 },
    },
    {
      mediaMinWidth: 'medium',
      cols: 3,
      gap: { row: 16, column: 16 },
    },
    {
      mediaMinWidth: 'large',
      cols: 4,
      gap: { row: 12, column: 12 },
    },
  ],

  cardLayout: [
    {
      template: { row: '300px auto' },
      gap: { row: 24 },
    },
    {
      mediaMinWidth: 'medium',
      template: { column: '300px auto' },
      gap: { column: 32 },
    },
  ],
};

export const customPalette = {};
