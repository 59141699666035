export const routesList = [
  {
    name: 'Home',
    route: '/',
  },
  { name: 'Search', route: '/search' },
  { name: 'Favorites', route: '/favorites' },
  { name: 'Profile', route: '/profile' },
  { name: 'Bookings', route: '/bookings' },
];
