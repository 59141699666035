import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';

const adminMain = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-auto-rows: auto;
  width: 100%;
`;

const bookingHistoryMain = css`
  grid-column: 1 / 7;
`;

export const styles = {
  adminMain,
  bookingHistoryMain,
};
