import { useFacility } from '@modules/facility';
import { useMemo } from 'react';
import {
  useActiveCourt,
  useCurrentBookedHours,
  useCurrentToDeleteBookedHours,
  useIsDestructiveMode,
  useSetCurrentBookedHours,
  useSetCurrentToDeleteBookedHours,
} from '../state';

// Define the shape of the data that will be used to set the current bookings
interface Data {
  from: Date;
  to: Date;
  price: number | null;
  id?: string;
}

export function useSetCurrentBookings({ from, to, price, id }: Data) {
  const isDestructive = useIsDestructiveMode();
  const bookedHours = useCurrentBookedHours();
  const toDeleteBookedHours = useCurrentToDeleteBookedHours();
  const activeCourt = useActiveCourt();
  const facility = useFacility();

  // Determine which set of hours to use based on whether the mode is destructive
  const hours = useMemo(
    () => (isDestructive ? toDeleteBookedHours : bookedHours),
    [bookedHours, toDeleteBookedHours, isDestructive],
  );

  // Get the functions to set the current booked hours and the hours to delete
  const setCurrentBookedHours = useSetCurrentBookedHours();
  const setToDeleteBookedHours = useSetCurrentToDeleteBookedHours();

  // Determine which setter function to use based on whether the mode is destructive
  const setter = useMemo(
    () => (isDestructive ? setToDeleteBookedHours : setCurrentBookedHours),
    [isDestructive],
  );

  // Define a function to handle the current booked hours
  function handleCurrentBookedHours(isActive: boolean) {
    if (!activeCourt || !facility) return;

    // If the court is not active, add a new booking
    if (!isActive) {
      setter((prev) => [
        ...prev,
        {
          from: from.getTime(),
          to: to.getTime(),
          price,
          courtName: activeCourt.name,
          courtId: activeCourt.id,
          facilityName: facility.name,
          id,
        },
      ]);

      return;
    }

    // If the court is active, remove the booking
    setter(
      hours.filter(
        (booked) =>
          booked.from !== from.getTime() ||
          (booked.from === from.getTime() &&
            booked.courtId !== activeCourt?.id),
      ),
    );
  }

  return { handleCurrentBookedHours };
}
