import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const nav = css`
  position: sticky;
  top: 20px;
  height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
`;

const link = css`
  display: flex;
  position: relative;
  transition: all 0.15s ease-out;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  width: 100%;
  color: var(--color-text-5);
  transition: color 0.15s ease-out;

  path {
    transition: all 0.15s ease-out;
  }

  &:hover,
  &:focus {
    color: var(--color-general-primary);

    & path {
      fill: var(--color-general-primary);
    }
  }

  &:hover {
    filter: gray saturate(0%) brightness(70%) contrast(1000%);
  }
`;

const icons = css`
  margin-right: 12px;
  vertical-align: middle;
  min-width: max-content;
`;

const disabled = css`
  pointer-events: none;
  opacity: 0.5;
`;

const sectionWrapper = css`
  margin-top: 48px;
`;

const sectionTitle = css`
  margin-bottom: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-text-3);
`;

const logo = css`
  display: block;
  cursor: pointer;
  margin-bottom: 40px;
  width: 80px;
`;

const list = css`
  display: flex;
  flex-direction: column;
  position: relative;
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
  gap: 4px;

  a {
    cursor: pointer;
    padding: 8px 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: start;
  }
`;

const activeLink = css`
  @media ${breakpoints.fromMed} {
    color: var(--color-general-primary);
    background-color: #211f26;

    & path {
      margin-right: 12px;
      fill: var(--color-general-primary);
    }
  }
`;

const logout = css`
  display: none;

  @media ${breakpoints.fromMed} {
    display: inline-block;
    margin-top: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #737373;
    cursor: pointer;

    &:hover {
      color: white;
    }
  }
`;

const logoSecondary = css`
  display: block;
  cursor: pointer;
  width: 46.5px;

  path {
    fill: var(--color-text-3);
    transition: all 0.15s ease-out;
  }
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--color-text-4);
  padding: 4px 0;

  span {
    transition: all 0.15s ease-out;
  }

  :hover {
    span {
      color: var(--color-text-5);
    }

    path {
      fill: var(--color-text-5);
    }
  }
`;

const footer = css`
  font-size: 12px;
  line-height: 16px;
  margin-top: auto;
  color: var(--color-text-3);
  display: flex;
  flex-direction: column;

  a {
    transition: color 0.15s ease-out;
    padding: 4px 0;

    :hover {
      color: var(--color-text-5);
    }
  }
`;

export const styles = {
  nav,
  link,
  icons,
  sectionWrapper,
  sectionTitle,
  disabled,
  logo,
  list,
  activeLink,
  logout,
  logoSecondary,
  footer,
  logoWrapper,
};
