import { LOCAL_HOST_DOMAIN } from 'shared/const';
import { isLocalHost } from 'shared/utils';

/**
 * This hook is used to get current domain */

export function useDomain() {
  const currentDomain = isLocalHost()
    ? LOCAL_HOST_DOMAIN
    : 'https://theplayoff.app';

  return currentDomain;
}
