import { useDefaultClaim } from '@modules/auth';
import { useCurrentFacilityId, useFacility } from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import {
  useSetAnalyticsError,
  useSetAnalyticsLoading,
  useSetGroupAnalytics,
} from '../state';
import { groupAnalyticsFirestoreConverter } from '../utils';

/**This hook is used to listen to group analytics in analytics/{facilityId} */
export function useGroupAnalyticsListener() {
  const facility = useFacility();
  const defaultClaim = useDefaultClaim();
  const currentFacilityId = useCurrentFacilityId();

  const setError = useSetAnalyticsError();
  const setLoading = useSetAnalyticsLoading();
  const setGroupAnalytics = useSetGroupAnalytics();

  const isEligible =
    defaultClaim?.role === 'admin' || defaultClaim?.role === 'playoff-admin';

  useEffect(() => {
    if (!currentFacilityId || facility?.type !== 'group' || !isEligible) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const document = doc(
      FirebaseService.firestore,
      'analytics',
      currentFacilityId,
    ).withConverter(groupAnalyticsFirestoreConverter);

    const unsubscribe = onSnapshot(
      document,
      (snapshot) => {
        setGroupAnalytics(snapshot.data() || null);
        setLoading(false);
      },
      (error) => setError(error.message),
    );

    return () => unsubscribe();
  }, [currentFacilityId, facility]);
}
