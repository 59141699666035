import {
  useCurrentMonthGrowth,
  useCurrMonthNumberOfMembers,
  useLastMonthGrowth,
  useLastMonthNumberOfMembers,
} from '@modules/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { monthlyStatisticsStyles as styles } from './monthlyStatisticsStyles';

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const GroupMonthlyStatistics: React.FC = () => {
  const [month, setMonth] = useState<'current' | 'past'>('current');

  const lastMonthGrowth = useLastMonthGrowth();
  const currentMonthGrowth = useCurrentMonthGrowth();
  const totalNumberOfMembers = useCurrMonthNumberOfMembers();
  const lastMonthTotalNumberOfMembers = useLastMonthNumberOfMembers();

  return (
    <div css={styles.selectWrapper}>
      <p css={styles.monthlyLabel}>
        <FormattedMessage id="admin.analytics.monthly.statistics" />
      </p>
      <div css={styles.monthSwitcher}>
        <span
          css={[month === 'current' ? styles.currentMonth : styles.pastMonth]}
          onClick={() => setMonth('current')}
        >
          <FormattedMessage id="admin.analytics.current.month" />
        </span>
        <span
          css={[month === 'past' ? styles.currentMonth : styles.pastMonth]}
          onClick={() => setMonth('past')}
        >
          <FormattedMessage id="admin.analytics.past.month" />
        </span>
      </div>
      <AnimatePresence mode="wait">
        {month === 'current' ? (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="current"
            css={styles.statisticsContainer}
          >
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>{totalNumberOfMembers}</span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="group.monthly.statistics.number.of.members.label" />
              </span>
            </div>
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {currentMonthGrowth > 0
                  ? `+${currentMonthGrowth}`
                  : currentMonthGrowth}{' '}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="group.monthly.statistics.growth.label" />
              </span>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="past"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={styles.statisticsContainer}
          >
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {lastMonthTotalNumberOfMembers}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="group.monthly.statistics.number.of.members.label" />
              </span>
            </div>
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {' '}
                {lastMonthGrowth > 0
                  ? `+${lastMonthGrowth}`
                  : lastMonthGrowth}{' '}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="group.monthly.statistics.growth.label" />
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
