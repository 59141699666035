import { SerializedStyles } from '@emotion/react';
import { useDefaultClaim } from '@modules/auth';
import {
  useBookingHelpers,
  useCurrentToDeleteBookedHours,
  useDeleteBooking,
  useSetIsModalOpen,
} from '@modules/booking-new';
import { useCurrentLocale } from '@modules/translations';
import {
  useClickOutsideElement,
  useCloseOnEscape,
  useNoScroll,
} from '@shared/hooks';
import { Button } from '@ui/Button';
import { animations } from 'const';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './cancelModal.styles';

const Repeat = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/repeat.svg'),
  {
    loading: () => null,
  },
);

export const CancelModal: FC = () => {
  useNoScroll(true);
  const ref = useCloseOnEscape<HTMLButtonElement>();
  const setIsModalOpen = useSetIsModalOpen();
  const outsideClickRef = useClickOutsideElement<HTMLDivElement>(
    () => setIsModalOpen(false),
    true,
  );
  const toDeleteBookings = useCurrentToDeleteBookedHours();
  const defaultClaim = useDefaultClaim();
  const { formatHour, convertToDate } = useBookingHelpers();
  const { deleteBooking } = useDeleteBooking();
  const { getLocale } = useCurrentLocale();

  async function handleDeleteBooking() {
    await deleteBooking();
    setIsModalOpen(false);
  }

  useEffect(() => {
    return () => {
      setIsModalOpen(false);
    };
  }, []);

  return (
    <motion.div
      {...animations.opacity({})}
      css={styles.base}
      ref={outsideClickRef}
    >
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        css={styles.modal}
      >
        <h2 css={styles.title}>
          <FormattedMessage id="cancel-modal.title" />
        </h2>
        <ul css={styles.list}>
          {toDeleteBookings.map((booking, index) => (
            <li key={index} css={styles.item}>
              {booking.name && (
                <span css={styles.name}>
                  {booking.name}{' '}
                  {booking?.isRepeat && <Repeat viewBox="0 0 16 16" />}
                </span>
              )}
              {booking.courtName && (
                <span css={styles.court}>{booking.courtName}</span>
              )}
              <p css={styles.date}>
                {format(booking.from, 'E, MMM d', { locale: getLocale() })}
                <span>
                  {formatHour(convertToDate(booking.from))} -{' '}
                  {formatHour(convertToDate(booking.to))}
                </span>
              </p>
            </li>
          ))}
        </ul>
        <p css={styles.linkText}>
          <FormattedMessage id="cancel-modal.link.description" />
          <Link href={`/dashboard/${defaultClaim?.role}/booking-overview`}>
            <FormattedMessage id="cancel-modal.link.text" />
          </Link>
        </p>
        <div css={styles.buttonWrapper}>
          <Button
            style="primary"
            customStyles={styles.buttonConfirm}
            onClick={handleDeleteBooking}
          >
            <FormattedMessage id="cancel-modal.button.cancel" />
          </Button>
          <Button
            style="primary"
            customStyles={styles.buttonBack}
            onClick={() => setIsModalOpen(false)}
            ref={ref}
          >
            <FormattedMessage id="cancel-modal.button.close" />
          </Button>
        </div>
      </motion.div>
    </motion.div>
  );
};
