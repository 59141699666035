import { AdminDashboard } from '@modules/admin';
import {
  DAYS,
  GroupMonthlyStatistics,
  GroupRevenueGraph,
  GroupRevenueGraphSelect,
  GroupsGraph,
  GroupsGraphSelect,
  RevenueGraph,
  RevenueGraphSelect,
  useBookingAnalyticsListener,
  useGroupAnalyticsSelector,
} from '@modules/analytics';
import { useFacility } from '@modules/facility';
import { useGroups } from '@modules/groups/state';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HotspotsGraph } from '../hot-spots-graph';
import { HotspotsGraphSelect } from '../hotspots-graph-select';
import { MonthlyStatistics } from '../monthly-statistics';
import { ReservationsGraph } from '../reservations-graph';
import { ReservationsGraphSelect } from '../reservations-graph-select';
import { styles } from './styles';

export const AdminAnalytics: React.FC = () => {
  /**Bookings listener */
  useBookingAnalyticsListener();
  const groups = useGroups();
  const facility = useFacility();
  const { formatMessage } = useIntl();
  const groupAnalytics = useGroupAnalyticsSelector();

  const [reservationsView, setReservationsView] = useState<
    'monthly' | 'weekly'
  >('monthly');
  const [revenueGraphOption, setRevenueGraphOption] = useState(
    formatMessage({ id: `revenue.graph-results.currentYear` }),
  );
  const [hotspotsGraphOption, setHotspotsGraphOption] = useState(
    formatMessage({ id: `day.${DAYS[0]}` }),
  );
  const [groupsGraphOption, setGroupsGraphOption] = useState(
    groups[0]?.name || '',
  );
  const [groupRevenueGraphOption, setGroupRevenueGraphOption] = useState(
    formatMessage({ id: `revenue.graph-results.currentYear` }),
  );

  useEffect(() => {
    setGroupsGraphOption(groups[0]?.name || '');
  }, [groups]);

  return (
    <AdminDashboard
      title={formatMessage({ id: 'admin.analytics.title' })}
      description={formatMessage({ id: 'admin.analytics.description' })}
      containerStyles={styles.adminMain}
      mainStyles={styles.adminAnalyticsMain}
    >
      {facility?.type === 'sport' && <MonthlyStatistics />}
      {facility?.type === 'group' && <GroupMonthlyStatistics />}
      {facility?.type === 'sport' && (
        <>
          <div css={styles.graphsContainer}>
            <div css={styles.singleGraphContainer}>
              <ReservationsGraphSelect
                reservationsView={reservationsView}
                onSetReservationsView={setReservationsView}
              />
              <div css={{ height: '100%' }}>
                <ReservationsGraph view={reservationsView} />
              </div>
            </div>
            <div css={styles.singleGraphContainer}>
              <div css={styles.reservationsGraphHeader}>
                <span css={styles.reservationNumber}>
                  <FormattedMessage id="revenue.graph.label" />
                </span>
                <div
                  css={[styles.monthSwitcher, styles.reservationGraphSwitcher]}
                >
                  <RevenueGraphSelect
                    selectedOption={revenueGraphOption}
                    onSetSelectedOption={setRevenueGraphOption}
                  />
                </div>
              </div>
              <div css={{ height: '100%' }}>
                <RevenueGraph revenueGraphOption={revenueGraphOption} />
              </div>
            </div>
          </div>
          <div css={styles.graphsContainer}>
            <div css={styles.barGraphWrapper}>
              <div css={styles.hotspotsGraphHeader}>
                <span css={styles.reservationNumber}>
                  <FormattedMessage id="hotspots.graph.label" />
                </span>
                <div
                  css={[styles.monthSwitcher, styles.reservationGraphSwitcher]}
                >
                  <HotspotsGraphSelect
                    selectedOption={hotspotsGraphOption}
                    onSetSelectedOption={setHotspotsGraphOption}
                  />
                </div>
              </div>
              <HotspotsGraph hotspotsGraphOption={hotspotsGraphOption} />
            </div>
          </div>
        </>
      )}

      {facility?.type === 'group' && groupAnalytics && (
        <>
          <div css={styles.graphsContainer}>
            <div css={styles.barGraphWrapper}>
              <div css={styles.hotspotsGraphHeader}>
                <span css={styles.reservationNumber}>
                  <FormattedMessage id="groups.graph.label" />
                </span>
                <div
                  css={[styles.monthSwitcher, styles.reservationGraphSwitcher]}
                >
                  <GroupsGraphSelect
                    selectedOption={groupsGraphOption}
                    onSetSelectedOption={setGroupsGraphOption}
                  />
                </div>
              </div>
              <GroupsGraph groupsGraphOption={groupsGraphOption} />
            </div>
          </div>
          <div css={styles.graphsContainer}>
            <div css={styles.barGraphWrapper}>
              <div css={styles.hotspotsGraphHeader}>
                <span css={styles.reservationNumber}>
                  <FormattedMessage id="group.revenue.graph.label" />
                </span>
                <div
                  css={[styles.monthSwitcher, styles.reservationGraphSwitcher]}
                >
                  <GroupRevenueGraphSelect
                    selectedOption={groupRevenueGraphOption}
                    onSetSelectedOption={setGroupRevenueGraphOption}
                  />
                </div>
              </div>
              <GroupRevenueGraph revenueGraphOption={groupRevenueGraphOption} />{' '}
            </div>
          </div>
        </>
      )}
    </AdminDashboard>
  );
};
