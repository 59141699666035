import { useCloseOnEscape } from '@shared/hooks';
import CheckIcon from 'public/icons/check.svg';
import { revenueGraphOptionStyles as styles } from './revenueGraphOptionStyles';

interface Props {
  option: string;
  isActive: boolean;
  onSetSelectedOption: (option: string) => void;
  onClose?: () => void;
}

export const RevenueGraphOption: React.FC<Props> = ({
  isActive,
  option,
  onSetSelectedOption,
  onClose,
}) => {
  const ref = useCloseOnEscape<HTMLDivElement>();

  return (
    <div css={styles.searchResult} ref={ref} onClick={onClose}>
      <span
        css={[styles.result, isActive && styles.resultActive]}
        onClick={() => onSetSelectedOption(option)}
      >
        {option}
      </span>
      {isActive && (
        <div css={styles.iconStyles}>
          <CheckIcon />
        </div>
      )}
    </div>
  );
};
