import { useBookingHelpers } from '@modules/booking-new';
import { useOrderDays } from '@modules/groups';
import { englishToCroatianDays } from '@modules/groups/utils';
import { useModal } from '@shared/hooks';
import { FC, useMemo } from 'react';
import { SingleGroupModal } from '../single-group-modal/SingleGroupModal';
import { styles } from './groupCard.styles';

interface Props {
  group: Group;
}

export const GroupCard: FC<Props> = ({ group }) => {
  const { orderDays } = useOrderDays();
  const sortedDays = useMemo(() => {
    return Object.keys(group.trainingTimes)
      .sort((a, b) => orderDays.indexOf(a) - orderDays.indexOf(b))
      .filter((day) => group.trainingTimes[day].length > 0);
  }, [group.trainingTimes]);

  const activeMembers = useMemo(() => {
    return group.members.filter((member) => member.isActive);
  }, [group.members]);
  const { convertToDate, formatHour } = useBookingHelpers();
  const { closeModal, isModalOpen, openModal } = useModal();

  return (
    <>
      <div css={styles.base} onClick={openModal}>
        <div css={styles.flex}>
          <p css={styles.name}>{group.name}</p>
          <div
            css={styles.capacity}
          >{`${activeMembers.length} / ${group.capacity}`}</div>
        </div>
        <div>
          {sortedDays.map((day, i) => (
            <div key={i} css={styles.dateWrapper}>
              <span css={styles.day}>{englishToCroatianDays[day]}</span>
              <div key={i}>
                {group.trainingTimes[day].map(({ to, from, courtName }, j) => (
                  <div key={j} css={styles.infoWrapper}>
                    <span css={styles.hour}>
                      {formatHour(convertToDate(from))} -{' '}
                      {formatHour(convertToDate(to))}
                    </span>
                    <span>{courtName}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <SingleGroupModal group={group} handleCloseModal={closeModal} />
      )}
    </>
  );
};
