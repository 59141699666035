import React from 'react';
import { useMobile } from 'shared/hooks';
import { Toaster } from 'sonner';
import { styles } from './styles';

export const ToastWrapper: React.FC = () => {
  const { isMobileWidth } = useMobile();

  return (
    <Toaster
      richColors
      position={isMobileWidth ? 'top-center' : 'bottom-left'}
      expand={true}
      css={styles.toastWrapper}
      visibleToasts={5}
      dir="ltr"
      duration={3000}
      theme="system"
      closeButton={true}
    />
  );
};
