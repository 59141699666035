import { ChartOptions } from 'chart.js';
import { useAnalyticsSelector } from '../state';
import { getDay, getHotspotsData } from '../utils';

export function useBarGraphOptions(
  option: string,
  onGetGraphOptions: (
    maxValue: number,
    stepSize: number,
  ) => ChartOptions<'bar'>,
) {
  const { hotspots } = useAnalyticsSelector();
  const day = getDay(option);
  const data = getHotspotsData(day, hotspots);
  if (!data?.length) {
    return onGetGraphOptions(0, 0);
  }
  const maxDataValue = Math.max(...data);
  const steps = 6;
  const roundedMax = Math.ceil(maxDataValue / steps) * steps;
  const stepSize = Math.ceil(roundedMax / steps);

  return onGetGraphOptions(roundedMax, stepSize);
}
