import FacilityIcon from 'public/icons/facility-16.svg';
import { styles } from './styles';
import { FormattedMessage } from 'react-intl';

export const CustomPlaceholder = () => (
  <div css={styles.wrapper}>
    <FacilityIcon />
    <span css={styles.placeholder}>
      <FormattedMessage id="facility-select.select-placeholder" />
    </span>
  </div>
);
