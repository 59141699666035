import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { Facility } from '../models';

export const facilityFirestoreConverter: FirestoreDataConverter<Facility> = {
  toFirestore: (facility: Facility): object => {
    const toFirestoreData: Omit<Facility, 'id'> = { ...facility };
    return toFirestoreData;
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot<Facility>): Facility => {
    const firestoreData = snapshot.data();
    return new Facility({ ...firestoreData, id: snapshot.id });
  },
};
