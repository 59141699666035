import { selector, useRecoilValue } from 'recoil';
import {
  newGroupMemberEmail,
  newGroupMemberName,
  newGroupMemberSurname,
} from './atoms';

export const newGroupMember = selector<GroupMember>({
  key: 'group.newGroupMember',
  get: ({ get }) => {
    return {
      id: get(newGroupMemberEmail),
      name: `${get(newGroupMemberName)} ${get(newGroupMemberSurname)}`,
      email: get(newGroupMemberEmail),
      datesAttended: [],
      dateJoined: Date.now(),
      membershipPaymentInfo: [],
      userId: `radnomIdForNow${Math.random()}`,
      isActive: true,
      membershipExpiryDate: null,
    };
  },
});

export const useNewMemberData = () => useRecoilValue(newGroupMember);
