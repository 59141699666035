import {
  bookingFirestoreConverter,
  useCurrentDate,
} from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { FirebaseService, getTypedCollectionDocument } from '@modules/firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

/**This hook is used to listen to all bookings where facilityId is equal to current facilityId */
export function useCanceledBookingsListener(booking: Booking) {
  const facility = useFacility();
  const date = useCurrentDate();
  const [cancelledBookings, setCancelledBookings] = useState<Booking[]>([]);

  useEffect(() => {
    const isGroup = facility?.type === 'group';
    if (!facility?.id || isGroup || !booking.paymentId) return;

    const q = query(
      collection(FirebaseService.firestore, 'bookings'),
      where('paymentId', '==', booking.paymentId),
    ).withConverter(bookingFirestoreConverter);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bookings = querySnapshot.docs.flatMap((doc) =>
        getTypedCollectionDocument<Booking[]>(doc),
      );
      const sortedBookingData = bookings?.sort(
        (a, b) => a.startTime - b.startTime,
      );
      if (sortedBookingData?.length >= 2) {
        setCancelledBookings(sortedBookingData);
      } else {
        setCancelledBookings([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [facility, date]);

  return { cancelledBookings };
}
