import { css } from '@emotion/react';

const base = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const textWrapper = css`
  display: flex;
  clip-path: inset(0 0 -10px 0);
`;

const block = css`
  display: block;
`;

export const animatedTextStyles = { base, textWrapper, block };
