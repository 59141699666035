import { SerializedStyles } from '@emotion/react';
import {
  convertPrice,
  useBookingSlotStates,
  useSetCurrentBookings,
} from '@modules/booking-new';
import { display } from '@styles/utils.display.styles';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { styles } from './bookingSlot.styles';

const Checked = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/checked-icon.svg'),
  {
    loading: () => null,
  },
);

const Repeat = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/repeat.svg'),
  {
    loading: () => null,
  },
);

const InfoIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/i.svg'),
  {
    loading: () => null,
  },
);

const PlusIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/plus-bold-12.svg'),
  {
    loading: () => null,
  },
);

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

interface Props {
  from: Date;
  to: Date;
  isAvailable?: boolean;
  index: number;
  price?: number;
  isLast?: boolean;
}

export const BookingSlot: FC<Props> = ({
  from,
  to,
  isAvailable,
  index,
  price,
  isLast,
}) => {
  const { handleCurrentBookedHours } = useSetCurrentBookings({
    from,
    to,
    price,
  });
  const { formatMessage } = useIntl();
  const {
    shortenedName,
    shortenedOpponentName,
    email,
    isExpired,
    isReserved,
    isPaintball,
    isActive,
    isInProgress,
    isTournamentGame,
    facilityName,
    isRepeat,
    hasFollower,
    hasAntecedent,
    minSlotsInBooking,
    openBookingInfo,
  } = useBookingSlotStates(from, to);
  const bottomStyle = useMemo(() => {
    if (hasFollower) return styles.bottomLess;
  }, [hasFollower]);
  const topStyle = useMemo(() => {
    if (hasAntecedent) return styles.topLess;
  }, [hasAntecedent]);

  if (!isAvailable) {
    return (
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.6, delay: 0.03 * index }}
        css={styles.baseDisabled(
          format(from, 'HHmm'),
          !isLast && format(to, 'HHmm'),
        )}
      />
    );
  }

  return (
    <AnimatePresence>
      <motion.button
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.6, delay: 0.03 * index }}
        onClick={() =>
          isReserved ? openBookingInfo() : handleCurrentBookedHours(isActive)
        }
        css={[
          isActive
            ? styles.baseActive(
                format(from, 'HHmm'),
                !isLast && format(to, 'HHmm'),
              )
            : styles.base(format(from, 'HHmm'), !isLast && format(to, 'HHmm')),
          isReserved &&
            styles.baseReserved(
              format(from, 'HHmm'),
              !isLast && format(to, 'HHmm'),
            ),
          topStyle,
          bottomStyle,
        ]}
        disabled={isReserved ? false : isExpired}
      >
        <div css={styles.time}>
          <span>
            {isInProgress && <div css={styles.dot} />}
            {format(from, 'HH:mm')}
          </span>
          {isTournamentGame && (
            <span css={styles.pyramid}>
              {facilityName === 'SQUASHer'
                ? 'SQUASHer'
                : formatMessage({
                    id: 'booking-slot.pyramid',
                  })}
            </span>
          )}
          {shortenedName && (
            <div css={styles.infoButton} className="icon">
              <InfoIcon viewBox="0 0 13 12" />
            </div>
          )}
          {!shortenedName && !isExpired && !isActive && (
            <div css={styles.plusIcon} className="icon">
              <PlusIcon viewBox="0 0 13 12" />
            </div>
          )}
          {isActive && (
            <div css={isReserved || isExpired ? display.none : styles.icon}>
              <Checked viewBox="0 0 26 26" />
            </div>
          )}
        </div>
        <span css={isRepeat ? styles.nameWithSpace : styles.name}>
          <>
            {shortenedName ||
              email ||
              (isExpired &&
                formatMessage({
                  id: 'booking-slot.expired',
                })) ||
              (!isPaintball && `€${convertPrice(price / minSlotsInBooking)}`)}
          </>
          {shortenedOpponentName && ` - ${shortenedOpponentName}`}
        </span>
        {isRepeat && <Repeat css={styles.repeat} viewBox="0 0 16 16" />}
      </motion.button>
    </AnimatePresence>
  );
};
