import { css } from '@emotion/react';

const inputStyles = css`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 12px 44px;
  background: #2a282f;
  border-radius: 10px;
  color: white;
  &:focus-within {
    border: 1px solid #61645e;
  }
  &:hover {
    border: 1px solid #61645e;
  }
`;

const searchResult = css`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #a4a4a4;
  width: 100%;
`;

const iconStyles = css`
  display: flex;
  margin-left: auto;
`;

const inputIconStyles = css`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
`;

/**
 *     position: absolute;
    
    z-index: 1;
 */
const result = css`
  line-height: 20px;
  color: #c7c7d1;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

const resultActive = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #acef34;
`;

export const hotspotsGraphOptionStyles = {
  inputStyles,
  searchResult,
  iconStyles,
  inputIconStyles,
  result,
  resultActive,
};
