import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  padding: 0;
  overflow: hidden;
`;

const mobileContainer = css`
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px;
  color: var(--color-text-5);
  min-height: calc(100vh - 174px);
  justify-content: center;
`;

const form = css`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${breakpoints.fromMed} {
    grid-column: 10 / 25;
    margin-bottom: 80px;
  }
`;

const title = css`
  margin-bottom: 24px;
  color: var(--text-text-7, #f8fcff);

  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 100%; /* 40px */
  letter-spacing: 0.8px;
  text-transform: uppercase;
`;

const description = css`
  color: var(--text-text-5, #d6e6f3);
  font-family: HK Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 24px;
  line-height: 24px; /* 150% */
`;

const storeContainer = css`
  display: flex;
  gap: 16px;
`;

export const styles = {
  title,
  description,
  form,
  base,
  mobileContainer,
  storeContainer,
};
