import { useDomain } from '@shared/hooks';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';

interface Props {
  title: string;
  description: string;
  image?: string;
}

const getOGLocale = (locale: string) => {
  const localeMap = {
    hr: 'hr_HR',
    en: 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    de: 'de_DE',
    pt: 'pt_PT',
    it: 'it_IT',
    bo: 'bs_BA',
    sr: 'sr_RS',
  };
  return localeMap[locale] || localeMap['hr'];
};

export const SEO: React.FC<Props> = ({ title, description, image }) => {
  const router = useRouter();
  let url = useDomain();
  const { formatMessage } = useIntl();

  if (typeof window !== 'undefined') {
    url = `${window.location.origin}`;
  } else {
    url = 'https://theplayoff.app';
  }
  const canocialUrl = `${url}${router.pathname}`;

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
      />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <link rel="canonical" href={canocialUrl} />
      <title>{title}</title>
      <meta name="theme-color" content="#222222" />
      <meta name="keywords" content={formatMessage({ id: 'seo.keywords' })} />
      <meta name="author" content="PLAYOFF" />
      <meta name="description" content={description} />
      <meta property="og:site_name" content="PLAYOFF" />
      <meta charSet="utf-8" />
      <meta property="og:locale" content={getOGLocale(router.locale)} />
      <meta property="og:url" content={canocialUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={image || 'https://theplayoff.app/images/seo-1200x627.jpg'}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="627" />
      <meta property="og:image:alet" content="PLAYOFF" />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:alt" content="PLAYOFF - More than a game" />

      {/* Twitter meta tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content={canocialUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={image || 'https://theplayoff.app/images/seo-1200x627.jpg'}
      />
      <meta name="twitter:image:alt" content="PLAYOFF" />
      <meta name="twitter:site" content="https://theplayoff.app/" />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:site" content="@theplayoffapp" />
      <meta name="twitter:creator" content="@theplayoffapp" />
    </Head>
  );
};
