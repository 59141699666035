import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { grid } from '@styles/grid.styes';

const containerStyles = css`
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;

  ${grid.basic12col};
  padding: 0;
`;

const titleStyles = css`
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
`;
const btnContainer = css`
  display: flex;
  justify-content: flex-end;
`;

const tableStyles = css`
  border-collapse: collapse;
  grid-column: 1 / 13;
  width: 100%;

  th {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 3px;
    padding-bottom: 12px;
    text-align: left;
    color: var(--color-text-gray);
    text-transform: uppercase;
  }

  td {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    border-bottom: 1px solid #f8f8f814;
    color: var(--color-text-5);
    padding: 12px 0;

    &:last-child {
      text-align: right;
    }

    button {
      padding: 4px 16px;
      border-radius: 32px;
      background-color: transparent;
      border: 1px solid gray;
      color: var(--color-text-5);
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

const revokeRoleBtn = css`
  ${button.secondary};
  padding: 8px 20px;
  margin-right: 16px;
`;

export const styles = {
  containerStyles,
  titleStyles,
  tableStyles,
  btnContainer,
  revokeRoleBtn,
};
