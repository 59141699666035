import { SerializedStyles } from '@emotion/react';
import { useBookingInfoModal } from '@modules/booking-new';
import {
  useClickOutsideElement,
  useCloseOnEscape,
  useSubmitOnEnter,
} from '@shared/hooks';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import { animations } from 'const';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './bookingInfoFormModal.styles';
import { BookingGameModeSelect, BookingPaintballSelect } from './fragments';

const User = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/user-16.svg'),
  {
    loading: () => null,
  },
);

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const Phone = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/phone-16.svg'),
  {
    loading: () => null,
  },
);

const Feedback = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/message-16.svg'),
  {
    loading: () => null,
  },
);

export const BookingInfoFormModal: FC = () => {
  const { formatMessage } = useIntl();
  const {
    values,
    errors,
    touched,
    isPaintball,
    hasTeamGame,
    isPyramidChecked,
    isTeamChecked,
    teamGameOption,
    selectedOption,
    currentFacility,
    handleBlur,
    handleChange,
    handleSubmit,
    setIsModalOpen,
    setFieldValue,
    setIsPyramidChecked,
    setIsTeamChecked,
    setSelectedOption,
    setTeamGameOption,
  } = useBookingInfoModal();
  const ref = useCloseOnEscape<HTMLButtonElement>();
  const submitRef = useSubmitOnEnter<HTMLButtonElement>();
  const outsideClickRef = useClickOutsideElement<HTMLDivElement>(
    () => setIsModalOpen(false),
    true,
  );

  return (
    <motion.div {...animations.opacity({})} css={styles.base}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        css={styles.modal}
        ref={outsideClickRef}
      >
        <h2 css={styles.title}>
          <FormattedMessage id="booking-info-form-modal.title" />
        </h2>
        <form autoComplete="off" onSubmit={handleSubmit} css={styles.form}>
          {' '}
          <div css={styles.form}>
            <FormInput
              type="text"
              label={formatMessage({
                id: 'booking-info-form-modal.name.label',
              })}
              id="name"
              name="name"
              Icon={User}
              value={values.name}
              error={errors.name}
              touched={touched}
              isRequired
              placeholder={formatMessage({
                id: 'booking-info-form-modal.name.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormInput
              type="email"
              label={formatMessage({
                id: 'booking-info-form-modal.email.label',
              })}
              id="email"
              name="email"
              Icon={Mail}
              value={values.email}
              error={errors.email}
              touched={touched}
              placeholder={formatMessage({
                id: 'booking-info-form-modal.email.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormInput
              type="text"
              label={formatMessage({
                id: 'booking-info-form-modal.phone.label',
              })}
              id="phoneNumber"
              name="phoneNumber"
              Icon={Phone}
              value={values.phoneNumber}
              error={errors.phoneNumber}
              touched={touched}
              placeholder={formatMessage({
                id: 'booking-info-form-modal.phone.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormInput
              type="text"
              label={formatMessage({
                id: 'booking-info-form-modal.comment.label',
              })}
              id="comment"
              name="comment"
              Icon={Feedback}
              value={values.comment}
              error={errors.comment}
              touched={touched}
              placeholder={formatMessage({
                id: 'booking-info-form-modal.comment.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/** Team game booking */}
            {hasTeamGame && (
              <BookingGameModeSelect
                values={values}
                errors={errors}
                touched={touched}
                isPyramidChecked={isPyramidChecked}
                isTeamChecked={isTeamChecked}
                teamGameOption={teamGameOption}
                selectedOption={selectedOption}
                currentFacility={currentFacility}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setIsPyramidChecked={setIsPyramidChecked}
                setIsTeamChecked={setIsTeamChecked}
                setSelectedOption={setSelectedOption}
                setTeamGameOption={setTeamGameOption}
              />
            )}
            {/** Paintball booking */}
            {isPaintball && (
              <BookingPaintballSelect
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </div>
          <div css={styles.buttonWrapper}>
            <Button
              style="primary"
              type="submit"
              ref={submitRef}
              customStyles={styles.buttonConfirm}
            >
              <FormattedMessage id="booking-info-form-modal.button.confirm" />
            </Button>
            <Button
              customStyles={styles.buttonBack}
              onClick={() => setIsModalOpen(false)}
              ref={ref}
            >
              <FormattedMessage id="booking-info-form-modal.button.cancel" />
            </Button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};
