import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { typo } from '@styles/typo.styles';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  ${grid.basic6col};
  margin-bottom: 86px;

  @media ${breakpoints.from1024} {
    ${grid.basic50col};
    margin-bottom: 129px;
  }
`;

const contentWrapper = css`
  grid-column: 1 / span 6;
  display: flex;
  flex-direction: column;
  background: var(--color-background-2);
  border-radius: 16px;
  gap: inherit;

  @media ${breakpoints.fromMed} {
    flex-direction: row;
  }

  @media ${breakpoints.from1024} {
    grid-column: 5 / span 42;
    max-height: 560px;
    gap: 79px;
  }

  @media ${breakpoints.fromXLrg} {
    gap: 196px;
  }
`;

const leftSide = css`
  flex: 1;
  margin: 40px 15px 16px;
  max-width: 100%;

  img {
    max-width: 160px;
  }

  @media ${breakpoints.fromMed} {
    margin: 40px 0 59px 55px;
  }
`;

const rightSide = css`
  display: flex;
  justify-content: center;
  flex: 0.9;
  position: relative;

  @media ${breakpoints.fromMed} {
    margin: 52px 42px 0 0;
  }

  @media ${breakpoints.fromXLrg} {
    margin: 32px 131px 0 0;
  }
`;

const overlay = css`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const rightImage = css`
  grid-column: 1 / span 6;
  overflow: hidden;
  min-height: 312px;
  padding: 0 44px;

  img {
    border-radius: 6px;
  }

  @media ${breakpoints.fromMed} {
    padding: 0;
  }

  @media ${breakpoints.fromLrg} {
    position: absolute;
    bottom: 0;
    height: auto;
    min-height: 460px;
  }
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 12px;
`;

const anchor = css`
  @media ${breakpoints.fromMed} {
    display: block;
    width: 108px;
    height: 22px;
  }
`;

const companyName = css`
  --color: var(--geist-foreground);
`;

const textWrapper = css`
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  gap: 12px;

  @media ${breakpoints.fromXLrg} {
    margin: 68px 0;
  }
`;

const title = css`
  ${typo.from40to24};
  color: var(--color-text-6);
  line-height: clamp(1.63rem, 1.3rem + 1.45vw, 2.75rem);
  font-weight: 700;
  letter-spacing: -0.02em;
`;

const description = css`
  ${typo.from16to14};
  color: var(--color-text-5);
  font-weight: 400;
`;

const storeImagesWrapper = css`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  @media ${breakpoints.fromMed} {
    justify-content: unset;
  }
`;

const qrWrapper = css`
  display: none;

  @media ${breakpoints.fromMed} {
    display: flex;
    padding-left: 19px;
    gap: 57px;

    img {
      border-radius: 12px;
    }
  }
`;

export const styles = {
  base,
  contentWrapper,
  leftSide,
  rightSide,
  overlay,
  rightImage,
  logoWrapper,
  anchor,
  companyName,
  textWrapper,
  title,
  description,
  storeImagesWrapper,
  qrWrapper,
};
