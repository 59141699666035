import { selector, useRecoilValue } from 'recoil';
import { getCurrentMonthYear, getMostReservedDate } from '../utils';
import { analyticsSelector } from './selectors';

/** Sum of all bookings */
const sumOfBookingsSelector = selector({
  key: 'sumOfBookingsSelector',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    return analytics?.bookings?.reduce(
      (sum, booking) => sum + booking.numberOfBookings,
      0,
    );
  },
});

/* Current month sum of bookings */
const currentMonthNumberOfBookings = selector({
  key: 'currSumOfBookingsSelector',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();

    return analytics?.bookings?.reduce((sum, booking) => {
      const bookingDate = new Date(booking.date);

      if (
        bookingDate.getMonth() === currentMonth &&
        bookingDate.getFullYear() === currentYear
      ) {
        return sum + booking.numberOfBookings;
      }
      return sum;
    }, 0);
  },
});

/* Past month sum of bookings */
const pastMonthNumberOfBookings = selector({
  key: 'pastMonthNumberOfBookings',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();

    return analytics?.bookings?.reduce((sum, booking) => {
      const bookingDate = new Date(booking.date);

      if (
        bookingDate.getMonth() === currentMonth - 1 &&
        bookingDate.getFullYear() === currentYear
      ) {
        return sum + booking.numberOfBookings;
      }
      return sum;
    }, 0);
  },
});

/* Current month most popular day */
const currentMonthMostPopularDay = selector({
  key: 'currentMonthMostPopularDay',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();

    const currentMonthBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return (
        bookingDate.getMonth() === currentMonth &&
        bookingDate.getFullYear() === currentYear
      );
    });

    return getMostReservedDate(currentMonthBookings);
  },
});

/* Past month most popular day */
const pastMonthMostPopularDay = selector({
  key: 'pastMonthMostPopularDay',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();

    const pastMonthBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return (
        bookingDate.getMonth() === currentMonth - 1 &&
        bookingDate.getFullYear() === currentYear
      );
    });

    return getMostReservedDate(pastMonthBookings);
  },
});

/**Monthly statistics */
export const useSumOfBookings = () => useRecoilValue(sumOfBookingsSelector);
export const useCurrentMonthNumberOfBookings = () =>
  useRecoilValue(currentMonthNumberOfBookings);
export const usePastMonthNumberOfBookings = () =>
  useRecoilValue(pastMonthNumberOfBookings);

export const useCurrentMonthMostPopularDay = () =>
  useRecoilValue(currentMonthMostPopularDay);
export const usePastMonthMostPopularDay = () =>
  useRecoilValue(pastMonthMostPopularDay);
