import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';
import { useSetIsLoading } from '../state';

export function useGroupFirebase() {
  const addNewGroupMemberCollable =
    useFunction<AddGroupMemberData>('addGroupMember');
  const removeNewGroupMemberCollable =
    useFunction<RemoveGroupMemberData>('removeGroupMember');
  const removeGroupMemberMembershipCollable =
    useFunction<RemoveGroupMemberMembershipData>('removeGroupMemberMembership');
  const extendGroupMemberMembershipCollable =
    useFunction<ExtendGroupMemberMembershipData>('extendGroupMemberMembership');
  const { formatMessage } = useIntl();
  const setIsLoading = useSetIsLoading();

  async function addNewGroupMember(groupMemberData: AddGroupMemberData) {
    if (!addNewGroupMemberCollable) return;
    try {
      setIsLoading(true);
      await addNewGroupMemberCollable(groupMemberData);
      toast.success(formatMessage({ id: 'toasts.groups.member-added' }));
    } catch {
      toast.error(formatMessage({ id: 'toasts.groups.member-added.error' }));
    } finally {
      setIsLoading(false);
    }
  }

  async function removeNewGroupMember(groupMemberData: RemoveGroupMemberData) {
    if (!removeNewGroupMemberCollable) return;
    try {
      setIsLoading(true);
      await removeNewGroupMemberCollable(groupMemberData);
      toast.success(formatMessage({ id: 'toasts.groups.member-removed' }));
    } catch {
      toast.error(formatMessage({ id: 'toasts.groups.member-removed.error' }));
    } finally {
      setIsLoading(false);
    }
  }

  async function removeGroupMemberMembership(
    groupMembershipData: RemoveGroupMemberMembershipData,
  ) {
    if (!removeGroupMemberMembershipCollable) return;
    try {
      setIsLoading(true);
      await removeGroupMemberMembershipCollable(groupMembershipData);
      toast.success(formatMessage({ id: 'toasts.groups.membership-removed' }));
    } catch {
      toast.error(
        formatMessage({ id: 'toasts.groups.membership-removed.error' }),
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function addGroupMemberMembership(
    groupMembershipData: ExtendGroupMemberMembershipData,
  ) {
    if (!extendGroupMemberMembershipCollable) return;
    try {
      setIsLoading(true);
      await extendGroupMemberMembershipCollable(groupMembershipData);
      toast.success(formatMessage({ id: 'toasts.groups.membership-extended' }));
    } catch {
      toast.error(
        formatMessage({ id: 'toasts.groups.membership-extended.error' }),
      );
    } finally {
      setIsLoading(false);
    }
  }

  return {
    addNewGroupMember,
    removeNewGroupMember,
    removeGroupMemberMembership,
    addGroupMemberMembership,
  };
}
