import { useFunction } from '@modules/firebase';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export const useValidateEditKey = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const validateEditKeyCallable =
    useFunction<ValidateEditKeyData>('validateEditKey');

  const [error, setError] = useState(false);

  const editKey = typeof router.query?.ek === 'string' ? router.query.ek : '';
  const uid = typeof router.query?.id === 'string' ? router.query.id : '';

  async function validateEditKey() {
    try {
      const { data } = await validateEditKeyCallable({ editKey, uid });
      if (!data.success) {
        setError(true);
        throw new Error(
          formatMessage({ id: 'toasts.validate-edit-key.error' }),
        );
      }
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
    }
  }

  useEffect(() => {
    if (!validateEditKeyCallable || !editKey) return;

    validateEditKey();
  }, []);

  useEffect(() => {
    if (error) router.push('/');
  }, [error]);

  return;
};
