import { useAdminRedirects } from '@modules/admin';
import {
  useGroupAnalyticsListener,
  useSportAnalyticsListener,
} from '@modules/analytics';
import {
  useFacilitiesListener,
  useFacilityListener,
  useFacilityRolesListener,
} from '@modules/facility';
import { useGroupsListener } from '@modules/groups';
import { useMobile } from '@shared/hooks';
import { LoadingPage } from '@ui/LoadingPage';
import { Footer, MobileDownloadScreen, MobileHeader } from '@ui/index';

interface Props {
  children?: React.ReactNode;
}

export const AdminLayout: React.FC<Props> = ({ children }) => {
  /**Facilities collection */
  useFacilitiesListener();
  useFacilityListener();
  useFacilityRolesListener();

  /**Groups collection */
  useGroupsListener();

  /** Analytics collection */
  useSportAnalyticsListener();
  useGroupAnalyticsListener();

  const { isMobileWidth } = useMobile();
  const { loading } = useAdminRedirects();

  if (loading) {
    return <LoadingPage />;
  }

  if (isMobileWidth) {
    return (
      <>
        <MobileHeader />
        <MobileDownloadScreen />
        <Footer />
      </>
    );
  }
  return <>{children}</>;
};
