import { SerializedStyles } from '@emotion/react';
import { Button } from '@ui/Button';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { GroupMemberCard } from '../../group-member-card';
import { styles } from './info.styles';

const AddIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/add-user.svg'),
  {
    loading: () => null,
  },
);

const CloseIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/close.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  group: Group;
  handleCloseModal: () => void;
  setCurrentStep: (value: string) => void;
}

export const Info: FC<Props> = ({
  group,
  handleCloseModal,
  setCurrentStep,
}) => {
  const activeMembers = group.members.filter((member) => member.isActive);

  return (
    <>
      <div css={styles.flex}>
        <p css={styles.name}>{group.name}</p>
        <Button customStyles={styles.buttonClose} onClick={handleCloseModal}>
          <CloseIcon viewBox="0 0 24 24" />
        </Button>
      </div>
      <div css={styles.flex}>
        <div css={styles.capacity}>
          <FormattedMessage id="single-group-modal.info.members" />
          {` ${activeMembers.length} / ${group.capacity}`}
        </div>
        <Button
          customStyles={styles.buttonAdd}
          style="primary"
          disabled={activeMembers.length >= group.capacity}
          onClick={() => setCurrentStep('memberForm')}
        >
          <AddIcon viewBox="0 0 16 16" />
          <FormattedMessage id="single-group-modal.info.add-member" />
        </Button>
      </div>
      <ul css={styles.list}>
        {activeMembers
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((member) => (
            <GroupMemberCard
              key={member.email}
              member={member}
              groupId={group.id}
              setCurrentStep={setCurrentStep}
            />
          ))}
      </ul>
    </>
  );
};
