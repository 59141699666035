import { SerializedStyles } from '@emotion/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from '../styles';
import { NavigationHeader } from './NavigationHeader';

const GlobeIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/globe.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  locale: string;
  isLanguageSwitcherModalOpen: boolean;
  isAdmin: boolean;
  isEmployee: boolean;
  openModal: () => void;
  handleAdminClick: () => string;
}

export const LandingHeader: React.FC<Props> = ({
  locale,
  isLanguageSwitcherModalOpen,
  isAdmin,
  isEmployee,
  openModal,
  handleAdminClick,
}) => {
  const [activeSection, setActiveSection] = useState<string | null>(null);

  const navStyle = (path: string) => {
    if (activeSection === path) return styles.navigationItemActive;

    return styles.navigationItem;
  };

  /**
   *
   * @stjepandzojic here we need to figure out how to unobserve when user clicks on link in navigation
   * curretnly when user clicks contact section observer will set hero section as active
   */
  function handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id);
      }
    });
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });

    const sections = document.querySelectorAll(
      '#hero, #info, #news, #hosts, #contact, #faq',
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
      <nav css={styles.navigation}>
        <Link css={navStyle('hero')} href="#hero" scroll={false}>
          <FormattedMessage id="header.start" />
        </Link>
        <Link css={navStyle('info')} href="#info" scroll={false}>
          <FormattedMessage id="header.about" />
        </Link>
        <Link css={navStyle('hosts')} href="#hosts" scroll={false}>
          <FormattedMessage id="header.become-partner" />
        </Link>
        {/* <Link css={navStyle('news')} href="#news" scroll={false}>
              Novosti
            </Link> */}
        <Link css={navStyle('faq')} href="#faq" scroll={false}>
          <FormattedMessage id="header.faq" />
        </Link>
        <Link css={navStyle('contact')} href="#contact" scroll={false}>
          <FormattedMessage id="header.contact" />
        </Link>
      </nav>
      <NavigationHeader
        locale={locale}
        isLanguageSwitcherModalOpen={isLanguageSwitcherModalOpen}
        isAdmin={isAdmin}
        isEmployee={isEmployee}
        openModal={openModal}
        handleAdminClick={handleAdminClick}
      />
    </>
  );
};
