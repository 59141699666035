import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { Booking } from '../Booking';

export const bookingFirestoreConverter: FirestoreDataConverter<Booking> = {
  toFirestore: (booking: Booking): object => {
    const firestoreData = {
      ...new Booking(booking),
    };
    return firestoreData;
  },

  fromFirestore: (snapshot: QueryDocumentSnapshot<Booking>): Booking => {
    const firestoreData = snapshot.data();
    return new Booking(firestoreData);
  },
};
