import { useFacility } from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect } from 'react';
import { useSetGroups } from '../state';
import { groupsFirestoreConverter } from '../utils';

/**This hook is used to listen to all groups where facilityId is equal to current facilityId */
export function useGroupsListener() {
  const setGroups = useSetGroups();
  const facility = useFacility();

  useEffect(() => {
    const isGroup = facility?.type === 'group';
    if (!facility?.id || !isGroup) return;

    const q = query(
      collection(FirebaseService.firestore, 'groups'),
      where('facilityId', '==', facility?.id),
    ).withConverter(groupsFirestoreConverter);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const groups: Group[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id };
      });
      setGroups(groups);
    });

    return () => {
      unsubscribe();
    };
  }, [facility]);
}
