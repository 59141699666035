import { addDays } from 'date-fns';

export function getDayFromWeek(currentDate: Date, dayNumberNeeded?: number) {
  if (!dayNumberNeeded) return currentDate;

  const currentDayNumber = currentDate.getDay();
  const diff = dayNumberNeeded - currentDayNumber;

  if (currentDayNumber === 0) return addDays(currentDate, -7 + diff);

  if (!Boolean(diff)) return currentDate;

  return addDays(currentDate, diff);
}
