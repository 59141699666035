import { useCurrentFacilityId, useSetFacilityRoles } from '@modules/facility';
import { FirebaseService, getTypedCollectionDocument } from '@modules/firebase';
import { facilityRolesFirestoreConverter } from '@modules/users';
import { collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

/**This hook is used to listen on current facility roles at facilities/{currentFacilityId}/users/{userId} */
export function useFacilityRolesListener() {
  const [loading, setLoading] = useState(true);

  const currentFacilityId = useCurrentFacilityId();
  const setFacilityRoles = useSetFacilityRoles();

  useEffect(() => {
    const facilityRolesCollection = collection(
      FirebaseService.firestore,
      `/facilities/${currentFacilityId}/users`,
    ).withConverter(facilityRolesFirestoreConverter);

    const unsubscribe = onSnapshot(
      facilityRolesCollection,
      (snapshot) => {
        const docs = snapshot.docs.flatMap((doc) =>
          getTypedCollectionDocument<FacilityRole[]>(doc),
        );
        const sortedDocs = docs.sort(
          (a, b) =>
            new Date(b.dateCreated).getTime() -
            new Date(a.dateCreated).getTime(),
        );

        docs.length ? setFacilityRoles(sortedDocs) : setFacilityRoles([]);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, [currentFacilityId]);

  return { loading };
}
