import { css } from '@emotion/react';
import { scroll } from '@styles/scroll.styles';

const list = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  ${scroll.base}
  max-height: 60vh;
`;

const base = css`
  display: flex;
  flex-direction: column;
`;

const hoursWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const trashButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    flex-shrink: 0;
    path {
      transition: all 0.2s ease-out;
      fill: var(--color-error);
    }
  }

  :hover {
    path {
      opacity: 0.8;
    }
  }
`;

const name = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  text-transform: capitalize;
`;

const date = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-4);
  text-transform: capitalize;
`;

const hours = css`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-6);
  font-family: var(--font-family-primarySemiBold);
`;

const price = css`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-4);
`;

const priceWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const styles = {
  priceWrapper,
  list,
  base,
  hoursWrapper,
  trashButton,
  name,
  date,
  hours,
  price,
};
