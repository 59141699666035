import { selector, useRecoilValue } from 'recoil';
import { getCurrentMonthYear } from '../utils';
import { analyticsSelector } from './selectors';

/* Current month number of reservations */
const currentMonthNumberOfReservations = selector({
  key: 'currentMonthNumberOfReservations',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const { currentMonth, currentYear } = getCurrentMonthYear();
    const reservationsByMonth: number[] = [0, 0, 0, 0, 0, 0, 0];

    const currentMonthBookings = analytics?.bookings?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return (
        bookingDate.getMonth() === currentMonth &&
        bookingDate.getFullYear() === currentYear
      );
    });

    const filteredBookings = currentMonthBookings?.filter((booking) => {
      const day = new Date(booking.date).getDate();
      return [1, 5, 10, 15, 20, 25, 30].includes(day);
    });

    filteredBookings?.forEach((booking) => {
      const day = new Date(booking.date).getDate();
      const index = [1, 5, 10, 15, 20, 25, 30].indexOf(day);
      reservationsByMonth[index] += booking.numberOfBookings;
    });

    return reservationsByMonth;
  },
});

/* Current week number of reservations */
const lastWeekNumberOfReservations = selector({
  key: 'lastWeekNumberOfReservations',
  get: ({ get }) => {
    const analytics = get(analyticsSelector);
    const reservationsByWeek: number[] = [0, 0, 0, 0, 0, 0, 0];

    const today = new Date();
    const sevenDaysBefore = new Date();
    sevenDaysBefore.setDate(today.getDate() - 7);

    const lastWeekBookings = analytics?.bookings
      ?.filter((booking) => {
        const bookingDate = new Date(booking.date);
        return bookingDate >= sevenDaysBefore && bookingDate <= today;
      })
      ?.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

    lastWeekBookings?.forEach((booking) => {
      const day = new Date(booking.date).getDay();
      if (day === 0) reservationsByWeek[6] += booking.numberOfBookings;
      else reservationsByWeek[day - 1] += booking.numberOfBookings;
    });

    return reservationsByWeek;
  },
});

/* Number of reservations graph */
export const useCurrentMonthNumberOfReservations = () =>
  useRecoilValue(currentMonthNumberOfReservations);
export const useLastWeekNumberOfReservations = () =>
  useRecoilValue(lastWeekNumberOfReservations);
