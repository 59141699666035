export interface Booking {
  id?: string; // this is bookings/{{bookingId}}
  /**Facility info */
  facilityId: string;
  facilityName: string;
  facilityAddress: string; // fali na WEBU ovaj
  courtId: string;
  courtName: string;
  /**Booking info */
  name: string;
  email: string;
  startTime: number;
  endTime: number;
  phoneNumber?: string;
  status: BookingStatus;
  price: number;
  isRepeat: boolean;
  repeatId?: string | null;
  isTournament: boolean;
  opponent: Opponent | null;
  isBookingNotificationSent: boolean;
  userId?: string | null;
  /**Stripe payment id */
  paymentId: string | null;
  createdAt: number;
  discountsApplied: Discount[];
  isFirstRepeat?: boolean;
  repeatDuration?: number;
  isRepeatCancelled?: boolean;
  /**Push notifications info */
  canceledBy: BookingManager | null;
  createdBy: BookingManager;
  /** Code assinged to the booking. This is what user enters in the PLAYOFF smart lock */
  smartLockCode?: string | null;
  isTeamGame?: boolean | null;
  selectedPackagedBookingData: SelectedPackagedBookingData | null;
  comment?: string | null;
  lmsMatchConfig?: LmsMatchConfig | null;
}

export class Booking {
  constructor(data?: Booking) {
    this.email = data?.email || '';
    this.name = data?.name || '';
    this.phoneNumber = data?.phoneNumber || '';
    this.startTime = data?.startTime || 0;
    this.endTime = data?.endTime || 0;
    this.price = data?.price || 0;
    this.facilityName = data?.facilityName || '';
    this.facilityId = data?.facilityId || '';
    this.courtId = data?.courtId || '';
    this.courtName = data?.courtName || '';
    this.status = data?.status || 'pending';
    this.isRepeat = data?.isRepeat || false;
    this.repeatId = data?.repeatId || null;
    this.isFirstRepeat = data?.isFirstRepeat || false;
    this.repeatDuration = data?.repeatDuration || 0;
    this.isRepeatCancelled = data?.isRepeatCancelled || false;
    this.canceledBy = data?.canceledBy || null;
    this.createdBy = data?.createdBy || null;
    this.isTournament = data?.isTournament || false;
    this.opponent = data?.opponent;
    this.isBookingNotificationSent = false;
    this.createdAt = data?.createdAt || +new Date();
    this.paymentId = data?.paymentId || null;
    this.id = data?.id || '';
    this.discountsApplied = data?.discountsApplied || [];
    this.smartLockCode = data?.smartLockCode || null;
    this.isTeamGame = data?.isTeamGame || null;
    this.selectedPackagedBookingData =
      data?.selectedPackagedBookingData || null;
    this.userId = data?.userId || null;
    this.facilityAddress = data?.facilityAddress || '';
    this.comment = data?.comment || '';
    this.lmsMatchConfig = data?.lmsMatchConfig || null;
  }
}
