import { atom } from 'recoil';

const isRecentResultsOpen = atom<boolean>({
  key: 'is-recent-result-open',
  default: false,
});

export const searchAtoms = {
  isRecentResultsOpen,
};
