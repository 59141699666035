import { useCallback, useEffect, useRef } from 'react';

export function useEscPress<T extends HTMLElement>(callback: VoidFunction) {
  const ref = useRef<T>(null);

  const onEsc = useCallback(
    (e: KeyboardEvent) => {
      if (!ref.current || e.key !== 'Escape') return;

      callback();
    },
    [ref, callback],
  );

  useEffect(() => {
    window.addEventListener('keydown', onEsc);

    return () => window.removeEventListener('keydown', onEsc);
  }, [onEsc]);

  return ref;
}
