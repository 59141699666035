import { components } from 'react-select';
import { CustomOptionProps } from '../models';
import FacilityIcon from 'public/icons/facility-16.svg';
import { styles } from './styles';

const { Option } = components;

export const CustomSelectOption = (props: CustomOptionProps) => (
  <Option {...props}>
    <div css={styles.valueContainer}>
      <FacilityIcon />
      <span css={styles.placeholder}>{props.data.label}</span>
    </div>
  </Option>
);
