import { useClickOutsideElement } from '@shared/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowIconDown from 'public/icons/arrow-icon-down-16.svg';
import ArrowIconUp from 'public/icons/arrow-icon-up-16.svg';
import CheckIcon from 'public/icons/check.svg';
import { FC, useState } from 'react';
import { SetterOrUpdater } from 'recoil';
import { styles } from './select.styles';

interface Props<T> {
  activeValue: { label: string; value: T };
  options: { label: string; value: T }[];
  dropdownLabel?: string;
  onChange: SetterOrUpdater<T>;
}

export const Select: FC<Props<string | number>> = ({
  activeValue,
  options,
  dropdownLabel,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const optionsContainerRef = useClickOutsideElement<HTMLDivElement>(() =>
    setIsOpen(false),
  );
  const isActive = (value: string | number) => value === activeValue.value;

  return (
    <div css={styles.base}>
      <span css={styles.activeItem} onClick={() => setIsOpen(!isOpen)}>
        {activeValue.label}
        {isOpen ? <ArrowIconDown /> : <ArrowIconUp />}
      </span>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={optionsContainerRef}
            css={styles.items}
            initial={{ opacity: 0, y: -20, translateX: '-50%' }}
            animate={{ opacity: 1, y: 0, translateX: '-50%' }}
            exit={{ opacity: 0, y: -20, translateX: '-50%' }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
          >
            <span css={styles.label}>{dropdownLabel}</span>
            <ul css={styles.list}>
              {options.map(({ value, label }, i) => (
                <li
                  key={i}
                  css={
                    isActive(value) ? styles.listItemActive : styles.listItem
                  }
                  onClick={() => {
                    onChange(value);
                    setIsOpen(false);
                  }}
                >
                  <span>{label}</span>
                  {isActive(value) && <CheckIcon />}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
