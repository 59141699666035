import { useRepeat } from '@modules/booking-new';
import { getWeekInCroatian } from '@shared/utils';
import Clock from 'public/icons/clock.svg';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { styles } from './repeat.styles';

export const Repeat: FC = () => {
  const { formatMessage } = useIntl();
  const {
    isRepeatChecked,
    repeatDuration,
    hasError,
    setIsRepeatChecked,
    handleOnChange,
  } = useRepeat();

  return (
    <div css={styles.base}>
      <label
        css={
          isRepeatChecked
            ? styles.checkboxActive(
                formatMessage({ id: 'booking-summary.repeat.checkboxLabel' }),
              )
            : styles.checkbox(
                formatMessage({ id: 'booking-summary.repeat.checkboxLabel' }),
              )
        }
      >
        <input
          type="checkbox"
          checked={isRepeatChecked}
          onChange={() => setIsRepeatChecked(!isRepeatChecked)}
        />
      </label>
      {isRepeatChecked && (
        <div css={styles.durationWrapper}>
          <Clock css={styles.durationIcon} viewBox="0 0 24 24" />
          <input
            value={repeatDuration}
            onChange={handleOnChange}
            css={styles.input}
            type="number"
            inputMode="numeric"
            min={1}
            placeholder={formatMessage({
              id: 'booking-summary.repeat.placeholder',
            })}
          />
          <span css={styles.repeatText}>
            {getWeekInCroatian({
              duration: repeatDuration,
              label: {
                first: formatMessage({
                  id: 'booking-summary.week.first',
                }),
                second: formatMessage({
                  id: 'booking-summary.week.second',
                }),
                third: formatMessage({
                  id: 'booking-summary.week.third',
                }),
              },
            })}
          </span>
          {hasError && (
            <span css={styles.inputError}>
              {hasError === 'min'
                ? formatMessage({
                    id: 'booking-summary.repeat.min',
                  })
                : formatMessage({
                    id: 'booking-summary.repeat.max',
                  })}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
