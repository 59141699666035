import { useActiveCourt, useSetActiveCourt } from '@modules/booking-new';
import { Button } from '@ui/Button';
import { FC, useEffect } from 'react';
import { styles } from './bookingCourtsSelector.styles';

interface Props {
  courts: Court[];
}
export const BookingCourtsSelector: FC<Props> = ({ courts }) => {
  const activeCourt = useActiveCourt();
  const setActiveCourt = useSetActiveCourt();

  useEffect(() => {
    setActiveCourt(courts[0]);
  }, [setActiveCourt, courts]);

  if (courts.length === 1) return null;

  return (
    <ul css={styles.courts}>
      {courts.map((court) => (
        <li key={court.id}>
          <Button
            onClick={() => setActiveCourt(court)}
            customStyles={
              activeCourt?.name === court.name
                ? styles.courtActive
                : styles.court
            }
          >
            {court.name}
          </Button>
        </li>
      ))}
    </ul>
  );
};
