import { useGroupFirebase, useGroupHelpers } from '@modules/groups/hooks';
import { Button } from '@ui/Button';
import { format } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './membershipCancelFee.styles';

interface Props {
  groupId: string;
  member: GroupMember;
  setCurrentMembershipStep: (value: string) => void;
  setCurrentStep: (value: string) => void;
}

export const MembershipCancelFee: FC<Props> = ({
  groupId,
  member,
  setCurrentMembershipStep,
  setCurrentStep,
}) => {
  const { getLastMembershipFee } = useGroupHelpers();
  const { removeGroupMemberMembership } = useGroupFirebase();
  const expirationDate = Boolean(member.membershipExpiryDate)
    ? format(member.membershipExpiryDate, 'd.M.yyyy.')
    : '/';
  const newExpirationDate = getLastMembershipFee(member.membershipPaymentInfo)
    ? format(getLastMembershipFee(member.membershipPaymentInfo), 'd.M.yyyy.')
    : '/';

  return (
    <>
      <p css={styles.title}>
        <FormattedMessage id="membership.cancel-fee.title" />
      </p>
      <p css={styles.description}>
        <FormattedMessage id="membership.cancel-fee.description" />
      </p>
      <p css={styles.dueDateWrapper}>
        <FormattedMessage id="membership.cancel-fee.deadline" />
        <span css={styles.lastExpirationDate}>{expirationDate}</span>{' '}
        <span css={styles.expirationDate}>{newExpirationDate}</span>
      </p>
      <div css={styles.flex}>
        <Button
          customStyles={styles.buttonBack}
          onClick={() => setCurrentMembershipStep('info')}
        >
          <FormattedMessage id="membership.cancel-fee.back" />
        </Button>
        <Button
          customStyles={styles.buttonDelete}
          onClick={async () => {
            await removeGroupMemberMembership({
              groupId,
              memberEmail: member.email,
            });
            setCurrentStep('info');
          }}
        >
          <FormattedMessage id="membership.cancel-payment" />
        </Button>
      </div>
    </>
  );
};
