import { css, keyframes } from '@emotion/react';

const shimmer = keyframes`
  0% {
    background-position: -40rem 0;
  }
  100% {
    background-position: 40rem 0;
  }
`;

const shimmerStyle = css`
  animation: ${shimmer} 2s infinite linear;
  background: linear-gradient(to right, #2b2a33 8%, #3c3b48 18%, #2b2a33 33%);
  background-size: 80rem 100%;
`;

const wrapper = css`
  background-color: #2b2a33;
  border-radius: 10px;
  width: 100%;
  height: 122px;
  margin-bottom: 12px;
  border: 1px solid rgba(248, 248, 248, 0.08);
  overflow: hidden;
  ${shimmerStyle}
`;

export const styles = {
  wrapper,
};
