import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
  StorageError,
  uploadBytes,
} from 'firebase/storage';
import { FirebaseService } from '../initFirebase';

export function useStorage() {
  const storage = getStorage();

  function handleError(error: StorageError) {
    return error;
  }

  function getCollectionReference() {
    return ref(storage, `${FirebaseService.auth.currentUser?.uid}`);
  }

  function updatePropUrl<T>(data: T, prop: keyof T, url: string) {
    if (typeof data[prop] === 'string') {
      data[prop] = url as T[keyof T];
    }

    return data;
  }

  async function addStorageItemAsync(file: File, path: string, name?: string) {
    let reference = ref(getCollectionReference(), path);

    if (name) reference = ref(getCollectionReference(), name);

    return uploadBytes(reference, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .catch(handleError);
  }

  async function addFromLocalBlobAsync(
    blobUrl: string,
    path: string,
    name: string,
  ) {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    if (!blob) return;

    return addStorageItemAsync(new File([blob], name), path, name);
  }

  async function removeStorageItemAsync(path: string) {
    return deleteObject(ref(getCollectionReference(), path)).catch(handleError);
  }

  async function processUploadsAsync<T>(
    documentName: string,
    refId: string,
    data: T,
    imageProps: string[],
  ) {
    for (const prop of imageProps) {
      const imageUrl: string = data[prop];
      if (!imageUrl) continue;

      const alreadyUploaded = imageUrl.startsWith(
        'https://firebasestorage.googleapis.com',
      );

      if (alreadyUploaded) continue;

      const uploadUrl = await addFromLocalBlobAsync(
        imageUrl,
        `${documentName}/${refId}`,
        prop,
      );

      if (typeof uploadUrl !== 'string') return;

      updatePropUrl<T>(data, prop as keyof T, uploadUrl);
    }

    return data;
  }

  return {
    addStorageItemAsync,
    removeStorageItemAsync,
    processUploadsAsync,
  };
}
