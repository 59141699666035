export const FILTER_RESULTS = {
  en: ['Upcoming', 'Past', 'Canceled', 'Repeated'],
  hr: ['Nadolazeće', 'Prošle', 'Otkazane', 'Stalne'],
  es: ['Próximas', 'Pasadas', 'Canceladas', 'Repetidas'],
  fr: ['À venir', 'Passées', 'Annulées', 'Répétées'],
  de: ['Bevorstehend', 'Vergangen', 'Abgesagt', 'Wiederholt'],
  pt: ['Próximas', 'Anteriores', 'Canceladas', 'Repetidas'],
  it: ['Imminenti', 'Passate', 'Annullate', 'Ripetute'],
  bo: ['Nadolazeće', 'Prošle', 'Otkazane', 'Stalne'],
  sr: ['Nadolazeće', 'Prošle', 'Otkazane', 'Stalne'],
};
