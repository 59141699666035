import { useEffect, useState } from 'react';
import { TranslationFileNotFoundError } from '../errors';
import defaultTranslation from 'public/translations/hr.json';

export function useCurrentTranslations(locale: string | undefined) {
  const [currentTranslations, setCurrentTranslations] =
    useState(defaultTranslation);

  useEffect(() => {
    import(`public/translations/${locale}.json`)
      .then((module) => setCurrentTranslations(module.default))
      .catch((error) => {
        throw new TranslationFileNotFoundError(error.message);
      });
  }, [locale]);

  return currentTranslations;
}
