import { useCallback, useEffect, useRef } from 'react';

export function useClickOutsideElement<T extends HTMLElement>(
  callback: VoidFunction,
  withDefault?: boolean,
) {
  const ref = useRef<T>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        !withDefault && event.preventDefault();
        callback();
      }
    },
    [callback],
  );

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return ref;
}
