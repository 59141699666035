import {
  atom,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { BookedHours } from '../models';

const currentDate = atom<Date>({
  key: 'booking.currentDate',
  default: new Date(),
});

const activeCourt = atom<Court | null>({
  key: 'booking.activeCourt',
  default: null,
});

const isDestructiveMode = atom<boolean>({
  key: 'booking.isDestructiveMode',
  default: false,
});

const bookings = atom<Booking[] | undefined>({
  key: 'booking.bookings',
  default: [],
});

const currentBookedHours = atom<BookedHours[]>({
  key: 'booking.currentBookedHours',
  default: [],
});

const currentToDeleteBookedHours = atom<BookedHours[]>({
  key: 'booking.currentToDeleteBookedHours',
  default: [],
});

const isRepeatChecked = atom<boolean>({
  key: 'booking.isRepeatCheckedd',
  default: false,
});

const repeatDuration = atom<number>({
  key: 'booking.repeatDuration',
  default: 4,
});

const isModalOpen = atom<boolean>({
  key: 'booking.isModalOpen',
  default: false,
});

const isLoading = atom<boolean>({
  key: 'booking.isLoading',
  default: false,
});

const showFilter = atom<string>({
  key: 'booking.showFilter',
  default: 'all',
});

const repeatError = atom<'min' | 'max' | null>({
  key: 'booking.hasRepeatError',
  default: null,
});

const bookingSlotInfo = atom<Booking | null>({
  key: 'booking.bookingSlotInfo',
  default: null,
});

const isDayView = atom<boolean>({
  key: 'booking.isDayView',
  default: true,
});

export const bookingAtoms = {
  currentDate,
  activeCourt,
  isDestructiveMode,
  bookings,
  currentBookedHours,
  currentToDeleteBookedHours,
  isRepeatChecked,
  repeatDuration,
  isModalOpen,
  isLoading,
  showFilter,
};

export const useActiveCourt = () => useRecoilValue(activeCourt);
export const useCurrentDate = () => useRecoilValue(currentDate);
export const useIsDestructiveMode = () => useRecoilValue(isDestructiveMode);
export const useBookings = () => useRecoilValue(bookings);
export const useCurrentBookedHours = () => useRecoilValue(currentBookedHours);
export const useCurrentToDeleteBookedHours = () =>
  useRecoilValue(currentToDeleteBookedHours);
export const useIsRepeatChecked = () => useRecoilValue(isRepeatChecked);
export const useRepeatDuration = () => useRecoilValue(repeatDuration);
export const useIsModalOpen = () => useRecoilValue(isModalOpen);
export const useIsLoading = () => useRecoilValue(isLoading);
export const useShowFilter = () => useRecoilValue(showFilter);
export const useRepeatError = () => useRecoilValue(repeatError);
export const useBookingSlotInfo = () => useRecoilValue(bookingSlotInfo);
export const useIsDayView = () => useRecoilValue(isDayView);

export const useSetActiveCourt = () => useSetRecoilState(activeCourt);
export const useSetCurrentDate = () => useSetRecoilState(currentDate);
export const useSetIsDestructiveMode = () =>
  useSetRecoilState(isDestructiveMode);
export const useSetBookings = () => useSetRecoilState(bookings);
export const useSetCurrentBookedHours = () =>
  useSetRecoilState(currentBookedHours);
export const useSetCurrentToDeleteBookedHours = () =>
  useSetRecoilState(currentToDeleteBookedHours);
export const useSetIsRepeatChecked = () => useSetRecoilState(isRepeatChecked);
export const useSetRepeatDuration = () => useSetRecoilState(repeatDuration);
export const useSetIsModalOpen = () => useSetRecoilState(isModalOpen);
export const useSetIsLoading = () => useSetRecoilState(isLoading);
export const useSetShowFilter = () => useSetRecoilState(showFilter);
export const useSetRepeatError = () => useSetRecoilState(repeatError);
export const useSetBookingSlotInfo = () => useSetRecoilState(bookingSlotInfo);
export const useSetIsDayView = () => useSetRecoilState(isDayView);

/**Resets */
export const useResetCurrentBookedHours = () =>
  useResetRecoilState(currentBookedHours);
export const useResetCurrentToDeleteBookedHours = () =>
  useResetRecoilState(currentToDeleteBookedHours);
export const useResetRepeatError = () => useResetRecoilState(repeatError);
export const useResetBookingSlotInfo = () =>
  useResetRecoilState(bookingSlotInfo);
