import { css } from '@emotion/react';

const buttonStyle = {
  primary: css`
    background-color: var(--color-general-primary);
    color: var(--color-text-1);

    :disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  `,
  secondary: css`
    background-color: hsl(29, 82%, 80%);
    color: hsl(160, 4%, 14%);
    box-shadow: 0px 0px 0px 3px hsla(29, 82%, 80%, 0);
    transition: box-shadow 0.18s var(--transition-easing-cubic);

    &:hover,
    &:active {
      box-shadow: 0px 0px 0px 3px hsla(29, 82%, 80%, 0.3);
    }

    :disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  `,
  reset: css`
    cursor: pointer;
    background-color: transparent;
    border-width: 0;
    padding: 0;
  `,
  button: css`
    display: inline-block;
  `,
  rounded: css`
    border-radius: 32px;
  `,
  small: css`
    padding: 8px 16px;
  `,
  medium: css`
    padding: 8px 28px;
  `,
  large: css`
    padding: 12px 40px;
  `,
  normal: css`
    padding: 16px 24px;
  `,
  disabled: css`
    cursor: not-allowed;
    opacity: 0.4;
  `,
};

export { buttonStyle };
