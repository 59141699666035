import { useSetIsSelectSportOpen } from '@modules/facility';
import React, { useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { CustomSelectOption, CustomSelectValue } from './components';
import { FacilityOption } from './models';
import { selectProps, styles } from './styles';
import { FormattedMessage } from 'react-intl';

interface Props {
  facilityOption?: FacilityOption;
}

export const CurrentFacilitySelect: React.FC<Props> = ({ facilityOption }) => {
  const [selectedOption, setSelectedOption] = useState<
    MultiValue<FacilityOption> | SingleValue<FacilityOption> | null
  >(facilityOption);

  const setIsDropdownOpen = useSetIsSelectSportOpen();

  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="facility-select.title" />
        <span css={styles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        isDisabled={true}
        defaultValue={facilityOption}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          MultiValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        styles={{
          ...selectProps.styles,
          control: (base: any) => ({
            ...base,
            border: '1px solid rgba(248, 248, 248, 0.08)',
            backgroundColor: 'transparent',
            marginTop: '8px',
            borderRadius: '10px',
            '&:hover': {
              border: 'none',
              boxShadow: 'none',
            },
          }),
        }}
        value={selectedOption}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
      />
    </div>
  );
};
