import {
  useDefaultClaim,
  useIsPlayOffAdmin,
  useUserClaims,
} from '@modules/auth';
import { useFacility } from '@modules/facility';
import Link from 'next/link';
import { useRouter } from 'next/router';
import CalendarIcon from 'public/icons/calendar_calendar.svg';
import ClockIcon from 'public/icons/clock-16.svg';
import GroupIcon from 'public/icons/group.svg';
import IdIcon from 'public/icons/id_card.svg';
import ChartIcon from 'public/icons/line_chart_up.svg';
import Logo from 'public/logo/playoff-logo-68.svg';
import versionFile from 'public/version.json';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

export const AdminDashboardNavigation: React.FC = () => {
  const facility = useFacility();
  const { pathname } = useRouter();
  const isPlayoffAdmin = useIsPlayOffAdmin();
  const isEmployee = pathname.includes('dashboard/employee');

  const defaultClaim = useDefaultClaim();
  const claims = useUserClaims();
  const newRole = claims?.find(
    (claim) => claim.facilityId === facility.id,
  )?.role;

  function getRole() {
    if (defaultClaim?.role === 'playoff-admin') return 'playoff-admin';

    return newRole;
  }

  function isActive(routes: string[]) {
    if (routes.some((route) => route === pathname)) return styles.activeLink;

    return '';
  }

  return (
    <nav css={styles.nav}>
      <Link href="/">
        <Logo css={styles.logo} />
      </Link>
      <p css={styles.sectionTitle}>
        <FormattedMessage id="admin-nav.dashboard" />
      </p>
      <ul css={styles.list}>
        {facility?.type === 'sport' && (
          <>
            <li>
              <Link
                css={[
                  styles.link,
                  isActive([
                    '/dashboard/playoff-admin/booking-calendar',
                    '/dashboard/admin/booking-calendar',
                    '/dashboard/employee/booking-calendar',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/booking-calendar`}
              >
                <CalendarIcon css={styles.icons} />
                <FormattedMessage id="admin-nav-booking-calendar" />
              </Link>
            </li>
            <li>
              <Link
                css={[
                  styles.link,
                  isActive([
                    '/dashboard/playoff-admin/booking-overview',
                    '/dashboard/admin/booking-overview',
                    '/dashboard/employee/booking-overview',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/booking-overview`}
              >
                <ClockIcon css={styles.icons} />
                <FormattedMessage id="admin-nav-booking-overview" />
              </Link>
            </li>
          </>
        )}
        {facility?.type === 'group' && (
          <li>
            <Link
              css={[
                styles.link,
                isActive([
                  '/dashboard/playoff-admin/groups',
                  '/dashboard/admin/groups',
                  '/dashboard/employee/groups',
                ]),
              ]}
              href={`/dashboard/${getRole()}/groups`}
            >
              <GroupIcon css={styles.icons} />
              <FormattedMessage id="admin-nav-groups" />
            </Link>
          </li>
        )}
      </ul>
      {!isEmployee && (
        <div css={styles.sectionWrapper}>
          <p css={styles.sectionTitle}>
            <FormattedMessage id="admin-nav-admin-tools" />
          </p>
          <ul css={styles.list}>
            <li>
              <Link
                css={[
                  styles.link,
                  isActive([
                    '/dashboard/playoff-admin/management',
                    '/dashboard/admin/management',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/management`}
              >
                <IdIcon css={styles.icons} />
                <FormattedMessage id="admin-nav-management" />
              </Link>
            </li>
            <li>
              <Link
                css={[
                  styles.link,
                  isActive([
                    '/dashboard/playoff-admin/analytics',
                    '/dashboard/admin/analytics',
                  ]),
                ]}
                href={`/dashboard/${getRole()}/analytics`}
              >
                <ChartIcon css={styles.icons} />
                <FormattedMessage id="admin-nav-analytics" />
              </Link>
            </li>
            {isPlayoffAdmin && (
              <div css={styles.sectionWrapper}>
                <p css={styles.sectionTitle}>
                  <FormattedMessage id="admin-nav-playoff.dashboard" />
                </p>
                <ul css={styles.list}>
                  <li>
                    <Link
                      css={[
                        styles.link,
                        isActive(['/dashboard/playoff-admin/playoff']),
                      ]}
                      href={`/dashboard/playoff-admin/playoff`}
                    >
                      <FormattedMessage id="admin-nav-playoff-management" />
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </ul>
        </div>
      )}
      <div css={styles.footer}>
        <Link css={styles.logoWrapper} href="/">
          <Logo css={styles.logoSecondary} viewBox="0 0 68 16" />
          <span>{versionFile.currentVersion}</span>
        </Link>

        <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="footer.privacy-policy" />
        </Link>
        <Link
          href="/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="footer.terms-of-service" />
        </Link>
      </div>
    </nav>
  );
};
