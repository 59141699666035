interface RoleOption {
  value: Role;
  label: string;
}

export function getFacilityPossibleRoleOptions(
  facility: Facility,
  label: {
    admin: string;
    employee: string;
    trainer: string;
    privatePlayer: string;
  },
) {
  const roleOptions: RoleOption[] = [
    {
      value: 'admin',
      label: label.admin,
    },
    {
      value: 'employee',
      label: label.employee,
    },
  ];
  if (facility?.isPrivate) {
    const updatedRoles: RoleOption[] = [
      ...roleOptions,
      {
        value: 'private-player',
        label: label.privatePlayer,
      },
    ];
    return updatedRoles;
  }
  if (facility?.type === 'group') {
    const updatedRoles: RoleOption[] = [
      ...roleOptions,
      {
        value: 'trainer',
        label: label.trainer,
      },
    ];
    return updatedRoles;
  }

  return roleOptions;
}
