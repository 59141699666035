export function getTime(timestamp: number, locale?: string) {
  const date = new Date(timestamp);

  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: locale === 'hr' ? false : true,
  });

  return `${time}${locale === 'hr' ? 'h' : ''}`;
}
