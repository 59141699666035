import {
  getDayFromWeek,
  useGenerateGrid,
  useGenerateHoursList,
  useIsDestructiveMode,
} from '@modules/booking-new';
import { useCurrentLocale } from '@modules/translations';
import { format, isSameDay } from 'date-fns';
import React, { FC } from 'react';
import { BookingSlot } from '../booking-slot/BookingSlot';
import { DesctructiveBookingSlot } from '../booking-slot/DesctructiveBookingSlot';
import { styles } from './singleDay.styles';

interface Props {
  currentDate: Date;
  hours: WorkHour[];
  interval: number;
  dayNumber?: number;
  timeRange?: TimeRange;
  workSchedules?: WorkSchedule[];
}

export const SingleDay: FC<Props> = ({
  currentDate,
  dayNumber,
  hours,
  interval,
  timeRange,
  workSchedules,
}) => {
  const isDestructive = useIsDestructiveMode();
  const dayOfSlot = getDayFromWeek(currentDate, dayNumber);
  const { list } = useGenerateHoursList(
    hours,
    interval,
    dayOfSlot,
    timeRange,
    workSchedules,
  );
  const { gridList } = useGenerateGrid(interval, dayOfSlot, timeRange);
  const { getLocale } = useCurrentLocale();

  return (
    <div css={styles.wrapper}>
      <div
        css={isSameDay(dayOfSlot, new Date()) ? styles.dateActive : styles.date}
      >
        <span>
          {format(getDayFromWeek(currentDate, dayNumber), 'E dd.M.', {
            locale: getLocale(),
          })}
        </span>
        <div css={styles.gradient} />
      </div>
      <div css={styles.list(gridList)}>
        {list?.map(({ from, to, isAvailable, price }, index) => (
          <React.Fragment key={from.toString()}>
            {!isDestructive ? (
              <BookingSlot
                key={from.toString()}
                from={from}
                to={to}
                isAvailable={isAvailable}
                index={index}
                price={price}
                isLast={index === list.length - 1}
              />
            ) : (
              <DesctructiveBookingSlot
                key={from.toString()}
                from={from}
                to={to}
                isAvailable={isAvailable}
                index={index}
                price={price}
                isLast={index === list.length - 1}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
