import { SerializedStyles } from '@emotion/react';
import { useGroupFirebase } from '@modules/groups/hooks';
import {
  useIsLoading,
  useSetNewGroupMemberEmail,
  useSetNewGroupMemberName,
  useSetNewGroupMemberPhone,
  useSetNewGroupMemberSurname,
} from '@modules/groups/state';
import { useNewMemberData } from '@modules/groups/state/selectors';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import { animations } from 'const';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './memberForm.styles';

const User = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/user-16.svg'),
  {
    loading: () => null,
  },
);

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const Phone = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/phone-16.svg'),
  {
    loading: () => null,
  },
);

interface FormValues {
  email?: string;
  name?: string;
  surname?: string;
  phoneNumber?: string;
}

interface Props {
  groupId: string;
  setCurrentStep: (value: string) => void;
}

export const MemberForm: FC<Props> = ({ groupId, setCurrentStep }) => {
  const { formatMessage } = useIntl();
  const isLoading = useIsLoading();
  const newMember = useNewMemberData();
  const setName = useSetNewGroupMemberName();
  const setEmail = useSetNewGroupMemberEmail();
  const setSurname = useSetNewGroupMemberSurname();
  const setPhoneNumber = useSetNewGroupMemberPhone();

  const { addNewGroupMember } = useGroupFirebase();

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = formatMessage({
        id: 'single-group-modal.member-form.toast.error.email.invalid',
      });
    }

    if (!values.email) {
      errors.email = formatMessage({
        id: 'single-group-modal.member-form.toast.error.email.required',
      });
    }

    if (!values?.name) {
      errors.name = formatMessage({
        id: 'single-group-modal.member-form.toast.error.first-name.required',
      });
    }

    if (!values?.surname) {
      errors.surname = formatMessage({
        id: 'single-group-modal.member-form.toast.error.last-name.required',
      });
    }

    return errors;
  };

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik<FormValues>({
      initialValues: {
        email: '',
        name: '',
        surname: '',
        phoneNumber: '',
      },
      validate,
      onSubmit: async (_, { resetForm }) => {
        try {
          await addNewGroupMember({ groupId, member: newMember });
          resetForm();
          setCurrentStep('info');
        } catch {}
      },
    });

  return (
    <motion.div {...animations.opacity({})} css={styles.base}>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}
        css={styles.modal}
      >
        <h2 css={styles.title}>
          <FormattedMessage id="single-group-modal.member-form.create-reservation" />
        </h2>
        <form autoComplete="off" onSubmit={handleSubmit} css={styles.form}>
          <FormInput
            type="text"
            label={formatMessage({
              id: 'single-group-modal.member-form.first-name.label',
            })}
            id="name"
            name="name"
            Icon={User}
            value={values.name}
            error={errors.name}
            touched={touched}
            isRequired
            placeholder={formatMessage({
              id: 'single-group-modal.member-form.first-name.placeholder',
            })}
            onChange={(e) => {
              handleChange(e);
              setName(e.currentTarget.value);
            }}
            onBlur={handleBlur}
          />
          <FormInput
            type="text"
            label={formatMessage({
              id: 'single-group-modal.member-form.last-name.label',
            })}
            id="surname"
            name="surname"
            Icon={User}
            value={values.surname}
            error={errors.surname}
            touched={touched}
            isRequired
            placeholder={formatMessage({
              id: 'single-group-modal.member-form.last-name.placeholder',
            })}
            onChange={(e) => {
              handleChange(e);
              setSurname(e.currentTarget.value);
            }}
            onBlur={handleBlur}
          />
          <FormInput
            type="email"
            label={formatMessage({
              id: 'single-group-modal.member-form.email.label',
            })}
            id="email"
            name="email"
            Icon={Mail}
            value={values.email}
            error={errors.email}
            touched={touched}
            isRequired
            placeholder={formatMessage({
              id: 'single-group-modal.member-form.email.placeholder',
            })}
            onChange={(e) => {
              handleChange(e);
              setEmail(e.currentTarget.value);
            }}
            onBlur={handleBlur}
          />
          <FormInput
            type="text"
            label={formatMessage({
              id: 'single-group-modal.member-form.contact.label',
            })}
            id="phoneNumber"
            name="phoneNumber"
            Icon={Phone}
            value={values.phoneNumber}
            error={errors.phoneNumber}
            touched={touched}
            placeholder={formatMessage({
              id: 'single-group-modal.member-form.contact.placeholder',
            })}
            onChange={(e) => {
              handleChange(e);
              setPhoneNumber(e.currentTarget.value);
            }}
            onBlur={handleBlur}
          />
          <div css={styles.buttonWrapper}>
            <Button
              style="primary"
              type="submit"
              disabled={isLoading}
              customStyles={styles.buttonConfirm}
            >
              <FormattedMessage id="single-group-modal.member-form.save" />
            </Button>
            <Button
              customStyles={styles.buttonBack}
              onClick={() => setCurrentStep('info')}
            >
              <FormattedMessage id="single-group-modal.member-form.back" />
            </Button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};
