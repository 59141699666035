import { useDefaultClaim, useUserId, useUserIdLoading } from '@modules/auth';
import { useFacility } from '@modules/facility';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

export function useAdminRedirects() {
  const router = useRouter();

  const [loading, setLoading] = useState(true);

  const userId = useUserId();
  const facility = useFacility();
  const userIdLoading = useUserIdLoading();
  const defaultClaim = useDefaultClaim();
  const isEligible = useMemo(
    () =>
      defaultClaim?.role === 'admin' ||
      defaultClaim?.role === 'employee' ||
      defaultClaim?.role === 'private-player',
    [defaultClaim?.role],
  );

  useEffect(() => {
    if (userIdLoading) return;
    if (!userId || !isEligible) {
      router.push('/login');
    }

    if (
      facility?.type === 'group' &&
      router.pathname.includes('booking-calendar')
    ) {
      router.push(`/dashboard/${defaultClaim?.role}/groups`);
    }

    if (facility?.type === 'sport' && router.pathname.includes('groups')) {
      router.push(`/dashboard/${defaultClaim?.role}/booking-calendar`);
    }

    if (facility) setLoading(false);
  }, [userIdLoading, userId, facility, defaultClaim]);

  return {
    loading: userIdLoading || loading,
  };
}
