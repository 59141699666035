import { SerializedStyles } from '@emotion/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { languageSwitcherStyles as styles } from './LanguageSwitcher.styles';

const CheckIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/check_big.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  isMobile: boolean;
}

export const LanguageSwitcher: React.FC<Props> = ({ isMobile }) => {
  const { locales, locale: currentLocale, asPath } = useRouter();
  const { formatMessage } = useIntl();

  return (
    <ul css={[styles.list, isMobile && styles.mobile]}>
      {locales?.map((locale) => (
        <li css={[styles.item]} key={locale}>
          <Link
            href={asPath}
            locale={locale}
            css={[
              styles.link,
              currentLocale === locale ? styles.itemActive : styles.notActive,
            ]}
          >
            {formatMessage({ id: `language.${locale}` })}
            {currentLocale === locale && (
              <div css={styles.checkIcon}>
                <CheckIcon viewBox="0 6 24 5" />
              </div>
            )}
          </Link>
        </li>
      ))}
    </ul>
  );
};
