import { RevenueGraphOption } from '@modules/analytics';
import { useClickOutsideElement } from '@shared/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowIconDown from 'public/icons/arrow-icon-down-16.svg';
import ArrowIconUp from 'public/icons/arrow-icon-up-16.svg';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { revenueGraphSelectStyles as styles } from './revenueGraphSelectStyles';

interface Props {
  selectedOption: string;
  onSetSelectedOption: (option: string) => void;
}

/**@markoboras0712 internationalize this */
const REVENUE_OPTIONS = [
  'revenue.graph-results.currentYear',
  'revenue.graph-results.pastYear',
  'revenue.graph-results.currentMonth',
  'revenue.graph-results.pastMonth',
];

export const RevenueGraphSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const { formatMessage } = useIntl();
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const optionsContainerRef = useClickOutsideElement<HTMLDivElement>(() =>
    setIsSelectOpen(false),
  );

  return (
    <>
      <div css={styles.selectWrapper}>
        <p css={styles.showLabel}>
          <FormattedMessage id="revenue.graph.select.label" />
        </p>

        <div onClick={() => setIsSelectOpen(!isSelectOpen)} css={styles.select}>
          <span css={styles.selectedOption}>{selectedOption}</span>
          {isSelectOpen ? <ArrowIconDown /> : <ArrowIconUp />}
        </div>
      </div>
      <AnimatePresence>
        {isSelectOpen && (
          <motion.div
            css={styles.recentResultsContainer}
            ref={optionsContainerRef}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.15, ease: 'easeInOut' }}
          >
            <span css={styles.recentResultsTitle}>
              <FormattedMessage id="revenue.graph.select.dropdown.label" />
            </span>
            <motion.div css={styles.daysContainer}>
              {REVENUE_OPTIONS.map((optionId, index) => {
                const optionLabel = formatMessage({ id: optionId });
                return (
                  <RevenueGraphOption
                    key={index}
                    option={optionLabel}
                    isActive={optionLabel === selectedOption}
                    onSetSelectedOption={() => onSetSelectedOption(optionLabel)}
                    onClose={() => setIsSelectOpen(false)}
                  />
                );
              })}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
