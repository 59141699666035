import { QueryDocumentSnapshot } from 'firebase/firestore';

/**
 * Represents a Firestore converter that can convert between custom data types
 * and Firestore documents.
 * @template T The custom data type.
 */
interface FirestoreConverter<T> {
  /**
   * @param data The custom data to convert.
   * @returns The converted document data.
   */
  toFirestore: (data: T) => DocumentData;

  /**
   * @param snapshot The document snapshot to convert.
   * @returns The converted custom data.
   */
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => T;
}

/**
 * @returns The new converter instance.
 */
export function createFirestoreConverter<T>(): FirestoreConverter<T> {
  return {
    /**
     * Converts a custom data type to a Firestore document data type by copying
     * all properties of the original data object.
     */
    toFirestore: (data: T) => ({ ...data }),

    /**
     * Converts a Firestore document snapshot to a custom data type by returning
     * the data property of the snapshot.
     */
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) =>
      snapshot.data() as T,
  };
}
