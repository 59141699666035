import { SerializedStyles } from '@emotion/react';
import { AdminDashboard } from '@modules/admin';
import { useAddMember } from '@modules/users';
import { useModal, useSubmitOnEnter } from '@shared/hooks';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SingleValue } from 'react-select';
import { toast } from 'sonner';
import { AddRoleModal } from '../add-role-modal';
import { FacilitySelect } from '../facility-select';
import { RoleOption, RoleSelect } from '../role-select';
import { styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

export const PlayOffManagementLayout: React.FC = () => {
  const { formatMessage } = useIntl();
  const ref = useSubmitOnEnter<HTMLButtonElement>();

  const [email, setEmail] = useState('');
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<RoleOption> | null>(null);
  const [selectedFacility, setSelectedFacility] = useState<
    Facility | undefined
  >(undefined);

  const { handleFacilityRole } = useAddMember();
  const { closeModal, isModalOpen, openModal } = useModal();

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  async function handleModal() {
    if (!email) {
      toast.error(
        formatMessage({ id: 'playoff-management-layout.toast-email-error' }),
      );
      return;
    }
    if (!selectedOption) {
      toast.error(
        formatMessage({ id: 'playoff-management-layout.toast-role-error' }),
      );
      return;
    }
    if (!selectedFacility?.id) {
      toast.error(
        formatMessage({ id: 'playoff-management-layout.toast-facility-error' }),
      );
      return;
    }

    openModal();
  }

  async function handleSubmit() {
    await handleFacilityRole({
      email,
      selectedOption,
      selectedFacility: selectedFacility?.id,
    });

    setEmail('');
    setSelectedFacility(undefined);
    setSelectedFacility(null);
    closeModal();
  }

  return (
    <AdminDashboard
      title={formatMessage({ id: 'playoff-management-layout.title' })}
      description={formatMessage({
        id: 'playoff-management-layout.description',
      })}
      containerStyles={styles.adminMain}
      mainStyles={styles.playOffManagementMain}
    >
      <motion.div
        style={{
          opacity: 0,
          y: 8,
        }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
        css={styles.formStyles}
      >
        <FormInput
          type="email"
          label={formatMessage({ id: 'login-form.email.label' })}
          id="email"
          isRequired
          name="email"
          Icon={Mail}
          placeholder={formatMessage({ id: 'login-form.email.placeholder' })}
          onChange={handleEmailChange}
        />
        <RoleSelect
          selectedOption={selectedOption}
          onSetSelectedOption={setSelectedOption}
        />
        <FacilitySelect onSetSelectedFacility={setSelectedFacility} />

        <Button
          type="button"
          customStyles={styles.formButton}
          ref={ref}
          onClick={() => handleModal()}
        >
          <FormattedMessage id="playoff-management-layout.add-user" />
        </Button>
      </motion.div>

      {isModalOpen && selectedFacility && email && selectedOption && (
        <AddRoleModal
          email={email}
          selectedFacility={selectedFacility}
          selectedOption={selectedOption}
          closeModal={closeModal}
          handleSubmit={handleSubmit}
        />
      )}
    </AdminDashboard>
  );
};
