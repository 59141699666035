import { useFacilityRoles } from '@modules/facility';
import { OpponentOption } from '../components';

export function useOpponentSelectOptions() {
  const facilityRoles = useFacilityRoles();

  const opponentOptions: OpponentOption[] = facilityRoles?.map((role) => {
    const label = role.displayName
      ? `${role.displayName} (${role.email})`
      : role.email;
    return {
      label,
      value: {
        name: role.displayName || '',
        email: role.email,
      },
    };
  });

  return { opponentOptions };
}
