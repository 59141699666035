import FacilityIcon from 'public/icons/facility-16.svg';
import { GroupBase, SingleValueProps } from 'react-select';
import { FacilityOption } from '../models';
import { styles } from './styles';

export const CustomSelectValue = (
  props: SingleValueProps<FacilityOption, boolean, GroupBase<FacilityOption>>,
) => (
  <div css={styles.valueContainer}>
    <FacilityIcon />
    <span css={styles.placeholder}>{props.data.label}</span>
  </div>
);
