import { selector, useRecoilValue } from 'recoil';
import { getCurrentMonthYear } from '../utils';
import { groupAnalytics } from './atoms';

/* Current year revenue */
const groupCurrentYearRevenue = selector({
  key: 'groupCurrentYearRevenue',
  get: ({ get }) => {
    const analytics: GroupAnalytics = get(groupAnalytics);

    const { currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const currentYearBookings = analytics?.groupsData?.filter((booking) => {
      return new Date(booking.date).getFullYear() === currentYear;
    });

    currentYearBookings?.forEach(({ totalIncome, date }) => {
      const month = new Date(date).getMonth();
      revenueByMonth[month] += totalIncome;
    });

    return revenueByMonth;
  },
});

const groupLastYearRevenue = selector({
  key: 'groupLastYearRevenue',
  get: ({ get }) => {
    const analytics: GroupAnalytics = get(groupAnalytics);
    const { currentYear } = getCurrentMonthYear();
    const revenueByMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    const lastYearBookings = analytics?.groupsData?.filter((booking) => {
      const bookingDate = new Date(booking.date);
      return bookingDate.getFullYear() === currentYear - 1;
    });

    lastYearBookings?.forEach(({ totalIncome, date }) => {
      const month = new Date(date).getMonth();
      revenueByMonth[month] += totalIncome;
    });

    return revenueByMonth;
  },
});

/* Revenue graph */
export const useGroupCurrentYearRevenue = () =>
  useRecoilValue(groupCurrentYearRevenue);
export const useGroupLastYearRevenue = () =>
  useRecoilValue(groupLastYearRevenue);
