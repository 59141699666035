import { css } from '@emotion/react';

export const display = {
  none: css`
    display: none;
  `,
  block: css`
    display: block;
  `,
  inline: css`
    display: inline;
  `,
  inlineBlock: css`
    display: inline-block;
  `,
  flex: css`
    display: flex;
  `,
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  inlineFlex: css`
    display: inline-flex;
  `,
  grid: css`
    display: grid;
  `,
  table: css`
    display: table;
  `,
};
