import { css } from '@emotion/react';
import { button } from '@styles/button.styles';

const flex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const buttonContainer = css`
  justify-content: flex-start;
`;

const title = css`
  color: var(--color-text-6);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const buttonClose = css`
  padding: 12px;
  margin-right: -12px;
  margin-top: -12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const name = css`
  color: var(--color-text-6);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const membershipDate = css`
  color: var(--color-text-4);
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 32px;

  span {
    font-size: 16px;
    color: var(--color-text-5);
  }
`;

const buttonConfirm = css`
  ${button.primary}
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  background: var(--color-general-primary);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled {
    color: white;
    cursor: not-allowed;
  }
`;

const buttonCancel = css`
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  background-color: var(--color-warning-utility);

  :disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
`;

export const styles = {
  title,
  buttonClose,
  flex,
  name,
  membershipDate,
  buttonConfirm,
  buttonCancel,
  buttonContainer,
};
