import { css } from '@emotion/react';
import { scroll } from '@styles/scroll.styles';

const base = css`
  position: relative;
`;

const activeItem = css`
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  display: flex;
  align-items: center;
  min-width: max-content;
  color: var(--color-text-5);
`;

const items = css`
  ${scroll.base}
  width: max-content;
  left: 0;
  background: var(--color-background-4);
  border: 1px solid rgba(248, 248, 248, 0.08);
  box-shadow: 0px -4px 32px rgba(20, 19, 21, 0.44),
    0px 14px 32px rgba(20, 19, 21, 0.44);
  border-radius: 6px;
  padding: 16px 0;
  position: absolute;
  top: 32px;
  left: 50%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const list = css`
  ${scroll.base}
`;

const label = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-4);
  background: var(--color-background-4);
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const listItem = css`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
  color: var(--color-text-5);

  &:hover {
    color: #fff;
  }
`;

const listItemActive = css`
  ${listItem}
  color: var(--color-general-primary);
`;

export const styles = {
  base,
  activeItem,
  items,
  label,
  listItem,
  listItemActive,
  list,
};
