import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

export class FirebaseService {
  private static Instance = initializeApp({
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  });

  public static functions = getFunctions(this.Instance, 'europe-west2');

  public static firestore = getFirestore(this.Instance);

  public static storage = getStorage(this.Instance);

  public static auth = getAuth(this.Instance);
}

if (process.env.NEXT_PUBLIC_FIREBASE_ENV === 'local') {
  // connectAuthEmulator(FirebaseService.auth, 'http://localhost:9099', {
  //   disableWarnings: true,
  // });
  // connectFirestoreEmulator(FirebaseService.firestore, 'localhost', 8080);
  // connectFunctionsEmulator(FirebaseService.functions, 'localhost', 5003);
  // connectStorageEmulator(FirebaseService.storage, 'localhost', 9199);
}
