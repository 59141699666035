import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  padding: 0;

  @media ${breakpoints.toMed} {
    overflow: hidden;
  }
`;

const authContainer = css`
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px;
  color: var(--color-text-5);
  min-height: 60vh;
  justify-content: center;

  @media ${breakpoints.fromMed} {
    ${grid.basic50col};
    grid-auto-rows: auto;
    align-items: center;
    min-height: calc(100vh - 310px);
    max-width: 100%;
    padding: 0;
  }
`;

export const layoutStyles = { base, authContainer };
