import { useDefaultClaim } from '@modules/auth';
import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useEditDisplayName() {
  const defaultClaim = useDefaultClaim();
  const { formatMessage } = useIntl();
  const updateDisplayNameCallable =
    useFunction<UpdateDisplayNameData>('updateDisplayName');

  async function editDisplayName({
    displayName,
    email,
    facilityId,
  }: UpdateDisplayNameData) {
    if (!updateDisplayNameCallable) return;
    try {
      await updateDisplayNameCallable({
        email,
        facilityId,
        userRole: defaultClaim.role,
        displayName,
      });
      toast.success(formatMessage({ id: 'toasts.users.edit-display-name' }));
    } catch {
      toast.error(
        formatMessage({ id: 'toasts.users.edit-display-name.error' }),
      );
    }
  }

  return {
    editDisplayName,
  };
}
