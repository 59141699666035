import { css } from '@emotion/react';

const wrapper = css`
  position: sticky;
  top: 20px;
  grid-column: 5 / span 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 480px;
  padding: 20px 16px;
  background-color: var(--color-background-4);
  border-radius: 8px;
  height: max-content;
  max-width: 300px;
`;

const title = css`
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
  margin-bottom: 20px;
`;

const bookedHourList = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
`;

const divider = css`
  padding-top: 16px;
`;

const checkboxWrapper = css`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  padding-top: 20px;
`;

const repeatText = css`
  color: var(--color-text-6);
  font-size: 14px;
  line-height: 16px;
`;

const durationText = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 12px;
  color: var(--color-text-5);
`;

const bookingRepeatDropdown = css`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  position: relative;
`;

const bookingRepeatLabel = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-4);
  background: var(--color-background-4);
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const select = css`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-text6);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const selectedOption = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-general-primary);
`;

const checkbox = css`
  position: relative;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-4);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }
`;

const checkboxActive = css`
  ${checkbox};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid var(--color-general-primary);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const buttonWrapper = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-top: 20px;

  button {
    width: 50%;
    min-width: max-content;
  }
`;

const buttonConfirm = css`
  padding: 8px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;

  :disabled {
    cursor: default;
    opacity: 0.4;
  }
`;

const buttonConfirmDesctructive = css`
  ${buttonConfirm};
  background-color: var(--color-error-utility);
  color: var(--color-text-6);
`;

const cancel = css`
  border: none;
  cursor: pointer;
  color: var(--color-text-5);
  background-color: transparent;
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;

  :disabled {
    opacity: 0.4;
    cursor: pointer;
  }
`;

export const styles = {
  wrapper,
  title,
  bookedHourList,
  checkboxWrapper,
  repeatText,
  durationText,
  bookingRepeatDropdown,
  bookingRepeatLabel,
  select,
  selectedOption,
  checkbox,
  checkboxActive,
  buttonWrapper,
  buttonConfirm,
  buttonConfirmDesctructive,
  cancel,
  divider,
};
