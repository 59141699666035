import { useIntl } from 'react-intl';

export function useGetRevenueData() {
  const { formatMessage } = useIntl();

  const getRevenueData = ({
    option,
    currentYearRevenue,
    lastYearRevenue,
    currentMonthRevenue,
    pastMonthRevenue,
  }: RevenueGraphData) => {
    switch (option) {
      case formatMessage({ id: 'revenue.graph-results.currentYear' }):
        return currentYearRevenue;
      case formatMessage({ id: 'revenue.graph-results.pastYear' }):
        return lastYearRevenue;
      case formatMessage({ id: 'revenue.graph-results.currentMonth' }):
        return currentMonthRevenue;
      case formatMessage({ id: 'revenue.graph-results.pastMonth' }):
        return pastMonthRevenue;
      default:
        return [];
    }
  };

  function getInterval(option: string) {
    const isCurrentYear =
      option === formatMessage({ id: 'revenue.graph-results.currentYear' });
    const isPastYear =
      option === formatMessage({ id: 'revenue.graph-results.pastYear' });
    if (isCurrentYear || isPastYear) {
      return 'yearly';
    }

    return 'monthly';
  }

  return { getRevenueData, getInterval };
}
