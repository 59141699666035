import { useUserId } from '@modules/auth';
import { useFacility } from '@modules/facility';
import { FirebaseService } from '@modules/firebase';
import { useCurrentLocale } from '@modules/translations';
import { addWeeks, format } from 'date-fns';
import { collection, doc, writeBatch } from 'firebase/firestore';
import { useMemo } from 'react';
import { toast } from 'sonner';
import {
  useBookings,
  useCurrentBookedHours,
  useIsRepeatChecked,
  useRepeatDuration,
  useResetCurrentBookedHours,
  useSetIsLoading,
} from '../state';

import {
  generateSmartLockCode,
  getBookingConflict,
  getMinutesBetween,
  getPaintballPrice,
  mergeBookings,
} from '../utils';
import { useBookingHelpers } from './useBookingHelpers';

interface BookingInformation {
  name: string;
  opponent: Opponent | null;
  isTournament: boolean;
  isTeamGame: boolean;
  email?: string;
  phoneNumber?: string;
  comment?: string | null;
  selectedPackagedBookingData: SelectedPackagedBookingData | null;
}

export function useSubmitBooking() {
  const allCurrentBookedHours = useCurrentBookedHours();
  const allBookings = useBookings();
  const userId = useUserId();
  const currentFacility = useFacility();
  const isRepeatChecked = useIsRepeatChecked();
  const repeatDuration = useRepeatDuration();
  const isSmartLockAvailable = useMemo(
    () => currentFacility?.isSmartLockAvailable,
    [currentFacility],
  );
  const acceptedBookings = useMemo(
    () => allBookings.filter(({ status }) => status === 'accepted'),
    [allBookings],
  );
  const discountConfig = useMemo(
    () => currentFacility?.discountConfig,
    [currentFacility],
  );
  const maxSlotsInBooking = useMemo(
    () => currentFacility?.maxSlotsInBooking,
    [currentFacility],
  );
  const minSlotsInBooking = useMemo(
    () => currentFacility?.minSlotsInBooking,
    [currentFacility],
  );
  const mergedBookedHours = useMemo(
    () =>
      Boolean(allCurrentBookedHours.length)
        ? mergeBookings(
            allCurrentBookedHours,
            discountConfig,
            maxSlotsInBooking,
            minSlotsInBooking,
          )
        : [],
    [
      allCurrentBookedHours,
      discountConfig,
      maxSlotsInBooking,
      minSlotsInBooking,
    ],
  );
  const { convertToDate, formatHour } = useBookingHelpers();
  const resetAllCurrentBookedHours = useResetCurrentBookedHours();
  const setIsLoading = useSetIsLoading();
  const { getLocale } = useCurrentLocale();

  async function handleSubmitBooking({
    name,
    opponent,
    isTournament,
    isTeamGame,
    comment,
    email,
    phoneNumber,
    selectedPackagedBookingData,
  }: BookingInformation) {
    let repeatId = null;
    let successfulBookingsCount = 0;
    const batch = writeBatch(FirebaseService.firestore);

    setIsLoading(true);

    for (const bookedHours of mergedBookedHours) {
      const code = generateSmartLockCode(5);
      const bookingsRef = doc(
        collection(FirebaseService.firestore, 'bookings'),
      );
      const smartLockCodeRef = doc(
        FirebaseService.firestore,
        'smartLockCodes',
        code,
      );
      const price = selectedPackagedBookingData
        ? getPaintballPrice(
            selectedPackagedBookingData.package,
            selectedPackagedBookingData.numberOfUsers,
            getMinutesBetween(bookedHours),
          )
        : bookedHours.price;

      const booking = {
        name: name || 'Vlasnik',
        email: email || null,
        userId: userId || null,
        phoneNumber: phoneNumber || '',
        startTime: bookedHours.from,
        endTime: bookedHours.to,
        price,
        facilityId: currentFacility.id,
        facilityName: currentFacility.name,
        courtId: bookedHours.courtId,
        courtName: bookedHours.courtName,
        status: 'accepted',
        isRepeat: isRepeatChecked,
        repeatId,
        isFirstRepeat: false,
        type: 'upcoming',
        repeatDuration: 0,
        canceledBy: null,
        createdBy: 'admin',
        isTournament,
        comment: comment || null,
        isTeamGame,
        opponent,
        isBookingNotificationSent: false,
        selectedPackagedBookingData,
        createdAt: +new Date(),
        smartLockCode: isSmartLockAvailable ? code : null,
        discountsApplied: bookedHours?.discountsApplied || [],
      };

      const bookingConflict = getBookingConflict({
        from: bookedHours.from,
        to: bookedHours.to,
        currentCourtId: bookedHours.courtId,
        currentFacility,
        acceptedBookings,
      });

      if (Boolean(bookingConflict)) {
        toast.error(
          `Termin ${format(bookingConflict.startTime, 'EEEE, d. MMMM', {
            locale: getLocale(),
          })} ${formatHour(
            convertToDate(bookingConflict.startTime),
          )} - ${formatHour(
            convertToDate(bookingConflict.endTime),
          )} je već rezerviran! `,
        );
      } else {
        if (isSmartLockAvailable) {
          batch.set(smartLockCodeRef, { bookings: [booking] });
        }
        batch.set(bookingsRef, booking);
        successfulBookingsCount++;
      }

      if (isRepeatChecked) {
        const repeatBookings = [];
        for (let i = 0; i < repeatDuration; i++) {
          if (i == 0) {
            repeatId = bookingsRef.id;
            const firstRepeatBooking = {
              ...booking,
              isFirstRepeat: true,
              repeatDuration,
              repeatId,
            };

            batch.update(bookingsRef, firstRepeatBooking);
            repeatBookings.push(firstRepeatBooking);

            continue;
          }

          const newStartTime = addWeeks(bookedHours.from, i).getTime();
          const newEndTime = addWeeks(bookedHours.to, i).getTime();

          const repeatBooking = {
            ...booking,
            startTime: newStartTime,
            endTime: newEndTime,
            repeatId,
          };

          const bookingConflict = getBookingConflict({
            from: newStartTime,
            to: newEndTime,
            currentCourtId: booking.courtId,
            currentFacility,
            acceptedBookings,
          });

          if (Boolean(bookingConflict)) {
            toast.error(
              `Termin ${format(bookingConflict.startTime, 'EEEE, d. MMMM', {
                locale: getLocale(),
              })} ${formatHour(
                convertToDate(bookingConflict.startTime),
              )} - ${formatHour(
                convertToDate(bookingConflict.endTime),
              )} je već rezerviran! `,
            );
            continue;
          }

          repeatBookings.push(repeatBooking);

          if (isSmartLockAvailable && i === repeatDuration - 1) {
            batch.update(smartLockCodeRef, {
              bookings: repeatBookings,
            });
          }
          const repeatBookinsRef = doc(
            collection(FirebaseService.firestore, 'bookings'),
          );
          batch.set(repeatBookinsRef, repeatBooking);
          successfulBookingsCount++;
        }
      }
    }

    if (successfulBookingsCount === 0) {
      setIsLoading(false);
      return;
    }

    try {
      await batch.commit();
      toast.success(
        successfulBookingsCount === 1
          ? `Uspješno ste rezervirali termin!`
          : `Uspješno ste rezervirali termine!`,
      );
    } catch (e) {
      toast.error('Greška prilikom spremanja termina!');
    } finally {
      resetAllCurrentBookedHours();
      setIsLoading(false);
    }
  }

  return { handleSubmitBooking };
}
