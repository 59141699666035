import { useEffect } from 'react';

export function useNoScroll(isOpen: boolean) {
  useEffect(() => {
    if (!isOpen) return;

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);
}
