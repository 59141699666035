import Fuse from 'fuse.js';

/**
 * Uses Fuse.js to perform a fuzzy search on an array of objects.
 *
 * @template T The type of the objects in the array. Must extend object.
 * @param {string[]} keys The properties of the objects to be included in the search.
 * @param {string} [query] The search query. If not provided, the original array is returned.
 * @param {T[]} [documents] The array of objects to search in. If not provided, an empty array is returned.
 * @returns {T[]} An array of objects that match the search query.
 *
 */
export function useFuseObjectSearch<T extends object>(
  keys: string[],
  query?: string,
  documents?: T[],
): T[] {
  if (!documents) return [];
  if (!query) return documents;

  const options = {
    keys,
    threshold: 0.5,
  };

  const fuse = new Fuse(documents, options);
  return fuse.search(query).map((result) => result.item);
}
