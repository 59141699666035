import { useFunction } from '@modules/firebase';

export const useResetPassword = () => {
  const sendForgotPasswordEmailCallable = useFunction<ForgotPasswordData>(
    'sendForgotPasswordEmail',
  );

  async function sendForgotPasswordEmail({ email }: ForgotPasswordData) {
    if (!sendForgotPasswordEmailCallable) return;
    await sendForgotPasswordEmailCallable({
      email,
    });
  }

  return {
    sendForgotPasswordEmail,
  };
};
