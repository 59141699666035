/* eslint-disable react/display-name */
import { SerializedStyles } from '@emotion/react';
import { ForwardedRef, MouseEventHandler, ReactNode, forwardRef } from 'react';
import { buttonStyle as styles } from './Button.styles';

type Props = {
  id?: string;
  children?: ReactNode;
  style?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'normal' | 'large';
  type?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  customStyles?: SerializedStyles | SerializedStyles[];
  tabIndex?: number;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
};

export const Button = forwardRef(
  (
    {
      children,
      disabled = false,
      id,
      style = 'primary',
      size = 'medium',
      type = 'button',
      customStyles,
      tabIndex,
      onClick,
      onMouseEnter,
      onMouseLeave,
    }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    return (
      <button
        id={id}
        type={type}
        ref={ref}
        disabled={disabled}
        css={[
          styles.reset,
          styles.button,
          styles.rounded,
          styles[style],
          styles[size],
          customStyles,
        ]}
        tabIndex={tabIndex}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </button>
    );
  },
);
