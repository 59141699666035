import { ChartOptions, ScriptableContext } from 'chart.js';
import { RESERVATION_GRAPH_LABELS } from '../const';
import {
  useCurrentMonthNumberOfReservations,
  useLastWeekNumberOfReservations,
} from '../state';

export const getReservationGraphOptions = (): ChartOptions<'line'> => ({
  responsive: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      displayColors: false,
      backgroundColor: '#2A343C',
      titleColor: '#D0DDE7',
      bodyColor: '#D0DDE7',
      titleFont: { family: 'GTPressura', size: 11, weight: '400' },
      bodyFont: { family: 'GTPressura', size: 11, weight: '400' },
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        color: 'transparent',
      },
      ticks: {
        color: '#61645E',
        font: {
          size: 14,
          weight: '300',
          family: 'HK Grotesk',
          lineHeight: 1.25,
        },
      },
    },
    y: {
      type: 'linear',
      grid: {
        color: '#61645E',
      },
      beginAtZero: true,
      min: 0,
      border: {
        dash: [4, 2],
      },
      ticks: {
        callback: (value: number) => {
          if (value === 0) return value.toString();

          if (value >= 20 && value <= 2000 && value % 5 === 0) {
            return value.toString();
          }

          if (value < 20 && value % 1 !== 0) {
            return Math.round(value).toString();
          }

          if (value < 20 && value % 1 === 0) return value.toString();
        },
        font: {
          size: 14,
          weight: '300',
          lineHeight: 1.25,
          family: 'HK Grotesk',
        },
        count: 5,
      },
    },
  },
});

export function getReservationGraphData(
  view: 'monthly' | 'weekly',
  locale: string,
) {
  const currentMonthNumberOfReservations =
    useCurrentMonthNumberOfReservations();
  const lastWeekNumberOfReservations = useLastWeekNumberOfReservations();
  const data =
    view === 'weekly'
      ? lastWeekNumberOfReservations
      : currentMonthNumberOfReservations;
  const localizedLabels = RESERVATION_GRAPH_LABELS[locale][view];

  return {
    labels: localizedLabels,
    datasets: [
      {
        data,
        borderColor: '#ACEF34',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(-1000, 0, 1000, 200);
          gradient.addColorStop(0.03, 'rgba(133, 185, 40, 0.9)');
          gradient.addColorStop(0.5365, 'rgba(177, 211, 125, 0.4)');
          gradient.addColorStop(0.9203, 'rgba(211, 232, 190, 0.1)');

          return gradient;
        },
        fill: 'start',
      },
    ],
  };
}
