import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
);

interface Props {
  reservationsView: 'monthly' | 'weekly';
  onSetReservationsView: (view: 'monthly' | 'weekly') => void;
}

export const ReservationsGraphSelect: React.FC<Props> = ({
  reservationsView,
  onSetReservationsView,
}) => {
  return (
    <div css={styles.reservationsGraphHeader}>
      <span css={styles.reservationNumber}>
        <FormattedMessage id="reservations.graph.label" />
      </span>
      <div css={[styles.monthSwitcher, styles.reservationGraphSwitcher]}>
        <span
          css={[
            reservationsView === 'monthly'
              ? styles.currentMonth
              : styles.pastMonth,
          ]}
          onClick={() => onSetReservationsView('monthly')}
        >
          <FormattedMessage id="reservations.graph.monthly.label" />
        </span>
        <span
          css={[
            reservationsView === 'weekly'
              ? styles.currentMonth
              : styles.pastMonth,
          ]}
          onClick={() => onSetReservationsView('weekly')}
        >
          <FormattedMessage id="reservations.graph.weekly.label" />
        </span>
      </div>
    </div>
  );
};
