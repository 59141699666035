import { addWeeks } from 'date-fns';

/**
 * Gets a formatted date string and its repeated interval, both properly capitalized,
 * according to the specified locale.
 *
 * @param {Booking} data - Booking data containing the start time and repeat duration.
 * @param {string} locale - Locale code to format the date string.
 * @returns {Object} Object containing both the initial and repeated capitalized day strings.
 */
export function getRepeatedCapitalizedDay(data: Booking, locale: string) {
  const localeMap = {
    hr: 'hr-HR',
    sr: 'sr-Latn-RS',
    bo: 'sr-Latn-RS',
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    de: 'de-DE',
    pt: 'pt-PT',
    it: 'it-IT',
  };

  const effectiveLocale = localeMap[locale] || locale;
  const date = new Date(data.startTime);
  const formattedDate = formatLocaleDate(date, effectiveLocale);
  const capitalizedDay = capitalize(formattedDate);
  const repeatEndDate = addWeeks(date, data.repeatDuration - 1);
  const repeatedFormattedDate = formatLocaleDate(
    repeatEndDate,
    effectiveLocale,
  );
  const repeatedCapitalizedDay = `${capitalizedDay} - ${capitalize(
    repeatedFormattedDate,
  )}`;

  return { repeatedCapitalizedDay, capitalizedDay };
}

/**
 * Formats a date according to the given locale.
 *
 * @param {Date} date - The date to format.
 * @param {string} locale - The locale code for formatting.
 * @returns {string} The formatted date string.
 */
function formatLocaleDate(date: Date, locale: string): string {
  return date.toLocaleDateString(locale, {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} str - String to capitalize.
 * @returns {string} Capitalized string.
 */
function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
