import { useBookedHoursList, useIsDestructiveMode } from '@modules/booking-new';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BookedListItems } from '../booked-list-items/BookedListItems';
import { styles } from './bookingDaySummary.styles';
import { BookingSummaryButtons } from './buttons/BookingSummaryButtons';
import { Repeat } from './repeat/Repeat';

export const BookingDaySummary: FC = () => {
  const isDestructive = useIsDestructiveMode();
  const { sortedList } = useBookedHoursList();

  return (
    <div css={styles.wrapper}>
      <div>
        <p css={styles.title}>
          <FormattedMessage id="booking-summary.selected-slots" />
          {` (${sortedList.length})`}
        </p>
        <BookedListItems />
      </div>
      <div css={styles.divider}>
        {!isDestructive && <Repeat />}
        <BookingSummaryButtons />
      </div>
    </div>
  );
};
