import { useIsPlayOffAdmin } from '@modules/auth';
import {
  facilityFirestoreConverter,
  useSetCurrentFacilityId,
  useSetFacilities,
} from '@modules/facility';
import { FirebaseService, getTypedCollectionDocument } from '@modules/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

/**This hook is used to fetch all playoff facilities for playoff-admin */
export function usePlayoffFacilitiesListener() {
  const [loading, setLoading] = useState(true);

  const setFacilities = useSetFacilities();
  const isPlayOffAdmin = useIsPlayOffAdmin();
  const setCurrentFacilityId = useSetCurrentFacilityId();

  useEffect(() => {
    if (!isPlayOffAdmin) return;

    const facilitiesCollection = collection(FirebaseService.firestore, 'facilities').withConverter(
      facilityFirestoreConverter,
    );

    const unsubscribe = onSnapshot(
      facilitiesCollection,
      (snapshot) => {
        const docs = snapshot.docs.flatMap((doc) =>
          getTypedCollectionDocument<Facility[]>(doc),
        );

        docs.length ? setFacilities(docs) : setFacilities([]);
        setCurrentFacilityId(docs[0]?.id);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
    );

    return () => unsubscribe();
  }, []);

  return { loading };
}
