import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { DEFAULT_LANGUAGE } from '../const';
import { useCurrentTranslations } from '../hooks';

export const TranslationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { locale, defaultLocale } = useRouter();
  const currentTranslations = useCurrentTranslations(locale);

  const config = {
    messages: currentTranslations,
    locale: locale || DEFAULT_LANGUAGE,
    defaultLocale,
  };

  return <IntlProvider {...config}>{children}</IntlProvider>;
};
