import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

export function getTypedDocument<T>(
  snapshot: DocumentSnapshot<unknown>,
): T | undefined {
  if (!snapshot.exists()) return undefined;
  const doc = {
    ...(snapshot.data() as DocumentData),
  } as unknown as T;
  return doc;
}

export function getTypedCollectionDocument<T>(
  snapshot: QueryDocumentSnapshot<DocumentData>,
) {
  const doc = { ...snapshot.data(), id: snapshot.id } as unknown as T;
  return doc;
}
