import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const wrapper = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 12px;
  }

  @media ${breakpoints.toMed} {
    svg {
      display: none;
    }
  }
`;

const icon = css`
  position: absolute;
  left: 17px;
  top: 14px;
`;

const placeholder = css`
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  &:hover {
    background-color: 'transparent';
  }
`;

const valueContainer = css`
  display: flex;
  gap: 8px;
  color: white;

  svg {
    margin-right: 6px;
  }
`;

export const styles = {
  wrapper,
  placeholder,
  valueContainer,
  icon,
};
