import {
  getGroupRevenueGraphOptions,
  getGroupsRevenueGraphData,
  getRevenueGraphOption,
  useGetRevenueData,
} from '@modules/analytics';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
);

interface Props {
  revenueGraphOption: string;
}

export const GroupRevenueGraph: React.FC<Props> = ({ revenueGraphOption }) => {
  const { locale } = useRouter();
  const option = getRevenueGraphOption(revenueGraphOption);
  const { getInterval } = useGetRevenueData();
  const interval = useMemo(() => getInterval(option), [option]);

  return (
    <Line
      data={getGroupsRevenueGraphData(option, locale, interval)}
      options={getGroupRevenueGraphOptions()}
    />
  );
};
