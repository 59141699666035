import { useEffect, useState } from 'react';

interface UseModalHook {
  isModalOpen: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
  guardModalClose: (onClose: VoidFunction, message: string) => void;
}

export function useModal(): UseModalHook {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  function guardModalClose(onClose: VoidFunction, message: string) {
    const modalGuardMessage = confirm(message);
    if (modalGuardMessage) onClose();
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isModalOpen]);

  return {
    isModalOpen,
    openModal,
    closeModal,
    guardModalClose,
  };
}
