import {
  getHotspotsGraphData,
  getHotspotsGraphOptions,
  useAnalyticsSelector,
  useBarGraphOptions,
} from '@modules/analytics';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Filler,
);

interface Props {
  hotspotsGraphOption: string;
}

export const HotspotsGraph: React.FC<Props> = ({ hotspotsGraphOption }) => {
  const { hotspots } = useAnalyticsSelector();
  const options = useBarGraphOptions(
    hotspotsGraphOption,
    getHotspotsGraphOptions,
  );

  return (
    <Bar
      options={options}
      data={getHotspotsGraphData(hotspotsGraphOption, hotspots)}
      height={800}
      width={2000}
    />
  );
};
