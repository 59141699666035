import { css } from '@emotion/react';

const base = css`
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  color: var(--color-text-6);
  background-color: var(--color-background-3);
  border-radius: 8px;
`;

const name = css`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
`;

const trashButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  svg {
    flex-shrink: 0;
  }
`;

const content = css`
  width: 100%;
`;

const wrapper = css`
  display: flex;
  gap: 16px;
`;

const infoWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const date = css`
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
`;

const text = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-3);
`;

const membership = css`
  ${text};
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  padding: 0;
`;

const bullet = css`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  margin-left: 4px;
`;

const bulletRed = css`
  ${bullet};
  background-color: var(--color-error-utility);
`;

const bulletGreen = css`
  ${bullet};
  background-color: var(--color-general-primary);
`;

const bulletOrange = css`
  ${bullet};
  background-color: var(--color-warning-utility);
`;

const check = css`
  path {
    fill: #eb5757;
  }

  rect {
    stroke: #eb5757;
  }
`;

const buttonDelete = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  color: var(--color-text-6);
  background-color: var(--color-error-utility);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  svg {
    path {
      fill: var(--color-text-6);
    }
  }
`;

const buttonBack = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  color: var(--color-text-5);
  background-color: var(--color-background-4);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const styles = {
  base,
  content,
  name,
  trashButton,
  wrapper,
  infoWrapper,
  date,
  text,
  bullet,
  bulletRed,
  bulletGreen,
  bulletOrange,
  check,
  buttonDelete,
  buttonBack,
  membership,
};
