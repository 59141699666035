import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const container = css`
  ${grid.basic6col};
  color: var(--color-text-5);
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 1.4em;
  font-weight: 400;
  padding: 68px 16px;
  border-top: 1px solid #505050;

  @media ${breakpoints.fromMed} {
    ${grid.basic48col};
    padding: 68px 0 0;
    max-width: 100%;
  }

  @media ${breakpoints.fromXHuge} {
    max-width: 100%;
  }
`;

const leftSideWrapper = css`
  grid-column: 1 / span 5;

  @media ${breakpoints.fromMed} {
    grid-column: 4 / span 12;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 4 / span 9;
  }
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 12px;

  @media ${breakpoints.toSml} {
    margin-bottom: 60px;
  }
`;

const anchor = css`
  @media ${breakpoints.fromMed} {
    display: block;
    width: 108px;
    height: 22px;
  }
`;

const companyName = css`
  --color: var(--geist-foreground);
`;

const description = css`
  color: var(--color-text-5);
  font-size: 12px;
  line-height: 1.3em;
  margin-bottom: 24px;

  @media ${breakpoints.toSml} {
    display: none;
  }
`;
const socialsWrapper = css`
  display: flex;
  gap: 8px;
  margin-bottom: 100px;

  @media ${breakpoints.toSml} {
    display: none;
  }
`;
const socials = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-background-2);
  width: 40px;
  height: 40px;
  border-radius: 8px;

  &:hover {
    path {
      fill: var(--color-general-primary);
    }
  }
`;

const linksWrapper = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const footerList = css`
  @media ${breakpoints.fromLrg} {
    color: var(--color-text-5);

    li {
      position: relative;
      overflow: hidden;
    }

    a {
      position: relative;
      z-index: 1;
      display: inline-block;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: white;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease-out;
      }

      &:hover::before {
        transform: scaleX(1);
      }
    }
  }
`;

const footerLink = css`
  width: fit-content;
`;

const linkWrapperOne = css`
  ${linksWrapper};
  ${footerList};
  grid-column: 1 / span 3;

  @media ${breakpoints.fromMed} {
    grid-column: 23 / span 7;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 23 / span 6;
  }
`;

const linkWrapperTwo = css`
  ${linksWrapper};
  ${footerList};
  grid-column: 5 / span 3;

  @media ${breakpoints.fromMed} {
    grid-column: 31 / span 6;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 31 / span 5;
  }
`;

const linkWrapperThree = css`
  ${linksWrapper};
  ${footerList};
  grid-column: 1 / span 3;
  margin-top: 60px;

  @media ${breakpoints.fromMed} {
    grid-column: 38 / span 7;
    margin-top: 0;
  }

  @media ${breakpoints.fromXLrg} {
    grid-column: 38 / span 6;
  }
`;

const linkHeader = css`
  color: var(--color-text-3);
  font-size: 10px;
  line-height: 1.2em;
  letter-spacing: 0.3em;
  text-transform: uppercase;
`;

const storeImagesWrapper = css`
  display: flex;
  margin-bottom: 20px;
  gap: 12px;

  @media ${breakpoints.toSml} {
    display: none;
  }
`;

export const styles = {
  container,
  leftSideWrapper,
  anchor,
  companyName,
  description,
  socialsWrapper,
  socials,
  logoWrapper,
  linksWrapper,
  linkHeader,
  linkWrapperOne,
  linkWrapperTwo,
  linkWrapperThree,
  footerList,
  footerLink,
  storeImagesWrapper,
};
