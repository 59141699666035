import { useEffect } from 'react';

interface UseScrollIntoView {
  onFocus: (
    currentTarget:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLElement),
  ) => void;
  handleFocus: (event: React.FocusEvent<HTMLElement>) => void;
}

export function useScrollIntoView(): UseScrollIntoView {
  let timeout: NodeJS.Timeout | null = null;

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }, [timeout]);

  function onFocus(
    currentTarget:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLElement),
  ) {
    timeout = setTimeout(() => {
      currentTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }, 10);
  }

  function handleFocus(event: React.FocusEvent<HTMLElement>) {
    const { currentTarget } = event;

    onFocus(currentTarget);
  }

  return { onFocus, handleFocus };
}
