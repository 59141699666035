import { css } from '@emotion/react';

const base = css`
  padding: 4px;
  background-color: var(--color-background-4);
  border-radius: 28px;
  display: flex;
  height: 32px;
  border: none;
  position: relative;
  cursor: pointer;
  margin-right: 32px;
`;

const text = css`
  padding: 0 12px;
  font-family: var(--font-family-primarySemiBold);
  font-size: 14px;
  line-height: 16px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text-4);
  z-index: 2;
  width: 82px;
`;

const textActive = css`
  ${text}
  color: var(--color-text-1);
`;

const circleDay = css`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 82px;
  height: 24px;
  border-radius: 24px;
  background-color: var(--color-general-primary);
  z-index: 1;
  transition: all 0.4s ease-out;
`;

const circleWeek = css`
  ${circleDay}
  transform: translateX(82px);
`;

const display = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-3);
  margin-right: 12px;
`;

export const styles = {
  base,
  textActive,
  text,
  circleDay,
  circleWeek,
  display,
};
