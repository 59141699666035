import { css } from '@emotion/react';

const inputStyles = css`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 12px 44px;
  background: #2a282f;
  border-radius: 10px;
  color: white;

  &:hover,
  &:focus,
  &:focus-within {
    border: 1px solid #61645e;
  }
`;

const inputIconStyles = css`
  position: absolute;
  top: 12px;
  left: 18px;
  z-index: 1;
`;

export const styles = {
  inputStyles,
  inputIconStyles,
};
