import { SerializedStyles } from '@emotion/react';
import { motion, useInView } from 'framer-motion';
import React, { useRef } from 'react';
import { animatedTextStyles } from './animatedText.styles';

interface Props {
  text?: string;
  delay?: number;
  duration?: number;
  margin?: string;
  amount?: number;
  style?: SerializedStyles;
}

export const AnimatedText: React.FC<Props> = ({
  text,
  delay = 0,
  amount = 0.3,
  duration = 0.6,
  margin = '0px 0px -10% 0px',
  style,
}) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    amount,
    margin,
  });

  return (
    <span css={[animatedTextStyles.base, style]} ref={ref}>
      <span css={animatedTextStyles.textWrapper}>
        <motion.span
          css={animatedTextStyles.textWrapper}
          initial={{ translateY: '-140%' }}
          animate={{ translateY: isInView ? 0 : '-140%' }}
          transition={{
            duration,
            ease: [0.65, 0.01, 0.02, 0.98],
            delay,
          }}
        >
          {text}
        </motion.span>
        <span css={animatedTextStyles.block}>&nbsp;</span>
      </span>
    </span>
  );
};
