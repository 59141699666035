export function getMostReservedDate(bookings?: BookingDataPoint[]) {
  let maxNumberOfBookings = 0;
  let mostReservedDate: string = '';

  if (!bookings) return mostReservedDate;

  for (const booking of bookings) {
    if (booking.numberOfBookings > maxNumberOfBookings) {
      maxNumberOfBookings = booking.numberOfBookings;
      mostReservedDate = booking.date;
    }
  }

  return mostReservedDate;
}
