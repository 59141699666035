import { useFacilities, useSetIsSelectSportOpen } from '@modules/facility';
import { useScrollIntoView } from '@shared/hooks';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CustomPlaceholder,
  CustomSelectOption,
  CustomSelectValue,
} from './components';
import { FacilityOption } from './models';
import { selectProps, styles } from './styles';

interface Props {
  facilityOption?: FacilityOption;
  onSetSelectedFacility?: (facility: Facility) => void;
}

export const FacilitySelect: React.FC<Props> = ({
  facilityOption,
  onSetSelectedFacility,
}) => {
  const [selectedOption, setSelectedOption] = useState<
    MultiValue<FacilityOption> | SingleValue<FacilityOption> | null
  >(facilityOption);
  const { handleFocus } = useScrollIntoView();

  const facilities = useFacilities();
  const facilityNames = facilities?.map((facility) => ({
    value: facility.name,
    label: facility.name,
  }));

  const setIsDropdownOpen = useSetIsSelectSportOpen();

  const handleMenuOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleMenuClose = () => {
    setIsDropdownOpen(false);
  };

  const handleChange = (newValue: SingleValue<FacilityOption>) => {
    setSelectedOption(newValue);

    const facility = facilities.find((facility) => {
      return facility.name === newValue.value;
    });
    onSetSelectedFacility(facility);
  };

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="facility-select.title" />
        <span css={styles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        placeholder={<CustomPlaceholder />}
        defaultValue={facilityOption}
        options={facilityNames}
        onFocus={handleFocus}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          MultiValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        value={selectedOption}
        onMenuOpen={handleMenuOpen}
        onMenuClose={handleMenuClose}
        onChange={handleChange}
      />
    </div>
  );
};
