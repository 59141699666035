export function useBookingHelpers() {
  const convertToDate = (milliseconds: number): Date => {
    return new Date(milliseconds);
  };

  const formatHour = (date: Date): string => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const convertDateHoursOfOtherDate = (date: Date, dateToConvert: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    dateToConvert.setHours(hours);
    dateToConvert.setMinutes(minutes);
    dateToConvert.setSeconds(0);
    dateToConvert.setMilliseconds(0);

    return new Date(dateToConvert);
  };

  return { convertToDate, formatHour, convertDateHoursOfOtherDate };
}
