import { FC } from 'react';
import { styles } from './styles';

export const PitchPresentation: FC = () => {
  const embedCode = {
    __html:
      '<iframe src="https://pitch.com/embed-link/8rhb2m" frameborder="0" width="800" height="600" allowfullscreen></iframe>',
  };

  return <div css={styles.content} dangerouslySetInnerHTML={embedCode} />;
};
