import { ChartOptions, ScriptableContext } from 'chart.js';

export const getHotspotsGraphOptions = (
  maxValue: number,
  stepSize: number,
): ChartOptions<'bar'> => {
  return {
    responsive: true,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    plugins: {
      tooltip: {
        displayColors: false,
        backgroundColor: '#2A343C',
        titleColor: '#D0DDE7',
        bodyColor: '#D0DDE7',
        titleFont: { family: 'GTPressura', size: 11, weight: '400' },
        bodyFont: { family: 'GTPressura', size: 11, weight: '400' },
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.3,
      },
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        grid: {
          color: 'transparent',
        },
        ticks: {
          color: '#61645E',
          font: {
            size: 14,
            weight: '300',
            family: 'HK Grotesk',
            lineHeight: 1.25,
          },
        },
      },
      y: {
        type: 'linear',
        min: 0,
        grid: {
          color: '#61645E',
        },
        border: {
          dash: [4, 2],
        },
        beginAtZero: true,
        max: maxValue,
        ticks: {
          stepSize: stepSize,
          callback: (value: number) => {
            return value.toString();
          },
          font: {
            size: 14,
            weight: '300',
            lineHeight: 1.25,
            family: 'HK Grotesk',
          },
          count: 5,
        },
      },
    },
  };
};

/**@markoboras0712 internationalize this */
export function getHotspotsData(option: string, hotspots: WeekdayHotspots[]) {
  switch (option) {
    case 'Monday':
      return hotspots?.[0]?.hourlyBookings;
    case 'Tuesday':
      return hotspots?.[1]?.hourlyBookings;
    case 'Wednesday':
      return hotspots?.[2]?.hourlyBookings;
    case 'Thursday':
      return hotspots?.[3]?.hourlyBookings;
    case 'Friday':
      return hotspots?.[4]?.hourlyBookings;
    case 'Saturday':
      return hotspots?.[5]?.hourlyBookings;
    case 'Sunday':
      return hotspots?.[6]?.hourlyBookings;
    default:
      return Array(24).fill(0);
  }
}

/**@markoboras0712 internationalize this */
export function getDay(option: string) {
  if (option === 'Ponedjeljak' || option === 'Monday') {
    return 'Monday';
  }
  if (option === 'Utorak' || option === 'Tuesday') {
    return 'Tuesday';
  }
  if (option === 'Srijeda' || option === 'Wednesday') {
    return 'Wednesday';
  }
  if (option === 'Četvrtak' || option === 'Thursday') {
    return 'Thursday';
  }
  if (option === 'Petak' || option === 'Friday') {
    return 'Friday';
  }
  if (option === 'Subota' || option === 'Saturday') {
    return 'Saturday';
  }
  return 'Sunday';
}

export function getHotspotsGraphData(
  option: string,
  hotspots: WeekdayHotspots[],
) {
  const labels = [
    '0:00',
    '1:00',
    '2:00',
    '3:00',
    '4:00',
    '5:00',
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];
  const day = getDay(option);

  return {
    labels,
    datasets: [
      {
        data: getHotspotsData(day, hotspots),
        borderColor: '#ACEF34',
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 2000, 800);
          gradient.addColorStop(0.03, 'rgba(133, 185, 40, 1)');
          gradient.addColorStop(0.5365, 'rgba(177, 211, 125, 0.4)');
          gradient.addColorStop(0.9203, 'rgba(211, 232, 190, 0.1)');

          return gradient;
        },
        fill: 'start',
      },
    ],
  };
}
