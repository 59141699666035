import { useFacility } from '@modules/facility';
import { RoleOption } from '@modules/users/components/role-select/models';
import { MultiValue, SingleValue } from 'react-select';
import { useSetRole } from './useSetRole';

interface Props {
  selectedOption: SingleValue<RoleOption> | null;
  email: string;
  selectedFacility?: string;
  selectedGroups?: MultiValue<GroupOption> | null;
  displayName?: string;
}

export function useAddMember() {
  const facility = useFacility();
  const { setFacilityRole } = useSetRole();

  async function handleFacilityRole({
    email,
    selectedOption,
    selectedFacility,
    displayName,
    selectedGroups,
  }: Props) {
    const facilityId = selectedFacility || facility?.id;
    const groupIds = selectedGroups?.map((group) => group.value);
    await setFacilityRole(
      {
        email: email,
        role: selectedOption.value,
        facilityId,
        displayName,
        groupIds,
      },
      selectedOption.label,
    );
  }

  return { handleFacilityRole };
}
