import { SerializedStyles } from '@emotion/react';
import { AdminDashboardNavDropdown } from '@modules/admin';
import { LanguageSwitcherModal } from '@modules/translations';
import { useModal } from '@shared/hooks';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Logo from 'public/logo/playoff-logo-68.svg';
import { useEffect, useState } from 'react';
import { styles } from './styles';
const GlobeIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/globe.svg'),
  {
    loading: () => null,
  },
);
export const MobileHeader = () => {
  const { locale, pathname, push } = useRouter();
  const { closeModal, isModalOpen, openModal } = useModal();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  }, [pathname]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
      return;
    }

    document.body.style.overflow = 'auto';
  }, [isMenuOpen]);

  return (
    <>
      <header id="header" css={[styles.base, styles.dashboard]}>
        <div css={styles.adminSection}>
          <Logo onClick={() => push('/')} css={styles.logo} />
        </div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ display: 'flex' }}
        >
          <div css={styles.languageBtn(isModalOpen)} onClick={openModal}>
            <GlobeIcon viewBox="0 1 15 15" />
            <span>{locale}</span>
          </div>
          <AdminDashboardNavDropdown />
        </motion.div>
        {isModalOpen && (
          <LanguageSwitcherModal
            handleCloseModal={closeModal}
            clickOutside={closeModal}
          />
        )}
      </header>
    </>
  );
};
