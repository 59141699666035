import { SerializedStyles } from '@emotion/react';
import { useCurrentGroupMember } from '@modules/groups/state';
import { Button } from '@ui/Button';
import { format } from 'date-fns';
import dynamic from 'next/dynamic';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MembershipCancelFee } from './cancel-fee';
import { MembershipExtend } from './extend';
import { styles } from './membership.styles';

const CloseIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/close.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  groupId: string;
  setCurrentStep: (value: string) => void;
}

export const Membership: FC<Props> = ({ groupId, setCurrentStep }) => {
  const member = useCurrentGroupMember();
  const [currentMembershipStep, setCurrentMembershipStep] = useState('info');
  const expirationDate = Boolean(member.membershipExpiryDate)
    ? format(member.membershipExpiryDate, 'd.M.')
    : '/';

  return (
    <div>
      {currentMembershipStep === 'info' && (
        <>
          <div css={styles.flex}>
            <p css={styles.title}>
              <FormattedMessage id="membership.management" />
            </p>
            <Button
              customStyles={styles.buttonClose}
              onClick={() => setCurrentStep('info')}
            >
              <CloseIcon viewBox="0 0 24 24" />
            </Button>
          </div>
          <p css={styles.name}>{member.name}</p>
          <p css={styles.membershipDate}>
            <FormattedMessage id="membership.expires" />
            <span>{expirationDate}</span>
          </p>
          <div css={[styles.flex, styles.buttonContainer]}>
            <Button
              customStyles={styles.buttonConfirm}
              onClick={() => setCurrentMembershipStep('extend')}
            >
              <FormattedMessage id="membership.extend" />
            </Button>
            <Button
              disabled={!Boolean(member.membershipPaymentInfo.length)}
              customStyles={styles.buttonCancel}
              onClick={() => setCurrentMembershipStep('cancel')}
            >
              <FormattedMessage id="membership.cancel-payment" />
            </Button>
          </div>
        </>
      )}
      {currentMembershipStep === 'extend' && (
        <MembershipExtend
          groupId={groupId}
          member={member}
          setCurrentStep={setCurrentStep}
          setCurrentMembershipStep={setCurrentMembershipStep}
        />
      )}
      {currentMembershipStep === 'cancel' && (
        <MembershipCancelFee
          groupId={groupId}
          member={member}
          setCurrentStep={setCurrentStep}
          setCurrentMembershipStep={setCurrentMembershipStep}
        />
      )}
    </div>
  );
};
