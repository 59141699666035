import { css } from '@emotion/react';

const flex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const title = css`
  color: var(--color-text-6);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const buttonClose = css`
  padding: 12px;
  margin-right: -12px;
  margin-top: -12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const name = css`
  color: var(--color-text-6);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const membershipDate = css`
  color: var(--color-text-4);
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 32px;

  span {
    font-size: 16px;
    color: var(--color-text-5);
  }
`;

const buttonDelete = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 20px;
  color: var(--color-text-6);
  background-color: var(--color-error-utility);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: calc(50% - 12px);
`;

const buttonBack = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  color: var(--color-text-5);
  background-color: var(--color-background-4);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: calc(50% - 12px);
`;

const description = css`
  color: var(--color-text-6);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
  max-width: 400px;
`;

const dueDateWrapper = css`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-4);
  margin-bottom: 32px;
`;

const expirationDate = css`
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
`;

const lastExpirationDate = css`
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  text-decoration: line-through;
`;

export const styles = {
  title,
  buttonClose,
  flex,
  name,
  membershipDate,
  buttonDelete,
  buttonBack,
  description,
  dueDateWrapper,
  expirationDate,
  lastExpirationDate,
};
