interface BookingConflictParams {
  from: number;
  to: number;
  currentCourtId: string;
  currentFacility: Facility;
  acceptedBookings: Booking[];
}

export function getBookingConflict(params: BookingConflictParams): Booking {
  const { from, to, currentCourtId, currentFacility, acceptedBookings } =
    params;

  return acceptedBookings.find(
    ({ startTime, endTime, facilityId, courtId }) =>
      startTime < to &&
      endTime > from &&
      facilityId === currentFacility.id &&
      courtId === currentCourtId,
  );
}
