import { ChartOptions, ScriptableContext } from 'chart.js';
import { useIntl } from 'react-intl';
import { getRevenueGraphMonthlyLabels } from '../const';
import { useGetRevenueData } from '../hooks';
import {
  useCurrentMonthRevenue,
  useCurrentYearRevenue,
  useLastYearRevenue,
  usePastMonthRevenue,
} from '../state';

export const getRevenueGraphOptions = (): ChartOptions<'line'> => ({
  responsive: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      displayColors: false,
      backgroundColor: '#2A343C',
      titleColor: '#D0DDE7',
      bodyColor: '#D0DDE7',
      titleFont: { family: 'GTPressura', size: 11, weight: '400' },
      bodyFont: { family: 'GTPressura', size: 11, weight: '400' },
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.1,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        color: 'transparent',
      },
      ticks: {
        color: '#61645E',
        font: {
          size: 14,
          weight: '300',
          family: 'HK Grotesk',
          lineHeight: 1.25,
        },
      },
    },
    y: {
      type: 'linear',
      grid: {
        color: '#61645E',
      },
      border: {
        dash: [4, 2],
      },
      beginAtZero: true,
      min: 0,
      ticks: {
        callback: (value: number) => {
          if (value === 0) return value.toString();

          if (value >= 20 && value <= 5000 && value % 5 === 0) {
            return value.toString();
          }

          if (value >= 20 && value <= 50000 && value % 20 === 0) {
            return value.toString();
          }

          if (value < 20 && value % 1 === 0) return value.toString();
        },
        font: {
          size: 14,
          weight: '300',
          lineHeight: 1.25,
          family: 'HK Grotesk',
        },
        count: 5,
      },
    },
  },
});

export function getRevenueGraphOption(message: string) {
  return message;
}

/**@markoboras0712 internationalize this */
export function getRevenueGraphData(
  option: string,
  locale: string,
  interval: 'yearly' | 'monthly',
) {
  const { formatMessage } = useIntl();
  const currentYearRevenue = useCurrentYearRevenue();
  const lastYearRevenue = useLastYearRevenue();
  const currentMonthRevenue = useCurrentMonthRevenue();
  const pastMonthRevenue = usePastMonthRevenue();
  const { getRevenueData } = useGetRevenueData();

  const localizedLabels = getRevenueGraphMonthlyLabels(
    locale,
    option === formatMessage({ id: 'revenue.graph-results.pastMonth' }),
  )[interval];

  return {
    labels: localizedLabels,
    datasets: [
      {
        data: getRevenueData({
          option,
          currentYearRevenue,
          lastYearRevenue,
          currentMonthRevenue,
          pastMonthRevenue,
        }),
        borderColor: '#ACEF34',
        backgroundColor: (context: ScriptableContext<'line'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(-1000, 0, 1000, 200);
          gradient.addColorStop(0.03, 'rgba(133, 185, 40, 0.9)');
          gradient.addColorStop(0.5365, 'rgba(177, 211, 125, 0.4)');
          gradient.addColorStop(0.9203, 'rgba(211, 232, 190, 0.1)');

          return gradient;
        },
        fill: 'start',
      },
    ],
  };
}
