import { useEffect, useRef } from 'react';
import { useKeyPress } from './useKeyPress';

export function useCloseOnEscape<T extends HTMLElement>() {
  const ref = useRef<T>(null);
  const escapePressed = useKeyPress('Escape');
  useEffect(() => {
    if (escapePressed) ref.current?.click();
  }, [escapePressed]);

  return ref;
}
