import { AnimationsProps, HomeProductAnimationsProps } from '@shared/models';
import { MotionProps, Variants } from 'framer-motion';

const opacityInView = ({
  duration = 0.6,
  delay = 0.1,
  amount = 0.3,
  margin = '0px 0px -20% 0px',
  ease = 'easeOut',
  once = true,
}: AnimationsProps): MotionProps => ({
  initial: {
    opacity: 0,
  },
  whileInView: {
    opacity: 1,
  },
  viewport: {
    once,
    amount,
    margin,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

const opacity = ({
  duration = 0.6,
  delay = 0.1,
  ease = 'easeOut',
}: AnimationsProps): MotionProps => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

export const parentStagger: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.6,
      delay: 0.1,
      staggerChildren: 0.3,
      ease: [0.075, 0.82, 0.165, 1],
    },
  },
};

const parentStaggerPropsInView: MotionProps = {
  variants: parentStagger,
  initial: 'hidden',
  whileInView: 'visible',
  viewport: { once: true, amount: 0.3 },
};

const parentStaggerProps: MotionProps = {
  variants: parentStagger,
  initial: 'hidden',
  animate: 'visible',
};

const childStagger: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
};

const overlayInView = ({
  duration = 0.6,
  delay = 0.1,
  amount = 0.3,
  margin = '0px 0px -20% 0px',
  ref,
  ease = [0.77, 0.0, 0.175, 1.0],
}: AnimationsProps): MotionProps => ({
  initial: {
    height: '100%',
  },
  whileInView: {
    height: '0',
  },
  viewport: {
    once: true,
    amount,
    margin,
    root: ref,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

const homeProductAnimation = ({
  isHovered,
  rotationDeg,
  cursorPosition,
}: HomeProductAnimationsProps): MotionProps => ({
  initial: {
    scale: 0.1,
    opacity: 0,
    translateX: '-50%',
    translateY: '-50%',
    rotate: 0,
  },
  animate: {
    left: cursorPosition.x,
    top: cursorPosition.y,
    scale: isHovered ? 1 : 0.1,
    opacity: isHovered ? 1 : 0,
    translateX: '-50%',
    translateY: '-50%',
    rotate: rotationDeg,
  },
  transition: {
    duration: 0.1,
    ease: 'linear',
    opacity: { duration: 0.4, ease: 'easeOut' },
    scale: { duration: 0.4, ease: 'easeOut' },
    rotate: { duration: 0.4, ease: 'easeOut' },
  },
  exit: {
    scale: 0.1,
    opacity: 0,
    rotate: 0,
    transition: { duration: 0.1, ease: 'easeIn' },
  },
});

const transitionOverlay = (): MotionProps => ({
  initial: {
    translateY: '-100vh',
  },
  animate: {
    translateY: '0',
  },
  transition: {
    duration: 0.6,
    ease: [0.65, 0.01, 0.02, 0.98],
  },
  exit: {
    translateY: '-100vh',
    transition: {
      duration: 0.6,
      ease: [0.65, 0.01, 0.02, 0.98],
      delay: 0.8,
    },
  },
});

export const animations = {
  opacity,
  opacityInView,
  overlayInView,
  homeProductAnimation,
  transitionOverlay,
  parentStagger,
  childStagger,
  parentStaggerPropsInView,
  parentStaggerProps,
};
