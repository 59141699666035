import { useCurrentDate, useSetCurrentDate } from '@modules/booking-new';
import { useCurrentLocale } from '@modules/translations';
import { Button } from '@ui/Button';
import { format } from 'date-fns';
import { Croatian } from 'flatpickr/dist/l10n/hr';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import ArrowLeft from 'public/icons/arrow-short-left-24.svg';
import Calendar from 'public/icons/calendar-16.svg';
import { FC } from 'react';
import Flatpickr from 'react-flatpickr';
import { styles } from './dateWeekCalendar.styles';

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const DateDayCalendar: FC = () => {
  const currentDate = useCurrentDate();
  const { locale: currentLocale } = useRouter();
  const setCurrentDate = useSetCurrentDate();
  const { getLocale } = useCurrentLocale();

  function getFlatpickrLocale() {
    switch (currentLocale) {
      case 'hr':
        return Croatian;
      default:
        return 'en';
    }
  }

  const addOneDay = () => {
    let newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentDate(newDate);
  };

  const removeOneDay = () => {
    let newDate = new Date(currentDate);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentDate(newDate);
  };

  return (
    <motion.div
      variants={variants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.4 }}
      css={styles.wrapper}
    >
      <Button onClick={removeOneDay} customStyles={styles.button}>
        <ArrowLeft viewBox="0 0 24 24" />
      </Button>
      <div css={styles.calendarWrapper}>
        <Flatpickr
          value={currentDate}
          options={{
            disableMobile: true,
            dateFormat: 'Y-m-d H:i',
            locale: getFlatpickrLocale(),
          }}
          css={styles.inputDay}
          onChange={([date]) => {
            setCurrentDate(date);
          }}
        />
        <Calendar viewBox="0 0 16 18" css={styles.calendarIcon} />
        <div css={styles.dateRange}>
          {format(currentDate, 'iiii, d. MMMM', {
            locale: getLocale(),
          })}{' '}
        </div>
      </div>
      <Button onClick={addOneDay} customStyles={styles.buttonRight}>
        <ArrowLeft viewBox="0 0 24 24" />
      </Button>
    </motion.div>
  );
};
