import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { scroll } from '@styles/scroll.styles';

export const base = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #16141bcc;
`;

const modal = css`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 36px 32px;
  width: 600px;
  background-color: var(--color-background-2);
  border-radius: 8px;
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text-6);
`;

const form = css`
  ${scroll.base}
  display: flex;
  gap: 28px;
  flex-direction: column;
`;

const buttonConfirm = css`
  ${button.primary}
  padding: 8px 28px;
  font-size: 14px;
  line-height: 20px;
  color: #151717;
  background: var(--color-general-primary);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled {
    color: white;
    cursor: not-allowed;
  }
`;

const buttonBack = css`
  padding: 8px 28px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  background-color: transparent;
  color: var(--color-text-5);
`;

const buttonWrapper = css`
  margin-top: 12px;
`;

export const styles = {
  base,
  modal,
  title,
  form,
  buttonConfirm,
  buttonBack,
  buttonWrapper,
};
