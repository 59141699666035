import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { display } from '@styles/display.styles';
import { scroll } from '@styles/scroll.styles';

const flex = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
`;

const name = css`
  font-size: 20px;
  line-height: 24px;
`;

const capacity = css`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  background-color: #f4f8f70d;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  width: max-content;
`;

const termsWrapper = css`
  ${display.overflowAuto}
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 24px;

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
`;

const term = css`
  display: flex;
  width: max-content;
  padding: 4px 12px;
  gap: 4px;
  font-size: 14px;
  line-height: 16px;
  border-radius: 32px;
  border: 1px solid var(--color-border-4);

  span:first-of-type,
  span:last-of-type {
    color: var(--color-text-5);
  }

  span:nth-of-type(2) {
    font-weight: 700;
  }
`;

const buttonAdd = css`
  ${button.primary}
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background: var(--color-general-primary);
  letter-spacing: -0.01em;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);

    :disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
`;

const buttonClose = css`
  padding: 12px;
  margin-right: -20px;
  margin-top: -20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const list = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 420px;
  ${scroll.base}
`;

export const styles = {
  name,
  capacity,
  flex,
  termsWrapper,
  term,
  buttonAdd,
  buttonClose,
  list,
};
