import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { display } from '@styles/display.styles';

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;
`;

const base = css`
  display: flex;
  border: 1px solid #f8f8f814;
  border-radius: 8px;
  overflow: hidden;
`;

const columnOne = css`
  ${display.overflowAuto};
  height: 100%;
  padding: 36px 32px;
  min-width: 420px;
  max-width: 500px;
  background-color: var(--color-background-2);
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #ffffff;
`;

const description = css`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

const buttonContainer = css`
  display: flex;
  gap: 8px;
  margin-top: 16px;
`;

const confirmButton = css`
  ${button.primary};
  margin-top: 16px;
  background: #acef34;
  border-radius: 32px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #151717;
  padding: 8px 20px;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled {
    color: white;
    cursor: not-allowed;
  }
`;

const cancelButton = css`
  ${button.secondary};
  margin-top: 16px;
  background: transparent;
  border-radius: 32px;
  padding: 8px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #c7c7d1;

  &:hover,
  &:focus,
  &:active {
  }
`;

const confirmDescription = css`
  margin: 16px 0;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

export const styles = {
  base,
  wrapper,
  columnOne,
  title,
  description,
  confirmButton,
  buttonContainer,
  cancelButton,
  confirmDescription,
};
