import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { scroll } from '@styles/scroll.styles';

const flex = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const title = css`
  color: var(--color-text-6);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const buttonClose = css`
  padding: 12px;
  margin-right: -12px;
  margin-top: -12px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const name = css`
  color: var(--color-text-6);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const membershipDate = css`
  color: var(--color-text-4);
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 32px;

  span {
    font-size: 16px;
    color: var(--color-text-5);
  }
`;

const buttonConfirm = css`
  ${button.primary}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 20px;
  background-color: var(--color-general-primary);
  font-size: 14px;
  line-height: 20px;
  width: calc(50% - 12px);

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }

  &:disabled {
    color: white;
    cursor: not-allowed;
  }
`;

const buttonBack = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;
  color: var(--color-text-5);
  background-color: var(--color-background-4);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  width: calc(50% - 12px);
`;

const description = css`
  color: var(--color-text-6);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const dueDateWrapper = css`
  font-size: 14px;
  line-height: 16px;
  color: var(--color-text-4);
  margin-bottom: 32px;
`;

const expirationDate = css`
  font-size: 16px;
  line-height: 20px;
  color: var(--color-text-5);
`;

const lastExpirationDate = css`
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #737373;
  text-decoration: line-through;
`;

const inputStyles = css`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 12px 44px;
  background: #2a282f;
  border-radius: 10px;
  color: white;

  &:focus-within {
    border: 1px solid #61645e;
  }
  &:hover {
    border: 1px solid #61645e;
  }
`;

const searchResult = css`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #a4a4a4;
  width: 100%;
`;

const iconStyles = css`
  display: flex;
  margin-right: 14px;
  margin-left: auto;
`;

const inputIconStyles = css`
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
`;

const result = css`
  line-height: 20px;
  color: #c7c7d1;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    color: #fff;
  }
`;

const resultActive = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #acef34;
`;

const dropdownActiveContainer = css`
  width: 194px;
  height: auto;
  background: var(--color-background-4);
  border: none;
  box-shadow: 0px -4px 32px rgba(20, 19, 21, 0.44),
    0px 14px 32px rgba(20, 19, 21, 0.44);
  border-radius: 6px;
  padding: 16px 12px 0 16px;
  position: absolute;
  overflow: hidden;
  top: 32px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${scroll.base}
`;

const dropdown = css`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  position: relative;
`;

const label = css`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-4);
  background: var(--color-background-4);
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

const select = css`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-text6);
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const selectedOption = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--color-general-primary);
`;

const datePicker = css`
  height: 100%;

  .MuiInputAdornment-root {
    height: 100%;
    max-height: none;
    margin-left: 0;
  }

  .MuiInputBase-root {
    :hover {
      border-color: transparent;
    }
  }

  .MuiIconButton-root {
    color: var(--color-icon-5);
    padding: 6px 12px;
    background-color: var(--color-background-4);
    border-radius: 8px;

    :hover {
      outline: none;
      color: var(--color-icon-7);
      background-color: var(--color-background-4);
    }

    :active {
      background-color: var(--color-background-4);
      color: var(--color-icon-1);
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: transparent;

    :hover {
      border-color: transparent;
    }
  }

  input {
    display: none;
  }
`;

const activeDatePicker = css`
  .MuiIconButton-root {
    color: var(--color-icon-1);

    :hover {
      color: var(--color-icon-1);
    }
  }
`;

const wrapper = css`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  p {
    margin-bottom: 0;
  }
`;

export const styles = {
  title,
  buttonClose,
  flex,
  name,
  membershipDate,
  buttonConfirm,
  buttonBack,
  description,
  dueDateWrapper,
  expirationDate,
  lastExpirationDate,
  inputStyles,
  searchResult,
  iconStyles,
  inputIconStyles,
  result,
  resultActive,
  dropdownActiveContainer,
  dropdown,
  label,
  select,
  selectedOption,
  datePicker,
  activeDatePicker,
  wrapper,
};
