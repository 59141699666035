import { selector, useRecoilValue } from 'recoil';
import { bookingAtoms } from './atoms';

export const isActiveSummaryModal = selector({
  key: 'booking.isActiveSummary',
  get: ({ get }) => {
    const bookedHours = get(bookingAtoms.currentBookedHours);
    const toDeleteHours = get(bookingAtoms.currentToDeleteBookedHours);
    return Boolean(bookedHours.length) || Boolean(toDeleteHours.length);
  },
});

export const useIsActiveSummaryModal = () =>
  useRecoilValue(isActiveSummaryModal);
