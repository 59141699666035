import { useDefaultClaim } from '@modules/auth';
import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useDeleteRole() {
  const defaultClaim = useDefaultClaim();
  const { formatMessage } = useIntl();
  const deleteRoleCallable = useFunction<DeleteAllRoleData>('deleteRole');

  async function deleteRole({ email, facilityId, role }: DeleteAllRoleData) {
    if (!deleteRoleCallable) return;
    try {
      await deleteRoleCallable({
        email,
        facilityId,
        role,
        userRole: defaultClaim.role,
      });
      toast.success(
        formatMessage(
          { id: 'toasts.users.role-deleted' },
          {
            email,
          },
        ),
      );
      return;
    } catch {
      toast.error(formatMessage({ id: 'toasts.users.role-deleted.error' }));
      return;
    }
  }

  return {
    deleteRole,
  };
}
