import {
  bookingFirestoreConverter,
  useCurrentDate,
  useSetBookings,
  useSetIsLoading,
} from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { FirebaseService, getTypedCollectionDocument } from '@modules/firebase';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useEffect } from 'react';

/**This hook is used to listen to all bookings where facilityId is equal to current facilityId */
export function useBookingListener() {
  const facility = useFacility();
  const date = useCurrentDate();
  const setLoading = useSetIsLoading();
  const setBookings = useSetBookings();

  useEffect(() => {
    const isGroup = facility?.type === 'group';
    if (!facility?.id || isGroup) return;

    const q = query(
      collection(FirebaseService.firestore, 'bookings'),
      where('facilityId', '==', facility?.id),
    ).withConverter(bookingFirestoreConverter);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const bookings = querySnapshot.docs.flatMap((doc) =>
        getTypedCollectionDocument<Booking[]>(doc),
      );
      const sortedBookingData = bookings?.sort(
        (a, b) => a.startTime - b.startTime,
      );

      setBookings(sortedBookingData);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [facility, date]);
}
