import { SerializedStyles } from '@emotion/react';
import { useFirebaseAuth } from '@modules/auth';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';
import { styles } from '../styles';

const GlobeIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/globe.svg'),
  {
    loading: () => null,
  },
);

const LogoutIcon = dynamic<{ css?: SerializedStyles }>(
  () => import('public/icons/logout-icon-16.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  locale: string;
  isLanguageSwitcherModalOpen: boolean;
  openModal: () => void;
}

export const UserHeader: React.FC<Props> = ({
  locale,
  isLanguageSwitcherModalOpen,
  openModal,
}) => {
  const { handleLogout } = useFirebaseAuth();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      css={styles.adminSection}
    >
      <div
        css={styles.languageBtn(isLanguageSwitcherModalOpen)}
        onClick={openModal}
      >
        <GlobeIcon viewBox="0 1 15 15" />
        <span>{locale}</span>
      </div>
      <div onClick={handleLogout} css={styles.logout}>
        <LogoutIcon />
        <span>
          <FormattedMessage id="admin-nav.logout" />
        </span>
      </div>
    </motion.div>
  );
};
