import React from 'react';
import { styles } from './loadingState.styles';

export const LoadingState: React.FC = () => {
  return (
    <div css={styles.spinnerStyle}>
      <div css={styles.dotStyle({ delay: 0 })} />
      <div css={styles.dotStyle({ delay: 0.15 })} />
      <div css={styles.dotStyle({ delay: 0.3 })} />
    </div>
  );
};
