import { AdminDashboard } from '@modules/admin';
import { useFacility } from '@modules/facility';
import { LoadingState } from '@shared/components';
import { display } from '@styles/utils.display.styles';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBookingListener } from '../hooks';
import {
  useBookingSlotInfo,
  useIsDayView,
  useIsDestructiveMode,
  useIsLoading,
  useIsModalOpen,
  useResetCurrentBookedHours,
  useResetCurrentToDeleteBookedHours,
  useSetIsDestructiveMode,
} from '../state';
import { BookingCourtsSelector } from './booking-courts-selector/BookingCourtsSelector';
import { BookingInfoFormModal } from './booking-info-form-modal/BookingInfoFormModal';
import { BookingSlotInfo } from './booking-slot-info/BookingSlotInfo';
import { styles } from './bookingCalendar.styles';
import { CalendarView } from './calendar-view/CalendarView';
import { CancelModal } from './cancel-modal/CancelModal';
import { DateCalendar } from './date-picker/DateCalendar';
import { ViewPicker } from './view-picker/ViewPicker';

export const BookingCalendar: FC = () => {
  /**Bookings listener */
  useBookingListener();
  const facility = useFacility();
  const isDestructive = useIsDestructiveMode();
  const isModalOpen = useIsModalOpen();
  const isLoading = useIsLoading();
  const info = useBookingSlotInfo();
  const isDay = useIsDayView();
  const setIsDestructive = useSetIsDestructiveMode();
  const resetBookedHours = useResetCurrentBookedHours();
  const resetToDeleteBookedHours = useResetCurrentToDeleteBookedHours();
  const { formatMessage } = useIntl();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDestructive(event.target.checked);
  };

  useEffect(() => {
    resetBookedHours();
    resetToDeleteBookedHours();
  }, [isDestructive, facility]);

  return (
    <AdminDashboard
      title={formatMessage({ id: 'booking-calendar.title' })}
      description={formatMessage({ id: 'booking-calendar.description' })}
      containerStyles={styles.base}
      mainStyles={styles.main}
    >
      <div css={styles.wrapper}>
        <div css={isDay ? styles.spaceBetweenDay : styles.spaceBetweenWeek}>
          <div css={display.flexCenter}>
            <ViewPicker />
            <DateCalendar />
          </div>
          <div css={styles.destructiveWrapper}>
            <span>
              <FormattedMessage id="booking-calendar.destructive-mode" />
            </span>
            <label css={styles.toggleStyles}>
              <input
                type="checkbox"
                checked={isDestructive}
                onChange={handleInputChange}
                css={styles.inputStyles}
              />
              <span
                css={
                  isDestructive
                    ? styles.sliderCheckedStyles
                    : styles.sliderStyles
                }
              />
            </label>
          </div>
        </div>
        <BookingCourtsSelector courts={facility.courts} />
        <CalendarView />
      </div>
      <AnimatePresence mode="wait">
        {isModalOpen &&
          (isDestructive ? <CancelModal /> : <BookingInfoFormModal />)}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {info && <BookingSlotInfo />}
      </AnimatePresence>
      {isLoading && <LoadingState />}
    </AdminDashboard>
  );
};
