import { useFacility } from '@modules/facility';
import { OpponentOption } from '@modules/pyramid';
import { useNoScroll } from '@shared/hooks';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { toast } from 'sonner';
import { NumberOfPlayersOption } from '../components/booking-info-form-modal/fragments/number-of-players-select';
import { requiredSportOpponents } from '../const';
import { useSetIsModalOpen } from '../state';
import { useSubmitBooking } from './useSubmitBooking';

const validate = (values: FormValues) => {
  const errors: FormValues = {};
  if (!values?.name) errors.name = 'Ime je obavezno.';

  if (values?.comment && values.comment.length > 500) {
    errors.comment = 'Komentar ne može biti duži od 500 karaktera.';
  }

  if (values?.phoneNumber && !/^[+-]?\d+$/.test(values.phoneNumber)) {
    errors.phoneNumber =
      'Telefonski broj može sadržavati samo znakove +, - i brojeve od 0-9.';
  }

  return errors;
};

export interface FormValues {
  email?: string;
  name?: string;
  comment?: string | null;
  phoneNumber?: string;
  opponent?: Opponent | null;
  teamOpponentEmail?: string;
  teamOpponentName?: string;
  categoryName?: string;
  package?: Package | null;
  selectedNumberOfPlayers?: NumberOfPlayersOption | null;
}

export function useBookingInfoModal() {
  useNoScroll(true);
  const currentFacility = useFacility();
  const hasTeamGame = useMemo(
    () => currentFacility.isTeamGameSupported,
    [currentFacility],
  );
  const isPaintball = useMemo(
    () => Boolean(currentFacility.packagedBookingData),
    [currentFacility],
  );
  const [isPyramidChecked, setIsPyramidChecked] = useState(false);
  const [isTeamChecked, setIsTeamChecked] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OpponentOption> | null>(null);
  const [teamGameOption, setTeamGameOption] =
    useState<SingleValue<OpponentOption> | null>(null);

  const setIsModalOpen = useSetIsModalOpen();
  const { handleSubmitBooking } = useSubmitBooking();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik<FormValues>({
    initialValues: {
      email: '',
      name: '',
      phoneNumber: '',
      comment: '',
      opponent: null,
      teamOpponentEmail: '',
      teamOpponentName: '',
      categoryName: '',
      package: null,
      selectedNumberOfPlayers: null,
    },
    validate,
    onSubmit: async (values, { resetForm }) => {
      const opponent = selectedOption?.value ||
        teamGameOption?.value || {
          name: values.teamOpponentName || '',
          email: values.teamOpponentEmail || '',
        };
      const isOpponentRequired =
        isPyramidChecked &&
        requiredSportOpponents.some((sport) =>
          currentFacility.sports.some(
            (facilitySport) => facilitySport === sport,
          ),
        );
      const selectedPackagedBookingData: SelectedPackagedBookingData | null =
        isPaintball
          ? {
              numberOfUsers: values.selectedNumberOfPlayers?.value || 0,
              package: values.package || null,
              category:
                currentFacility.packagedBookingData?.categories?.find(
                  (item) => values.categoryName === item.name,
                ) || null,
            }
          : null;

      if (isOpponentRequired && (!opponent.email || !opponent.name)) {
        toast.error('Morate unijeti ime i email protivnika');
        return;
      }

      if (isPaintball) {
        if (
          !selectedPackagedBookingData?.category ||
          !selectedPackagedBookingData?.package
        ) {
          toast.error('Molimo vas odaberite paket.');
          return;
        }

        if (!selectedPackagedBookingData?.numberOfUsers) {
          toast.error('Molimo vas odaberite broj igrača.');
          return;
        }
      }

      await handleSubmitBooking({
        name: values.name,
        email: values.email,
        phoneNumber: values.phoneNumber,
        comment: values.comment,
        opponent,
        isTournament: isPyramidChecked,
        isTeamGame: isTeamChecked,
        selectedPackagedBookingData,
      });
      resetForm();
      setIsModalOpen(false);
    },
  });

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return {
    values,
    errors,
    touched,
    isPyramidChecked,
    isTeamChecked,
    teamGameOption,
    selectedOption,
    currentFacility,
    hasTeamGame,
    isPaintball,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setIsPyramidChecked,
    setIsTeamChecked,
    setSelectedOption,
    setTeamGameOption,
    setIsModalOpen,
  };
}
