import { css } from '@emotion/react';

const buttonStyles = css`
  max-width: 234px;
`;

const authProviderStyle = css`
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  border-radius: 20px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #d6d9dc;
  text-align: center;
  height: 48px;
  width: auto;
`;

const googleLoginStyle = css`
  ${authProviderStyle};
  border-color: #d6d9dc;
  background-color: white;
  color: #535a60;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

const facebookLoginStyle = css`
  ${authProviderStyle};
  color: #fff;
  background-color: #395697;
  border-color: transparent;
  
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const socialLoginStyles = {
  buttonStyles,
  googleLoginStyle,
  facebookLoginStyle,
};
