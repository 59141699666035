import { css } from '@emotion/react';

const base = css`
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--color-text-5);
`;

const checkbox = (label: string) => css`
  position: relative;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-4);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 48px;

  input[type='checkbox'] {
    display: none;
  }

  ::before {
    content: '${label}';
    position: absolute;
    color: var(--color-text-6);
    font-size: 14px;
    line-height: 20x;
    right: -48px;
    top: 2px;
  }
`;

const checkboxActive = (label: string) => css`
  ${checkbox(label)};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid var(--color-general-primary);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const repeatText = css`
  color: var(--color-text-6);
  font-size: 14px;
  line-height: 20x;
`;

const durationIcon = css`
  width: 13px;
  height: 13px;

  path {
    fill: var(--color-icon-4);
  }
`;

const durationWrapper = css`
  position: relative;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

const input = css`
  color: var(--color-text-6);
  background: transparent;
  padding: 0;
  width: 40px;
  border-bottom: 1px solid var(--color-border-5);
  padding: 0 4px;

  &::placeholder {
    color: var(--color-text-3);
  }
`;

const inputError = css`
  position: absolute;
  bottom: -16px;
  left: 0;
  color: var(--color-error);
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  min-width: max-content;
`;

export const styles = {
  base,
  checkbox,
  checkboxActive,
  repeatText,
  durationIcon,
  durationWrapper,
  input,
  inputError,
};
