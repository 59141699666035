import { Global } from '@emotion/react';
import { FirebaseAuthProvider } from '@modules/auth';
import { TranslationProvider } from '@modules/translations';
import { GoogleTagManager } from '@next/third-parties/google';
import { Layout } from '@ui/Layout';
import { ToastWrapper } from '@ui/ToastWrapper';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import 'flatpickr/dist/themes/material_green.css';
import type { AppProps } from 'next/app';
import { RecoilEnv, RecoilRoot } from 'recoil';
import { globalStyles } from 'styles/global.styles';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

const isBrowser = typeof window !== 'undefined';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <TranslationProvider>
      <RecoilRoot>
        <FirebaseAuthProvider>
          <ToastWrapper />
          <Global styles={globalStyles} />
          <Layout>
            {isBrowser && window.location.host === 'theplayoff.app' && (
              <>
                <Analytics />
                <SpeedInsights />
                <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
              </>
            )}
            <Component {...pageProps} />
          </Layout>
        </FirebaseAuthProvider>
      </RecoilRoot>
    </TranslationProvider>
  );
}

export default MyApp;
