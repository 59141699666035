import { css } from '@emotion/react';

const courts = css`
  grid-column: 1 / span 9;
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
`;

const court = css`
  font-family: var(--font-family-primarySemiBold);
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid var(--color-border-cardBorder);
  color: var(--color-text-5);
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background-color: var(--color-background-1);
`;

const courtActive = css`
  ${court};
  background-color: var(--color-background-5);
  border-color: var(--color-general-primary);
  color: var(--color-general-primary);
`;

export const styles = {
  courts,
  court,
  courtActive,
};
