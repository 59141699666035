import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

export const facilityRolesFirestoreConverter: FirestoreDataConverter<FacilityRole> =
  {
    toFirestore: (facilityRole: FacilityRole): object => {
      return {
        facilityRole,
      };
    },

    fromFirestore: (
      snapshot: QueryDocumentSnapshot<FacilityRole>,
    ): FacilityRole => {
      const firestoreData = snapshot.data();
      return firestoreData;
    },
  };
