import { SerializedStyles } from '@emotion/react';
import dynamic from 'next/dynamic';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

const GroupIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/group.svg'),
  {
    loading: () => null,
  },
);

export const CustomPlaceholder = () => (
  <div css={styles.wrapper}>
    <GroupIcon viewBox="0 0 16 16" />
    <span css={styles.placeholder}>
      <FormattedMessage id="users-management-form.groups.placeholder" />
    </span>
  </div>
);
