import { safeLog } from '@shared/utils';
import { deleteDoc, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { useMemo } from 'react';
import { FirebaseService } from '../initFirebase';
import { getTypedDocument } from '../utils';
import { createFirestoreConverter } from '../utils/createFirestoreConverter';

export function useFirestoreDocument<T extends { id?: string | null }>(
  path: string,
) {
  const reference = useMemo(
    () => doc(FirebaseService.firestore, path).withConverter(createFirestoreConverter()),
    [path],
  );

  async function get() {
    try {
      const snapshot = await getDoc(reference);
      const doc = getTypedDocument<T>(snapshot);
      safeLog(`Fetched ${path}: `, doc);
      return doc;
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  async function deleteDocument() {
    try {
      await deleteDoc(reference);
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  async function set(data: Partial<T>) {
    try {
      delete data.id;
      await setDoc(reference, { ...data }, { merge: true });
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  function listen(
    onSucess: (doc: T | undefined) => void,
    onError: (error?: string) => void,
    onFinished?: VoidFunction,
  ) {
    return onSnapshot(
      reference,
      (snapshot) => {
        const doc = getTypedDocument<T>(snapshot);
        onSucess(doc);
        onFinished?.();
        safeLog(`Listening to ${path}: `, doc);
      },
      (error) => {
        safeLog(`Error listening to ${path}: `, error);
        onError(error.message);
        onFinished?.();
      },
    );
  }

  return { get, set, listen, deleteDocument };
}
