/**
 * Get Login Error Message utility
 * @name getLoginErrorMessage
 * @param {string} errorCode - The error code returned from the login attempt in catch block.
 * @description Utility function that is used to return the error message based on the error code.
 */

export function getLoginErrorMessage(errorCode: string) {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'auth-toast.user-not-found';
    case 'auth/wrong-password':
      return 'auth-toast.wrong-password';
    case 'auth/cancelled-popup-request':
      return 'auth-toast.cancelled-popup-request';
    case 'auth/account-exists-with-different-credential':
      return 'auth-toast.email-already-in-use';
    case 'auth/too-many-requests':
      return 'auth-toast.too-many-requests';
    default:
      return 'auth-toast.user-not-found';
  }
}
