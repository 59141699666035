import { useCloseOnEscape } from '@shared/hooks';
import CheckIcon from 'public/icons/check.svg';
import { styles } from './membershipExtend.styles';

interface Props {
  option: number;
  label: string;
  isActive: boolean;
  onSetSelectedOption: (option: number) => void;
  onClose?: () => void;
}

export const MembershipDurationOptions: React.FC<Props> = ({
  isActive,
  option,
  label,
  onSetSelectedOption,
  onClose,
}) => {
  const ref = useCloseOnEscape<HTMLDivElement>();

  return (
    <div css={styles.searchResult} ref={ref} onClick={onClose}>
      <span
        css={[styles.result, isActive && styles.resultActive]}
        onClick={() => onSetSelectedOption(option)}
      >
        {label}
      </span>
      {isActive && (
        <>
          <div css={styles.iconStyles}>
            <CheckIcon />
          </div>
        </>
      )}
    </div>
  );
};
