import { Functions, HttpsCallable } from 'firebase/functions';
import { atom } from 'recoil';

const functionsReference = atom<Functions | undefined>({
  key: 'firebase-functions-ref',
  default: undefined,
});

const callables = atom<Partial<Record<FirebaseFunctions, HttpsCallable>>>({
  default: {},
  key: 'firebase-callables',
});

export const firebaseAtoms = {
  callables,
  functionsReference,
};
