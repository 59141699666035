import { css } from '@emotion/react';
import { scroll } from '@styles/scroll.styles';
import { breakpoints } from '@styles/variables.styles';

const list = css`
  display: flex;
  flex-direction: column;
  color: var(--color-general-primary-1);
  gap: 16px;
  margin-top: 4rem;
  margin-bottom: 2rem;

  @media ${breakpoints.fromMed} {
    margin-left: auto;
    flex-flow: wrap;
  }

  @media ${breakpoints.toMed} {
    ${scroll.base}
  }
`;

const link = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-background-3);
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  min-height: 76px;
  padding-left: 24px;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #2a282f;
    transition: all 0.2s ease-out;
    border-radius: 12px;
  }

  @media ${breakpoints.fromMed} {
    font-size: 20px;
  }
`;

const item = css`
  @media ${breakpoints.fromMed} {
    flex: 0 0 290px;
    min-width: 290px;
    height: auto;
  }
`;

const itemActive = css`
  position: relative;
  color: var(--color-general-primary-1);

  border: 1px solid;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      89.8deg,
      rgba(169, 237, 49, 0.77) 15.78%,
      rgba(44, 238, 75, 0.77) 97.34%
    );
`;

const notActive = css`
  color: white;
  font-weight: 700;
`;

const mobile = css`
  display: block;

  li a {
    text-transform: uppercase;

    &:hover {
    }
  }
`;
const checkIcon = css`
  margin-right: 24px;
`;
export const languageSwitcherStyles = {
  list,
  item,
  itemActive,
  link,
  mobile,
  notActive,
  checkIcon,
};
