import { SerializedStyles } from '@emotion/react';
import { useUserId, useUserIdLoading } from '@modules/auth';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { styles } from '../styles';

const GlobeIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/globe.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  locale: string;
  isLanguageSwitcherModalOpen: boolean;
  isAdmin: boolean;
  isEmployee: boolean;
  openModal: () => void;
  handleAdminClick: () => string;
}

export const NavigationHeader: React.FC<Props> = ({
  locale,
  isLanguageSwitcherModalOpen,
  isAdmin,
  isEmployee,
  openModal,
  handleAdminClick,
}) => {
  const userId = useUserId();
  const userIdLoading = useUserIdLoading();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      css={styles.adminSection}
    >
      <div
        css={styles.languageBtn(isLanguageSwitcherModalOpen)}
        onClick={openModal}
      >
        <GlobeIcon viewBox="0 1 15 15" />
        <span>{locale}</span>
      </div>

      {userId && !userIdLoading ? (
        <Link
          css={styles.authenticationLink}
          passHref
          rel="noreferrer"
          href={handleAdminClick()}
        >
          {isAdmin || isEmployee ? (
            <FormattedMessage id="header.admin" />
          ) : (
            <FormattedMessage id="header.dashboard" />
          )}
        </Link>
      ) : (
        <Link
          css={styles.authenticationLink}
          passHref
          rel="noreferrer"
          href="/login"
        >
          <FormattedMessage id="header.login" />
        </Link>
      )}
    </motion.div>
  );
};
