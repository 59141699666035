import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const base = css`
  padding: 12px;
  border-radius: 8px;
  background-color: var(--color-background-3);
  color: var(--color-text-6);
  cursor: pointer;
  margin-bottom: 40px;

  @media ${breakpoints.fromLrg} {
    grid-column: 1 / span 20;
  }
`;

const flex = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
`;

const name = css`
  font-size: 20px;
  line-height: 24px;
`;

const capacity = css`
  padding: 4px 12px;
  background-color: #f4f8f70d;
  border-radius: 12px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
`;

const infoWrapper = css`
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 16px;

  span:first-of-type,
  span:last-of-type {
    color: var(--color-text-5);
  }

  span:nth-of-type(2) {
    font-weight: 700;
  }
`;

const day = css`
  width: 80px;
`;

const hour = css`
  width: 90px;
`;

const dateWrapper = css`
  display: flex;
  gap: 12px;
`;

export const styles = {
  base,
  flex,
  name,
  capacity,
  infoWrapper,
  day,
  hour,
  dateWrapper,
};
