import {
  useGroupAnalyticsListener,
  useSportAnalyticsListener,
} from '@modules/analytics';
import { useBookingListener } from '@modules/booking-new';
import {
  useFacilityRolesListener,
  usePlayoffFacilitiesListener,
  usePlayoffFacilityListener,
} from '@modules/facility';
import { useGroupsListener } from '@modules/groups';
import { usePlayoffAdminRedirects } from '@modules/users';
import { useMobile } from '@shared/hooks';
import { Footer } from '@ui/Footer';
import { MobileHeader } from '@ui/Header';
import { LoadingPage } from '@ui/LoadingPage';
import { MobileDownloadScreen } from '@ui/MobileDownloadScreen';

interface Props {
  children?: React.ReactNode;
}

export const PlayOffAdminLayout: React.FC<Props> = ({ children }) => {
  /**Facilities collection */
  usePlayoffFacilitiesListener();
  usePlayoffFacilityListener();
  useFacilityRolesListener();

  /**Groups collection */
  useGroupsListener();

  /**Bookings listener */
  useBookingListener();

  /** Analytics collection */
  useSportAnalyticsListener();
  useGroupAnalyticsListener();

  const { isMobileWidth } = useMobile();
  const { loading } = usePlayoffAdminRedirects();

  if (loading) {
    return <LoadingPage />;
  }

  if (isMobileWidth) {
    return (
      <>
        <MobileHeader />
        <MobileDownloadScreen />
        <Footer />
      </>
    );
  }

  return <>{children}</>;
};
