import { css } from '@emotion/react';

const historyContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const resultsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
`;

const noReservationsContainer = css`
  max-width: 352px;
  padding: 12px 0px 12px 16px;
  border-radius: 8px;
  background: var(--background-2, #1f1d24);

  span {
    font-size: 16px;
    color: var(--text-text-5, #c7c7d1);
    line-height: 20px;
  }
`;

export const styles = {
  resultsContainer,
  historyContainer,
  noReservationsContainer,
};
