import {
  getConvertedDate,
  useCurrentMonthMostPopularDay,
  useCurrentMonthNumberOfBookings,
  usePastMonthMostPopularDay,
  usePastMonthNumberOfBookings,
} from '@modules/analytics';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { monthlyStatisticsStyles as styles } from './monthlyStatisticsStyles';

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const MonthlyStatistics: React.FC = () => {
  const { locale } = useRouter();
  const [month, setMonth] = useState<'current' | 'past'>('current');

  const pastMonthMostPopularDay = usePastMonthMostPopularDay();
  const pastMonthNumberOfBookings = usePastMonthNumberOfBookings();
  const currentMonthMostPopularDay = useCurrentMonthMostPopularDay();
  const currentMonthNumberOfBookings = useCurrentMonthNumberOfBookings();

  return (
    <div css={styles.selectWrapper}>
      <p css={styles.monthlyLabel}>
        <FormattedMessage id="admin.analytics.monthly.statistics" />
      </p>
      <div css={styles.monthSwitcher}>
        <span
          css={[month === 'current' ? styles.currentMonth : styles.pastMonth]}
          onClick={() => setMonth('current')}
        >
          <FormattedMessage id="admin.analytics.current.month" />
        </span>
        <span
          css={[month === 'past' ? styles.currentMonth : styles.pastMonth]}
          onClick={() => setMonth('past')}
        >
          <FormattedMessage id="admin.analytics.past.month" />
        </span>
      </div>
      <AnimatePresence mode="wait">
        {month === 'current' ? (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            key="current"
            css={styles.statisticsContainer}
          >
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {currentMonthNumberOfBookings}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="admin.analytics.reservation.label" />
              </span>
            </div>
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {getConvertedDate(locale, currentMonthMostPopularDay)}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="admin.analytics.popular.label" />
              </span>
            </div>
          </motion.div>
        ) : (
          <motion.div
            key="past"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            css={styles.statisticsContainer}
          >
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {pastMonthNumberOfBookings}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="admin.analytics.reservation.label" />
              </span>
            </div>
            <div css={styles.statisticsGroup}>
              <span css={styles.statisticsValue}>
                {getConvertedDate(locale, pastMonthMostPopularDay)}
              </span>
              <span css={styles.statisticsLabel}>
                <FormattedMessage id="admin.analytics.popular.label" />
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
