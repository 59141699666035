import { css } from '@emotion/react';
import { scroll } from 'styles/scroll.styles';

export const base = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #16141bcc;
  z-index: 3;
`;

const modal = css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 440px;
  background-color: var(--color-background-3);
  border-radius: 8px;
  z-index: 999;
`;

const title = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--font-family-primarySemiBold);
  font-size: 24px;
  line-height: 32px;
  color: var(--color-text-white);
  margin-bottom: 24px;

  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 12px;
    margin-right: -12px;

    svg {
      path {
        transition: fill 0.2s ease-out;
      }
    }

    :hover {
      svg {
        path {
          fill: var(--color-text-6);
        }
      }
    }
  }
`;

const infoContainer = css`
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-border-cardBorder);
  border-radius: 16px;
  margin-bottom: 32px;
`;

const date = css`
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-4);
  text-transform: capitalize;
`;

const hoursWrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text-6);

  span {
    font-size: 16px;
    line-height: 24px;
  }
`;

const court = css`
  padding: 4px 16px;
  border-radius: 12px;
  background-color: #2e2d34;
  color: var(--color-text-5);
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  margin-top: 8px;
`;

const list = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${scroll.base}

  li {
    display: flex;
    flex-direction: column;
  }
`;

const itemTitle = css`
  font-size: 10px;
  line-height: 12px;
  color: var(--color-text-4);
  text-transform: uppercase;
  letter-spacing: 0.25em;
`;

const itemPrimary = css`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-6);
  text-transform: capitalize;
`;

const comment = css`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-6);
`;

const itemSecondary = css`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-4);

  svg {
    width: 16px;
    height: 16px;
  }
`;

const buttonWrapper = css`
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

const buttonBack = css`
  width: 100%;
  padding: 8px 20px;
  border-radius: 32px;
  background-color: var(--color-background-5);
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-5);
  border: 1px solid transparent;
  transition: all 0.2s ease-out;
  cursor: pointer;

  :hover {
    background-color: var(--color-background-6);
    border-color: var(--color-border-cardBorder);
  }
`;

const buttonCancel = css`
  ${buttonBack}
  background-color: transparent;
  color: var(--color-error);
  border: 1px solid var(--color-error);

  :hover {
    background-color: var(--color-error);
    color: var(--color-text-6);
  }
`;

const paintballContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-borderDark);
  border-radius: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const packageDescriptionContainer = css`
  display: flex;
  flex-direction: column;
`;

const packageTitle = css`
  color: white;
  font-family: 'HK Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`;

const paintballHoursWrapper = css`
  color: var(--color-text-4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
`;

const numberOfPlayersContainer = css`
  display: flex;
  padding: 4px 12px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 12px;
  background: rgba(244, 248, 247, 0.05);
  color: #c7c7d1;
  height: fit-content;
`;

export const styles = {
  base,
  modal,
  title,
  infoContainer,
  packageDescriptionContainer,
  date,
  hoursWrapper,
  paintballHoursWrapper,
  numberOfPlayersContainer,
  paintballContainer,
  court,
  list,
  itemTitle,
  itemPrimary,
  itemSecondary,
  packageTitle,
  buttonWrapper,
  comment,
  buttonBack,
  buttonCancel,
};
