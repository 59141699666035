/**List of inputs
 * Input types:
 * - Text, number, email, phone, textarea, line
 * - Radio
 * - Select, multi select
 * - Checkbox
 * - Date picker
 * - Time picker
 * - Image upload

 */

export interface Facility {
  id?: string; // Firestore document ID
  /**Multi select and search */
  amenities: string[] | null; // Array of amenities E.g. ['wifi', 'parking']
  /**Multi select */
  availablePaymentMethods: PaymentType[]; // Array of available payment methods E.g. ['inFacility', 'card']
  bookingAddOnIntervals: number[] | null; // Array of booking add-on intervals E.g. [15, 30, 45, 60]
  bookingAddOns: BookingAddOn[] | null; // Array of booking add-ons
  bookingLimits: BookingLimits | null;
  /**Text input */
  city: string;
  /**Email input */
  contactEmail: string | null;
  /** Select with search */
  country: string;
  courts: Court[];
  /**Text area inputs */
  description: string[];
  /**Working hours for different days */
  differentWorkingHours: DifferentWorkHours[] | null;
  discountConfig?: DiscountConfig | null;
  discoverCity: string | null; // NOT INPUT
  discoverPriority: 'high' | 'medium' | 'low'; // NOT INPUT
  images: string[];
  /**Number input */
  interval: number;
  /** Radio */
  isCashBookingOutsideCityEnabled: boolean;
  isLive: boolean; // NOT INPUT
  /** Radio */
  isPhoneRequired: boolean | null;
  isPrivate: boolean; // NOT INPUT
  /** Radio */
  isSmartLockAvailable: boolean;
  /** Radio */
  isTeamGameSupported?: boolean | null;
  /** Radio */
  isTournamentAvailable: boolean;
  /**Number input */
  latitude: number;
  /**Image upload */
  logo: string;
  /**Number input */
  longitude: number;
  /**Number input */
  maxAllowedFutureReservationDays: number | null;
  /**Number input */
  maxSlotsInBooking: number | null;
  /**Number input */
  minSlotsInBooking: number | null;
  /**Text input */
  name: string;
  /**Array of dates when facility is closed */
  nonWorkingDays: Date[];
  packagedBookingData: PackagedBookingData | null;
  /**Number input */
  paymentPercentage: number | null;
  /**Phone inputs */
  phone: string[] | null;
  /**Multi select */
  sports: Sport[];
  /**Text input */
  street: string;
  /**Text input */
  stripeAccountId: string | null;
  /**Radio */
  type: SportFacilityType;
  workDays: WorkDays;
  /**Text input */
  zip: string;
  createdAt: number; // NOT INPUT
  lmsLeagueConfig?: LmsLeagueConfig | null; // NOT INPUT
  stripeCancellationConfig?: StripeCancellationConfig | null;
}
export class Facility {
  constructor(data?: Facility) {
    this.id = data?.id;
    this.amenities = data?.amenities || null;
    this.availablePaymentMethods = data?.availablePaymentMethods || [];
    this.bookingAddOnIntervals = data?.bookingAddOnIntervals || null;
    this.bookingAddOns = data?.bookingAddOns || null;
    this.city = data?.city || '';
    this.contactEmail = data?.contactEmail || null;
    this.country = data?.country || '';
    this.courts = data?.courts || [];
    this.description = data?.description || [''];
    this.differentWorkingHours = data?.differentWorkingHours || null;
    this.discoverCity = data?.discoverCity || null;
    this.discoverPriority = data?.discoverPriority || 'low';
    this.images = data?.images || [];
    this.interval = data?.interval || 0;
    this.isCashBookingOutsideCityEnabled =
      data?.isCashBookingOutsideCityEnabled || false;
    this.isPrivate = data?.isPrivate || false;
    this.isTournamentAvailable = data?.isTournamentAvailable || false;
    this.latitude = data?.latitude || 0;
    this.logo = data?.logo || '';
    this.longitude = data?.longitude || 0;
    this.name = data?.name || '';
    this.nonWorkingDays = data?.nonWorkingDays || [];
    this.packagedBookingData = data?.packagedBookingData || null;
    this.phone = data?.phone || null;
    this.sports = data?.sports || [];
    this.street = data?.street || '';
    this.type = data?.type || 'sport';
    this.workDays = data?.workDays || {
      nonWorkingDays: [],
      workingHours: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      adminWorkingHours: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      differentWorkingHours: [],
      workSchedules: [],
    };
    this.zip = data?.zip || '';
    this.createdAt = data?.createdAt || +new Date();

    this.stripeAccountId = data?.stripeAccountId || null;
    this.stripeCancellationConfig = data?.stripeCancellationConfig || null;
    this.paymentPercentage = data?.paymentPercentage || null;
    this.isTeamGameSupported = data?.isTeamGameSupported || false;
    this.maxAllowedFutureReservationDays =
      data?.maxAllowedFutureReservationDays || null;
    this.minSlotsInBooking = data?.minSlotsInBooking || 1;
    this.maxSlotsInBooking = data?.maxSlotsInBooking || 1;
    this.bookingLimits = data?.bookingLimits || null;
    this.discountConfig = data?.discountConfig || null;
    this.isSmartLockAvailable = data?.isSmartLockAvailable || false;
    this.isLive = data?.isLive || false;
    this.isPhoneRequired = data?.isPhoneRequired || null;
    this.lmsLeagueConfig = data?.lmsLeagueConfig || null;
  }
}
