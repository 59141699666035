export function convertPrice(num: number) {
  if (num === 0) {
    return '0';
  }

  if (num % 1 !== 0) {
    return num?.toFixed(2);
  }

  return Math.round(num);
}
