import { AdminDashboard } from '@modules/admin';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useGroups } from '../state';
import { GroupCard } from './group-card/GroupCard';
import { styles } from './groups.styles';

export const Groups: FC = () => {
  const { formatMessage } = useIntl();
  const groups = useGroups();

  return (
    <AdminDashboard
      containerStyles={styles.container}
      mainStyles={styles.main}
      title={formatMessage({
        id: 'groups.title',
      })}
      description={formatMessage({
        id: 'groups.description',
      })}
    >
      <div css={styles.wrapper}>
        {groups.map((group) => (
          <GroupCard key={group.id} group={group} />
        ))}
      </div>
    </AdminDashboard>
  );
};
