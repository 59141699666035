import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export function useOrderDays() {
  const { formatMessage } = useIntl();

  const orderDays = useMemo(
    () => [
      formatMessage({ id: 'day.monday' }),
      formatMessage({ id: 'day.tuesday' }),
      formatMessage({ id: 'day.wednesday' }),
      formatMessage({ id: 'day.thursday' }),
      formatMessage({ id: 'day.friday' }),
      formatMessage({ id: 'day.saturday' }),
      formatMessage({ id: 'day.sunday' }),
    ],
    [formatMessage],
  );

  return { orderDays };
}
