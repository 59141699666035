import { selector, useRecoilValue, useSetRecoilState } from 'recoil';
import { analyticsAtoms } from './atoms';

/**Analytics document data */
export const analyticsSelector = selector({
  key: 'analyticsSelector',
  get: ({ get }) => get(analyticsAtoms.analytics),
  set: ({ get, set }, newValue) =>
    set(analyticsAtoms.analytics, newValue || get(analyticsAtoms.analytics)),
});

/* Analytics selector */
export const useAnalyticsSelector = () => useRecoilValue(analyticsSelector);
export const useSetAnalytics = () => useSetRecoilState(analyticsSelector);

/**Loading analytics */
export const useAnalyticsLoading = () =>
  useRecoilValue(analyticsAtoms.loadingDocument);
export const useSetAnalyticsLoading = () =>
  useSetRecoilState(analyticsAtoms.loadingDocument);

/**Error analytics */
export const useAnalyticsError = () => useRecoilValue(analyticsAtoms.error);
export const useSetAnalyticsError = () =>
  useSetRecoilState(analyticsAtoms.error);

/** Group analytics document data */
export const groupAnalyticsSelector = selector({
  key: 'groupAnalyticsSelector',
  get: ({ get }) => get(analyticsAtoms.groupAnalytics),
  set: ({ get, set }, newValue) =>
    set(
      analyticsAtoms.groupAnalytics,
      newValue || get(analyticsAtoms.groupAnalytics),
    ),
});

/* Group analytics selector */
export const useGroupAnalyticsSelector = () =>
  useRecoilValue(groupAnalyticsSelector);
export const useSetGroupAnalytics = () =>
  useSetRecoilState(groupAnalyticsSelector);
