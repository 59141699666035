import { SerializedStyles } from '@emotion/react';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import { styles } from './styles';
import { FormattedMessage } from 'react-intl';

const AppStore = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/app-store.svg'),
  {
    loading: () => null,
  },
);

const GooglePlay = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/google-play.svg'),
  {
    loading: () => null,
  },
);

export const MobileDownloadScreen: React.FC = () => {
  return (
    <>
      <main css={[styles.base, styles.mobileContainer]}>
        <motion.div
          style={{
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
          css={styles.form}
        >
          <motion.h1
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, type: 'spring' }}
            css={styles.title}
          >
            <FormattedMessage id="ui.mobile-download-screen.title" />
          </motion.h1>
          <motion.p
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.2, type: 'spring' }}
            css={styles.description}
          >
            <FormattedMessage id="ui.mobile-download-screen.description" />
          </motion.p>

          <motion.div
            css={styles.storeContainer}
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.2, type: 'spring' }}
          >
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.8 }}
              href="https://play.google.com/store/apps/details?id=com.sportskiobjekti.app&pli=1"
            >
              <GooglePlay viewBox="0 0 138 44" />
            </motion.a>
            <motion.a
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6, duration: 0.8 }}
              href="https://apps.apple.com/us/app/playoff/id6449152333"
            >
              <AppStore viewBox="0 0 138 44" />
            </motion.a>
          </motion.div>
        </motion.div>
      </main>
    </>
  );
};
