import { selector, useRecoilValue } from 'recoil';
import { authAtoms } from './atoms';

export const isPlayOffAdmin = selector({
  key: 'isPlayOffAdmin',
  get: ({ get }) => {
    const claims = get(authAtoms.claims);
    const isPlayoffAdmin = claims?.some(
      (claim) => claim.role === 'playoff-admin',
    );

    return isPlayoffAdmin;
  },
});

export const useIsPlayOffAdmin = () => useRecoilValue(isPlayOffAdmin);
