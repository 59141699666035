import { SerializedStyles } from '@emotion/react';
import { useAuthForm, useFirebaseAuth } from '@modules/auth';
import { useSubmitOnEnter } from '@shared/hooks';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput/FormInput';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const IDCard = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/id_card.svg'),
  {
    loading: () => null,
  },
);

const Lock = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/lock.svg'),
  {
    loading: () => null,
  },
);

const GoogleIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/google.svg'),
  {
    loading: () => null,
  },
);

export const RegisterForm: React.FC = () => {
  const ref = useSubmitOnEnter<HTMLButtonElement>();

  const { formatMessage } = useIntl();
  const { handleGoogleLogin } = useFirebaseAuth();
  const { formik, loading } = useAuthForm('signup');

  return (
    <motion.form
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
      css={styles.formStyles}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <FormInput
        type="email"
        label={formatMessage({ id: 'register-form.email.label' })}
        id="email"
        name="email"
        Icon={Mail}
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched}
        isRequired
        placeholder={formatMessage({ id: 'register-form.email.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <FormInput
        type="text"
        label={formatMessage({ id: 'register-form.name.label' })}
        id="firstName"
        name="firstName"
        Icon={IDCard}
        touched={formik.touched}
        value={formik.values.firstName}
        isRequired
        placeholder={formatMessage({ id: 'register-form.name.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <FormInput
        type="text"
        label={formatMessage({ id: 'register-form.surname.label' })}
        id="lastName"
        name="lastName"
        isRequired
        Icon={IDCard}
        touched={formik.touched}
        value={formik.values.lastName}
        placeholder={formatMessage({ id: 'register-form.surname.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {/* <div>
        <p css={styles.nicknameLabelExtra}>
          <FormattedMessage id="register-form.nickname.extra.label" />
        </p>
        <FormInput
          type="text"
          label={formatMessage({ id: 'register-form.nickname.label' })}
          id="nickname"
          name="nickname"
          Icon={User}
          touched={formik.touched}
          isRequired
          placeholder={formatMessage({
            id: 'register-form.nickname.placeholder',
          })}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </div> */}

      <FormInput
        placeholder={formatMessage({
          id: 'register-form.password.placeholder',
        })}
        type="password"
        label={formatMessage({ id: 'register-form.password.label' })}
        id="password"
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched}
        Icon={Lock}
        isRequired
        value={formik.values.password}
        error={formik.errors.password}
        isPasswordInput
      />

      <div>
        <p css={styles.switchText}>
          <FormattedMessage id="register-form.tos&pp" />
          <Link href="/terms-of-service">
            <FormattedMessage id="register-form.tos" />
          </Link>
          <FormattedMessage id="register-form.tos.and" />{' '}
          <Link href="/privacy-policy">
            <FormattedMessage id="register-form.privacy-policy" />
          </Link>
          .
        </p>
      </div>
      <div css={styles.ctaButtons}>
        <Button
          disabled={loading}
          type="submit"
          customStyles={styles.formButton}
          ref={ref}
        >
          <FormattedMessage id="register-form.button" />
        </Button>
        {/* <SocialLogin /> */}
      </div>

      <div css={styles.otherAccLoginContainer}>
        <p>
          <FormattedMessage id="login-form.other.description" />
        </p>
        <div css={styles.registerBtnContainer}>
          <Button
            onClick={handleGoogleLogin}
            disabled={loading}
            customStyles={styles.registerBtn}
          >
            <GoogleIcon viewBox="0 1 15 15" />
            <FormattedMessage id="login-form.google.label" />
          </Button>
          {/* <Button disabled={loading} customStyles={styles.registerBtn}>
            <FacebookIcon viewBox=" 2 1 15 16" />
            <FormattedMessage id="login-form.facebook.label" />
          </Button> */}
        </div>
      </div>
    </motion.form>
  );
};
