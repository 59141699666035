import { FirebaseService } from '@modules/firebase';
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { toast } from 'sonner';
import { bookingFirestoreConverter } from '../utils';

/**
 * Provides functions to create, read, update, and delete a booking document in Firestore
 * @param {string | null} userUid The user's UID
 * @returns An object containing functions to create, read, update, and delete a booking document
 * @property {function} createBooking - Creates a new booking document in Firestore
 * @property {function} updateBooking - Updates an existing booking document in Firestore
 * @property {function} deleteBooking - Deletes an existing booking document in Firestore
 * @property {function} getBooking - Gets an existing booking document from Firestore
 */

export const useBookingsDocument = (bookingId?: string) => {
  /**
   * Creates a new booking document in Firestore
   * @param newBooking The new booking data
   */
  const createBooking = async (newBooking: Booking) => {
    try {
      const bookinsRef = collection(FirebaseService.firestore, 'bookings');
      const bookingData = bookingFirestoreConverter.toFirestore(newBooking);
      const docRef = await addDoc(bookinsRef, bookingData);

      return docRef.id;
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Updates an existing bookings document in Firestore
   * @param updateFirebaseService.firestoreooking The updated bookings data
   */
  const updateBooking = async (booking: Booking) => {
    try {
      const bookinsRef = doc(FirebaseService.firestore, 'bookings', booking.id);
      const bookingData = bookingFirestoreConverter.toFirestore(booking);
      await setDoc(bookinsRef, bookingData);
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Delete an existing booking document in Firestore
   */
  const deleteBookingByStatus = async (bookingId: string) => {
    try {
      const bookinsRef = doc(FirebaseService.firestore, 'bookings', bookingId);
      await updateDoc(bookinsRef, {
        status: 'cancelled',
        canceledBy: 'admin',
      });
    } catch (error) {
      toast.error(JSON.stringify(error));
    }
  };

  /**
   * Gets an existing booking document from Firestore
   */
  const getBooking = async (): Promise<Booking | undefined> => {
    try {
      const bookinsRef = doc(FirebaseService.firestore, 'bookings', bookingId);
      const bookingSnapshot = await getDoc(bookinsRef);
      if (!bookingSnapshot.exists()) {
        throw new Error("Booking snapshot doesn't exist");
      }
      const booking = bookingFirestoreConverter.fromFirestore(bookingSnapshot);
      return booking;
    } catch (error) {
      toast.error(JSON.stringify(error));
      return undefined;
    }
  };

  return {
    createBooking,
    updateBooking,
    deleteBookingByStatus,
    getBooking,
  };
};
