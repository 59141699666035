import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import FooterLogo from 'public/logo/playoff-logo-108.svg';
import { useIntl } from 'react-intl';
import { styles } from './styles';

export const Banner = () => {
  const { formatMessage } = useIntl();

  return (
    <section css={styles.base}>
      <div css={styles.contentWrapper}>
        <div css={styles.leftSide}>
          <motion.div
            css={styles.logoWrapper}
            {...animations.opacityInView({ delay: 0.2 })}
          >
            <Link
              css={styles.anchor}
              href="/"
              target="_self"
              rel="noopener noreferrer"
              aria-label="PlayOff"
            >
              <FooterLogo css={styles.companyName} />
            </Link>
          </motion.div>
          <div css={styles.textWrapper}>
            <h1 css={styles.title}>
              <AnimatedText
                text={formatMessage({ id: 'banner.title' })}
                delay={0.2}
              />
            </h1>
            <p css={styles.description}>
              <AnimatedText
                text={formatMessage({ id: 'banner.description' })}
                delay={0.2}
              />
            </p>
          </div>
          <motion.div
            css={styles.storeImagesWrapper}
            {...animations.opacityInView({ delay: 0.4 })}
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div css={styles.overlay} />
            <a
              href="https://play.google.com/store/apps/details?id=com.sportskiobjekti.app"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src="/images/google-play.jpg"
                alt="Google Play"
                width={148}
                style={{ width: '148px', height: '52px' }}
                height={52}
                loading="lazy"
              />
            </a>
            <div css={styles.overlay} />
            <a
              href="https://apps.apple.com/hr/app/playoff/id6449152333"
              target="_blank"
              rel="noreferrer noopener"
            >
              <Image
                src="/images/app-store.png"
                alt="App Store"
                style={{ width: '148px', height: '52px' }}
                width={148}
                height={52}
                loading="lazy"
              />
            </a>
          </motion.div>
          <motion.div
            css={styles.qrWrapper}
            {...animations.opacityInView({ delay: 0.4 })}
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <div css={styles.overlay} />
            <Image
              src="/images/google-play-QR.png"
              alt="Wep app"
              width={110}
              height={110}
              loading="lazy"
            />
            <div css={styles.overlay} />
            <Image
              src="/images/apple-store-QR.png"
              alt="Wep app"
              width={110}
              height={110}
              loading="lazy"
            />
          </motion.div>
        </div>
        <motion.div
          css={styles.rightSide}
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1.5,
            delay: 0.8,
            ease: [0.08, 0.83, 0.17, 1],
          }}
        >
          <motion.div css={styles.overlay} />
          <Image
            src="/images/banner-host-calendar.png"
            alt="Wep app"
            width={440}
            height={276}
            style={{ width: '100%', height: 'auto' }}
            css={styles.rightImage}
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};
