import { useGroups } from '@modules/groups/state';
import { useScrollIntoView } from '@shared/hooks';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CustomPlaceholder,
  CustomSelectOption,
  CustomSelectValue,
} from './components';
import { selectProps, styles } from './styles';

interface Props {
  selectedOption: MultiValue<GroupOption> | SingleValue<GroupOption> | null;
  onSetSelectedOption: React.Dispatch<
    React.SetStateAction<MultiValue<GroupOption> | null>
  >;
}

export const GroupsSelect: React.FC<Props> = ({
  selectedOption,
  onSetSelectedOption,
}) => {
  const groups = useGroups();
  const { handleFocus } = useScrollIntoView();

  const groupOptions = useMemo(
    () =>
      groups.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    [groups],
  );

  const handleChange = (newValue: MultiValue<GroupOption>) => {
    onSetSelectedOption(newValue);
  };

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="users-management-form.groups.label" />
        <span css={styles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        placeholder={<CustomPlaceholder />}
        options={groupOptions}
        onFocus={handleFocus}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        value={selectedOption}
        onChange={handleChange}
        isMulti
        isSearchable
      />
    </div>
  );
};
