import { SerializedStyles } from '@emotion/react';
import { SocialLogin, useAuthForm } from '@modules/auth';
import { useSubmitOnEnter } from '@shared/hooks';
import { Button } from '@ui/Button';
import { FormInput } from '@ui/FormInput/FormInput';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { signupFormStyles as styles } from './signupFormStyles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const Lock = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/lock.svg'),
  {
    loading: () => null,
  },
);

export const SignupForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { formik, loading } = useAuthForm('login');
  const ref = useSubmitOnEnter<HTMLButtonElement>();

  return (
    <motion.form
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
      css={styles.formStyles}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <FormInput
        type="email"
        label={formatMessage({ id: 'signup-form.email.label' })}
        id="email"
        name="email"
        Icon={Mail}
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched}
        isRequired
        placeholder={formatMessage({ id: 'signup-form.email.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <FormInput
        placeholder={formatMessage({ id: 'signup-form.password.placeholder' })}
        type="password"
        label={formatMessage({ id: 'signup-form.password.label' })}
        id="password"
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched}
        Icon={Lock}
        isRequired
        value={formik.values.password}
        error={formik.errors.password}
        isPasswordInput
      />

      <div css={styles.ctaButtons}>
        <Button
          disabled={loading}
          type="submit"
          customStyles={styles.formButton}
          ref={ref}
        >
          <FormattedMessage id="signup-form.button" />
        </Button>
        <SocialLogin />
      </div>

      <p css={styles.switchText}>
        <FormattedMessage id="signup-form.forgot.button" />
        <Link href={`forgot-password`}>
          <FormattedMessage id="signup-form.reset.link" />
        </Link>
      </p>
    </motion.form>
  );
};
