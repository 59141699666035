import { useActiveCourt, useCurrentDate } from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { FC, useMemo } from 'react';
import { BookingWeekSummary } from '../booking-week-summary';
import { SingleDay } from '../single-day/SingleDay';
import { styles } from './bookingWeekSlots.styles';

export const BookingWeekSlots: FC = () => {
  const currentDate = useCurrentDate();
  const fascility = useFacility();
  const activeCourt = useActiveCourt();
  const timeRange = useMemo(() => {
    const court = fascility.courts.find(
      (court) => court.id === activeCourt?.id,
    );
    return court?.adminTimeRanges?.[0] || court?.timeRanges?.[0];
  }, [fascility.courts, activeCourt?.id]);
  const { adminWorkingHours, workingHours, workSchedules } = useMemo(
    () => fascility.workDays,
    [fascility.workDays],
  );
  const hours = (day: string) =>
    adminWorkingHours?.[day] || workingHours?.[day];

  return (
    <div css={styles.base}>
      <SingleDay
        currentDate={currentDate}
        dayNumber={1}
        hours={hours('Monday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={2}
        hours={hours('Tuesday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={3}
        hours={hours('Wednesday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={4}
        hours={hours('Thursday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={5}
        hours={hours('Friday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={6}
        hours={hours('Saturday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <SingleDay
        currentDate={currentDate}
        dayNumber={7}
        hours={hours('Sunday')}
        interval={fascility.interval}
        timeRange={timeRange}
        workSchedules={workSchedules}
      />
      <BookingWeekSummary />
    </div>
  );
};
