import { ChangeEvent, useEffect } from 'react';
import {
  useIsRepeatChecked,
  useRepeatDuration,
  useRepeatError,
  useSetIsRepeatChecked,
  useSetRepeatDuration,
  useSetRepeatError,
} from '../state';

export function useRepeat() {
  const isRepeatChecked = useIsRepeatChecked();
  const repeatDuration = useRepeatDuration();
  const hasError = useRepeatError();
  const setIsRepeatChecked = useSetIsRepeatChecked();
  const setRepeatDuration = useSetRepeatDuration();
  const setRepeatError = useSetRepeatError();

  useEffect(() => {
    return () => {
      setIsRepeatChecked(false);
      setRepeatDuration(4);
    };
  }, [setIsRepeatChecked, setRepeatDuration]);

  function handleOnChange({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) {
    const numberValue = Number(value);

    if (numberValue < 1 || !numberValue) {
      setRepeatError('min');
      setRepeatDuration(numberValue);
      return;
    }

    if (numberValue > 52) {
      setRepeatError('max');
      setRepeatDuration(numberValue);
      return;
    }
    setRepeatError(null);
    setRepeatDuration(numberValue);
  }

  useEffect(() => {
    if (!isRepeatChecked) {
      setRepeatError(null);
      return;
    }

    setRepeatDuration(4);
  }, [isRepeatChecked, setRepeatError, setRepeatDuration]);

  return {
    isRepeatChecked,
    repeatDuration,
    hasError,
    setIsRepeatChecked,
    setRepeatDuration,
    handleOnChange,
  };
}
