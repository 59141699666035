import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
} from 'firebase/firestore';

/**
 * Converts FacilityAnalytics to and from Firestore.
 */
export const sportAnalyticsFirestoreConverter: FirestoreDataConverter<FacilityAnalytics> =
  {
    /**
     * Converts a FacilityAnalytics object to Firestore format.
     *
     * @param {FacilityAnalytics} analytics The FacilityAnalytics object to convert.
     * @returns {Object} The Firestore representation of the FacilityAnalytics object.
     */
    toFirestore: (analytics: FacilityAnalytics): object => {
      return {
        bookings: analytics.bookings,
        hotspots: analytics.hotspots,
      };
    },

    /**
     * Converts a Firestore snapshot to a FacilityAnalytics object.
     *
     * @param {QueryDocumentSnapshot<FacilityAnalytics>} snapshot The Firestore snapshot to convert.
     * @returns {FacilityAnalytics} The FacilityAnalytics object created from the snapshot data.
     */
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<FacilityAnalytics>,
    ): FacilityAnalytics => {
      return {
        bookings: snapshot.data().bookings,
        hotspots: snapshot.data().hotspots,
      };
    },
  };

interface GroupAnalytics {
  groupsData: GroupMembersDataPoint[];
}

interface GroupMembersDataPoint {
  date: string;
  groupId: string;
  numberOfMembers: number;
  totalIncome: number;
}

export const groupAnalyticsFirestoreConverter: FirestoreDataConverter<GroupAnalytics> =
  {
    toFirestore: (analytics: GroupAnalytics): object => {
      return {
        groupsData: analytics.groupsData,
      };
    },
    fromFirestore: (
      snapshot: QueryDocumentSnapshot<GroupAnalytics>,
    ): GroupAnalytics => {
      return {
        groupsData: snapshot.data().groupsData,
      };
    },
  };
