import { SerializedStyles } from '@emotion/react';
import {
  convertPrice,
  useBookingSlotStates,
  useSetCurrentBookings,
} from '@modules/booking-new';
import { display } from '@styles/utils.display.styles';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { styles } from './bookingSlot.styles';

const Trash = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/trash-16-danger.svg'),
  {
    loading: () => null,
  },
);

const Checked = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/checked-icon.svg'),
  {
    loading: () => null,
  },
);

const Repeat = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/repeat.svg'),
  {
    loading: () => null,
  },
);

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

interface Props {
  from: Date;
  to: Date;
  index: number;
  isAvailable?: boolean;
  price?: number;
  isLast?: boolean;
}

export const DesctructiveBookingSlot: FC<Props> = ({
  from,
  to,
  index,
  isAvailable,
  price,
  isLast,
}) => {
  const {
    shortenedName,
    shortenedOpponentName,
    email,
    isExpired,
    isReserved,
    isActive,
    isInProgress,
    isTournamentGame,
    isRepeat,
    facilityName,
    id,
    price: bookedPrice,
    minSlotsInBooking,
  } = useBookingSlotStates(from, to);
  const { formatMessage } = useIntl();
  const { handleCurrentBookedHours } = useSetCurrentBookings({
    from,
    to,
    price: bookedPrice,
    id,
  });

  if (!isAvailable) {
    return (
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2, delay: 0.05 * index }}
        css={styles.baseDisabled(
          format(from, 'HHmm'),
          !isLast && format(to, 'HHmm'),
        )}
      />
    );
  }

  return (
    <AnimatePresence>
      <motion.button
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2, delay: 0.05 * index }}
        onClick={() => handleCurrentBookedHours(isActive)}
        css={
          isActive
            ? styles.baseDestructiveActive(
                format(from, 'HHmm'),
                !isLast && format(to, 'HHmm'),
              )
            : styles.baseDestructive(
                format(from, 'HHmm'),
                !isLast && format(to, 'HHmm'),
              )
        }
        disabled={!isReserved}
      >
        <div css={styles.time}>
          <span>
            {isInProgress && <div css={styles.dot} />}
            {format(from, 'HH:mm')}
          </span>
          {isTournamentGame && (
            <span css={styles.pyramidDestructive}>
              {facilityName === 'SQUASHer'
                ? 'SQUASHer'
                : formatMessage({
                    id: 'booking-slot.pyramid',
                  })}
            </span>
          )}
          <div css={!isReserved ? display.none : styles.destructiveIcon}>
            {isActive ? (
              <Checked viewBox="0 0 26 26" css={styles.checkedNegative} />
            ) : (
              <Trash viewBox="0 0 16 16" css={styles.trash} />
            )}
          </div>
        </div>
        <span css={isRepeat ? styles.nameWithSpace : styles.name}>
          {shortenedName ||
            email ||
            (isExpired &&
              formatMessage({
                id: 'booking-slot.expired',
              })) ||
            `€${convertPrice(price / minSlotsInBooking)}`}
          {shortenedOpponentName && ` - ${shortenedOpponentName}`}
        </span>
        {isRepeat && <Repeat css={styles.repeat} viewBox="0 0 16 16" />}
      </motion.button>
    </AnimatePresence>
  );
};
