import { useDefaultClaim } from '@modules/auth';
import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export const useSetRole = () => {
  const defaultClaim = useDefaultClaim();
  const { formatMessage } = useIntl();
  const setRoleCallable = useFunction<AddRoleData>('setRole');

  async function setFacilityRole(
    { email, facilityId, role, displayName, groupIds }: AddRoleData,
    label: string,
  ) {
    if (!setRoleCallable) return;

    try {
      await setRoleCallable({
        email,
        facilityId,
        role,
        userRole: defaultClaim.role,
        displayName,
        groupIds,
      });
      toast.success(
        formatMessage(
          { id: 'toasts.users.set-role' },
          {
            email,
            role: label.toLocaleLowerCase(),
          },
        ),
      );
      return;
    } catch {
      toast.error(formatMessage({ id: 'toasts.users.set-role.error' }));
    }
  }

  return {
    setFacilityRole,
  };
};
