import { useFunction } from '@modules/firebase';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

export function useAddPlayoffAdmin() {
  const { formatMessage } = useIntl();
  const addPlayoffAdminCallable =
    useFunction<AddPlayoffAdminData>('addPlayoffAdmin');

  async function addPlayoffAdmin({ email }: AddPlayoffAdminData) {
    if (!addPlayoffAdminCallable) return;
    try {
      await addPlayoffAdminCallable({
        email,
      });
      toast.success(formatMessage({ id: 'toasts.playoff.success' }));
      return;
    } catch {
      toast.error(formatMessage({ id: 'toasts.playoff.error' }));
      return;
    }
  }

  return {
    addPlayoffAdmin,
  };
}
