import { safeLog } from '@shared/utils';
import { FirebaseError } from 'firebase/app';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  NextOrObserver,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  deleteUser,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import { toast } from 'sonner';
import { IFirebaseService } from '../models/FirebaseService';
import { getLoginErrorMessage } from '../utils';
import { FirebaseService } from './../../firebase/initFirebase';

export class FirebaseAuthService implements IFirebaseService {
  /**
   * Create new account with email and password
   * @name register
   * @description Function that creates new firebase account in Firebase Authentication.
   */
  private googleProvider = new GoogleAuthProvider();
  private facebookProvider = new FacebookAuthProvider();
  private _auth = FirebaseService.auth;

  async register(email: string, password: string): Promise<UserCredential> {
    try {
      const credential = await createUserWithEmailAndPassword(
        this._auth,
        email,
        password,
      );
      safeLog('Credential', { credential });

      return credential;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Login user with email and password
   * @name login
   * @description Function that logins user who has previously created account.
   */

  async login(email: string, password: string): Promise<void> {
    try {
      await signInWithEmailAndPassword(this._auth, email, password);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Logout user
   * @name logout
   * @description Function that logouts currently logged user.
   */
  async logout(): Promise<void> {
    try {
      await signOut(this._auth);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Send forgot password link to user email
   * @name resetPassword
   * @description Function that sends reset password link to user email.
   */

  async forgotPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(this._auth, email);
    } catch (error) {
      throw error;
    }
  }

  /**
   * Create new account or login user with Google
   * @name loginWithGoogle
   * @description Function that creates new firebase account in Firebase Authentication or logs in user with Google Provider.
   */

  async loginWithGoogle(): Promise<void> {
    try {
      await signInWithRedirect(this._auth, this.googleProvider);
    } catch (error) {
      safeLog('Error', { error });
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        toast.error(getLoginErrorMessage(errorCode));
      }
    }
  }

  /**
   * Create new account or login user with Facebook
   * @name loginWithFacebook
   * @description Function that creates new firebase account in Firebase Authentication or logs in user with Facebook Provider.
   * Set app in Facebook Developers, read firebase docs.
   */

  async loginWithFacebook(): Promise<void> {
    try {
      await signInWithPopup(this._auth, this.facebookProvider);
    } catch (error) {
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        toast.error(getLoginErrorMessage(errorCode));
      }
    }
  }

  /**
   * Delete account for currently logged user
   * @name deleteAccount
   * @description Function that deleted firebase account.
   */

  async deleteAccount(): Promise<void> {
    try {
      const user = this._auth.currentUser;
      if (!user) return;

      await deleteUser(user);
    } catch (error) {
      if (error instanceof FirebaseError) toast.error(error.message);
    }
  }

  subscribeToAuth = (user: NextOrObserver<User | null>) =>
    this._auth.onAuthStateChanged(user);
}
