import { useIsPlayOffAdmin } from '@modules/auth';
import { FirebaseService } from '@modules/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import {
  useCurrentFacilityId,
  useFacilities,
  useSetFacility,
  useSetFacilityError,
} from '../state';
import { facilityFirestoreConverter } from '../utils';

/**This hook is used to listen on current facility in play-off admin mode */
export function usePlayoffFacilityListener() {
  const facilities = useFacilities();
  const isPlayOffAdmin = useIsPlayOffAdmin();
  const currentFacilityId = useCurrentFacilityId();

  const setFacility = useSetFacility();
  const setError = useSetFacilityError();

  useEffect(() => {
    if (!isPlayOffAdmin || !facilities?.length) return;

    const document = doc(FirebaseService.firestore, 'facilities', currentFacilityId).withConverter(
      facilityFirestoreConverter,
    );

    const unsubscribe = onSnapshot(
      document,
      (snapshot) => {
        setFacility(snapshot.data() || null);
      },
      (error) => setError(error.message),
    );

    return () => unsubscribe();
  }, [isPlayOffAdmin, facilities, currentFacilityId]);
}
