interface Props {
  duration: number;
  label: {
    first: string;
    second: string;
    third: string;
  };
}

export function getWeekInCroatian({ duration, label }: Props): string {
  const lastDigit = duration % 10;
  const secondLastDigit = Math.floor((duration % 100) / 10);

  if (lastDigit === 1 && secondLastDigit !== 1) {
    return label.first;
  } else if (lastDigit >= 2 && lastDigit <= 4 && secondLastDigit !== 1) {
    return label.second;
  } else {
    return label.third;
  }
}
