import { css } from '@emotion/react';

const container = css`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  align-items: flex-start;
  padding: 20px 16px;
  gap: 10px;

  background: #24222a;
  color: #c7c7d1;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  border: 1px solid rgba(248, 248, 248, 0.08);
  border-radius: 10px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const pastContainer = css`
  background: #1f1d24;
  opacity: 0.6;
  pointer-events: none;
`;

const headerContainer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const cancelContainer = css`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const cancel = css`
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #eb5757;
  padding: 0;
  cursor: pointer;
`;

const bookingInfo = css`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const bookingDate = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
`;

const bookingTime = css`
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
`;

const name = css`
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80%;

  gap: 12px;
`;

const bookingInfoContainer = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const pyramid = css`
  padding: 4px 12px;
  color: var(--color-general-primary);
  background-color: var(--color-background-4);
  border-radius: 12px;
  color: #ffac1c;
`;

export const styles = {
  container,
  headerContainer,
  cancelContainer,
  cancel,
  bookingInfo,
  bookingDate,
  bookingTime,
  pastContainer,
  name,
  pyramid,
  bookingInfoContainer,
};
