import { SerializedStyles } from '@emotion/react';
import { useClickOutsideElement, useCloseOnEscape } from '@shared/hooks';
import { Button } from '@ui/Button';
import { LanguageSwitcher } from '@ui/LanguageSwitcher';
import { animations } from 'const';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

const CloseIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/close.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  handleCloseModal: () => void;
  clickOutside: () => void;
}

export const LanguageSwitcherModal: React.FC<Props> = ({
  handleCloseModal,
  clickOutside,
}) => {
  const ref = useCloseOnEscape<HTMLButtonElement>();
  const closeRef = useClickOutsideElement<HTMLDivElement>(clickOutside);

  return (
    <AnimatePresence>
      <motion.div css={styles.wrapper} {...animations.opacity({})}>
        <div css={styles.base} ref={closeRef}>
          <div css={styles.columnOne}>
            <div css={styles.header}>
              <h2 css={styles.title}>
                <FormattedMessage id="language-switcher-modal.title" />
              </h2>
              <Button
                ref={ref}
                customStyles={styles.buttonClose}
                onClick={handleCloseModal}
              >
                <CloseIcon viewBox="0 0 24 24" />
              </Button>
            </div>

            <LanguageSwitcher isMobile={false} />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
