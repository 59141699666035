import { subMonths } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { selector, useRecoilValue } from 'recoil';
import { groupAnalytics } from './atoms';

const currentDate = utcToZonedTime(new Date(), 'Europe/Zagreb');
const lastMonthDate = subMonths(currentDate, 1);
const twoMonthsAgoDate = subMonths(currentDate, 2);

const formattedCurrentDate = format(currentDate, 'yyyy-MM');
const formattedLastMonthDate = format(lastMonthDate, 'yyyy-MM');
const formattedTwoMonthsAgoDate = format(twoMonthsAgoDate, 'yyyy-MM');

/** Sum of all bookings */
const currMonthNumberOfMembersSelector = selector({
  key: 'totalNumberOfMembersSelector',
  get: ({ get }) => {
    const analytics: GroupAnalytics = get(groupAnalytics);

    const totalNumberOfMembers = analytics?.groupsData
      ?.filter((group) => {
        return group.date === formattedCurrentDate;
      })
      ?.reduce((sum, group) => {
        return sum + group.numberOfMembers;
      }, 0);

    return totalNumberOfMembers || 0;
  },
});

const lastMonthNumberOfMembersSelector = selector({
  key: 'lastMonthNumberOfMembersSelector',
  get: ({ get }) => {
    const analytics: GroupAnalytics = get(groupAnalytics);
    const lastMonthTotalNumberOfMembers = analytics?.groupsData
      ?.filter((group) => {
        return group.date === formattedLastMonthDate;
      })
      ?.reduce((sum, group) => {
        return sum + group.numberOfMembers;
      }, 0);

    return lastMonthTotalNumberOfMembers || 0;
  },
});

const currentMonthGrowthSelector = selector({
  key: 'currentMonthGrowthSelector',
  get: ({ get }) => {
    const currentMonthGrowth =
      get(currMonthNumberOfMembersSelector) -
      get(lastMonthNumberOfMembersSelector);

    return currentMonthGrowth || 0;
  },
});

const twoMonthsAgoNumberOfMembersSelector = selector({
  key: 'twoMonthsAgoNumberOfMembersSelector',
  get: ({ get }) => {
    const analytics: GroupAnalytics = get(groupAnalytics);
    const twoMonthsAgoTotalNumberOfMembers = analytics?.groupsData

      ?.filter((group) => {
        return group.date === formattedTwoMonthsAgoDate;
      })
      ?.reduce((sum, group) => {
        return sum + group.numberOfMembers;
      }, 0);

    return twoMonthsAgoTotalNumberOfMembers || 0;
  },
});

const lastMonthGrowthSelector = selector({
  key: 'lastMonthGrowthSelector',
  get: ({ get }) => {
    const lastMonthGrowth =
      get(lastMonthNumberOfMembersSelector) -
      get(twoMonthsAgoNumberOfMembersSelector);

    return lastMonthGrowth || 0;
  },
});

/**Monthly statistics */
export const useCurrMonthNumberOfMembers = () =>
  useRecoilValue(currMonthNumberOfMembersSelector);
export const useLastMonthNumberOfMembers = () =>
  useRecoilValue(lastMonthNumberOfMembersSelector);
export const useCurrentMonthGrowth = () =>
  useRecoilValue(currentMonthGrowthSelector);
export const useLastMonthGrowth = () => useRecoilValue(lastMonthGrowthSelector);
