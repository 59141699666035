import RoleIcon from 'public/icons/role-16.svg';
import { GroupBase, SingleValueProps } from 'react-select';
import { styles } from './styles';

export const CustomSelectValue = (
  props: SingleValueProps<GroupOption, boolean, GroupBase<GroupOption>>,
) => (
  <div css={styles.valueContainer}>
    <RoleIcon />
    <span css={styles.placeholder}>{props.data.label}</span>
  </div>
);
