import { css } from '@emotion/react';

const spinnerStyle = css`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #0077ff;
  border-top-color: transparent;
  animation: spin 0.6s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

export const loadingSpinnerStyles = {
  spinnerStyle,
};
