import { css } from '@emotion/react';

const toastWrapper = css`
  font-family: var(--font-family-primary) !important;
  font-size: 14px !important;
  line-height: 20px !important;

  button:first-of-type {
    position: absolute !important;
    top: 0 !important;
    right: -8px !important;
    left: auto !important;
  }
`;

export const styles = {
  toastWrapper,
};
