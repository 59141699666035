import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const container = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-auto-rows: auto;
  width: 100%;
`;

const main = css`
  grid-column: 1 / 11;
`;

const wrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;

  @media ${breakpoints.fromLrg} {
    ${grid.basic40col};
  }
`;

export const styles = { main, container, wrapper };
