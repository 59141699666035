import { useScrollIntoView } from '@shared/hooks';
import { formInputStyles } from '@ui/FormInput/styles';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CustomPlaceholder,
  CustomSelectOption,
  CustomSelectValue,
} from './components';
import { NumberOfPlayersOption } from './models';
import { selectProps, styles } from './styles';

interface Props {
  selectedOption:
    | MultiValue<NumberOfPlayersOption>
    | SingleValue<NumberOfPlayersOption>
    | null;
  availableUserNumbers: number[];
  onSetSelectedOption: React.Dispatch<
    React.SetStateAction<SingleValue<NumberOfPlayersOption> | null>
  >;
}

export const NumberOfPlayersSelect: React.FC<Props> = ({
  selectedOption,
  availableUserNumbers,
  onSetSelectedOption,
}) => {
  const opponentOptions = useMemo(
    () =>
      availableUserNumbers?.map((num) => ({
        value: num,
        label: `${num}`,
      })),
    [availableUserNumbers],
  );

  const handleChange = (newValue: SingleValue<NumberOfPlayersOption>) => {
    onSetSelectedOption(newValue);
  };
  const { handleFocus } = useScrollIntoView();

  return (
    <div css={styles.wrapper}>
      <label css={styles.label}>
        <FormattedMessage id="number-of-players-select.label" />
        <span css={formInputStyles.labelStar}>*</span>
      </label>
      <Select
        {...selectProps}
        placeholder={<CustomPlaceholder />}
        options={opponentOptions}
        onFocus={handleFocus}
        components={{
          Option: CustomSelectOption,
          SingleValue: CustomSelectValue,
          DropdownIndicator: () => null,
        }}
        value={selectedOption}
        onChange={handleChange}
        menuShouldScrollIntoView={false}
      />
    </div>
  );
};
