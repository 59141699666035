import { useBookingHelpers } from './useBookingHelpers';

interface List {
  from: Date;
}

/**This function checks the time range of the facility and the time range of the court and returns the time range of the
 * court for the current day with which we create the gird of the day*/

export function useGenerateGrid(
  interval: number,
  currentDay: Date,
  timeRange?: TimeRange,
) {
  const { convertDateHoursOfOtherDate } = useBookingHelpers();

  if (!timeRange) return {};

  const start = convertDateHoursOfOtherDate(
    new Date(timeRange.startTime),
    currentDay,
  );

  const end = convertDateHoursOfOtherDate(
    new Date(timeRange.endTime),
    currentDay,
  );

  if (start.getHours() >= end.getHours()) {
    end.setDate(end.getDate() + 1);
  }

  const gridList: List[] = [];

  while (start < end) {
    const nextDateTime = new Date(start);
    start.setMinutes(start.getMinutes() + interval);

    gridList.push({
      from: new Date(nextDateTime),
    });
  }

  return { gridList };
}
