import {
  useCurrentBookedHours,
  useCurrentToDeleteBookedHours,
  useIsDestructiveMode,
  useRepeatError,
  useResetCurrentBookedHours,
  useResetCurrentToDeleteBookedHours,
  useSetIsModalOpen,
} from '@modules/booking-new';
import { Button } from '@ui/Button';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './bookingSummaryButtons.style';

export const BookingSummaryButtons: FC = () => {
  const isDestructive = useIsDestructiveMode();
  const currentBookedHours = useCurrentBookedHours();
  const currentToDeleteBookedHours = useCurrentToDeleteBookedHours();
  const hasError = useRepeatError();
  const isDisabled = useMemo(
    () =>
      (isDestructive ? false : Boolean(hasError)) ||
      (isDestructive
        ? !Boolean(currentToDeleteBookedHours.length)
        : !Boolean(currentBookedHours.length)),
    [isDestructive, hasError, currentBookedHours, currentToDeleteBookedHours],
  );
  const resetHours = useResetCurrentBookedHours();
  const resetToDeleteHours = useResetCurrentToDeleteBookedHours();
  const setIsModalOpen = useSetIsModalOpen();
  const { formatMessage } = useIntl();

  return (
    <>
      <div css={styles.buttonWrapper}>
        <Button
          onClick={() => {
            !isDestructive ? resetHours() : resetToDeleteHours();
          }}
          customStyles={styles.cancel}
        >
          <FormattedMessage id="booking-summary.button.cancel" />
        </Button>
        <Button
          style="primary"
          size="small"
          customStyles={
            isDestructive
              ? styles.buttonConfirmDesctructive
              : styles.buttonConfirm
          }
          disabled={isDisabled}
          onClick={() => setIsModalOpen(true)}
        >
          {isDestructive
            ? formatMessage({
                id: 'booking-summary.button.clear',
              })
            : formatMessage({
                id: 'booking-summary.button.book',
              })}
        </Button>
      </div>
    </>
  );
};
