import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const loading = atom<boolean>({
  key: 'authentication.loading',
  default: true,
});

const uid = atom<string | null>({
  key: 'authentication.uid',
  default: null,
});

const email = atom<string | null>({
  key: 'authentication.email',
  default: null,
});

const name = atom<string | null>({
  key: 'authentication.name',
  default: null,
});

const claims = atom<CustomClaim[] | null>({
  key: 'authentication.claims',
  default: null,
});

const defaultClaim = atom<CustomClaim | null>({
  key: 'authentication.defaultClaim',
  default: null,
});

const emailVerified = atom<boolean>({
  key: 'authentication.emailVerified',
  default: false,
});

const isSubscribedToNewsletter = atom<boolean>({
  key: 'authentication.isSubscribedToNewsletter',
  default: false,
});

export const authAtoms = {
  uid,
  loading,
  claims,
  defaultClaim,
  email,
  name,
  emailVerified,
  isSubscribedToNewsletter,
};

export const useUserId = () => useRecoilValue(uid);
export const useUserIdLoading = () => useRecoilValue(loading);
export const useUserEmail = () => useRecoilValue(email);
export const useUserName = () => useRecoilValue(name);
export const useUserClaims = () => useRecoilValue(claims);
export const useDefaultClaim = () => useRecoilValue(defaultClaim);
export const useEmailVerified = () => useRecoilValue(emailVerified);

export const useSetUserId = () => useSetRecoilState(uid);
export const useSetUserIdLoading = () => useSetRecoilState(loading);
export const useSetUserEmail = () => useSetRecoilState(email);
export const useSetUserName = () => useSetRecoilState(name);
export const useSetUserClaims = () => useSetRecoilState(claims);
export const useSetDefaultClaim = () => useSetRecoilState(defaultClaim);
export const useSetEmailVerified = () => useSetRecoilState(emailVerified);
